import Question from "./Question.js";
import FinishState from "./FinishState.js";
import "./CustomQuiz.css";
import "../../CSS/Texte.css";
import { useState } from "react";

export default function CustomQuiz({ inputQuiz, finishFunction, images }) {
    const title = inputQuiz.general.title;
    const questions = inputQuiz.questions;
    const intro = inputQuiz.general.intro;

    const [points, setPoints] = useState(0);
    const [correctQuestions, setCorrectQuestions] = useState(0);
    const [countQuestions, setCountQuestions] = useState(Object.keys(inputQuiz.questions).length);
    const [quizFinished, setQuizFinished] = useState(false);
    const [checkedAnswers, setCheckedAnswers] = useState({});
    const [currentPage, setCurrentPage] = useState(-1);

    const changePage = (value) => {
        setCurrentPage(currentPage + value);
        if ((currentPage + value >= -1) && (currentPage + value <= countQuestions)) {
            document.getElementById(currentPage + value).className = "question-wrapper-visible";
        }
        document.getElementById(currentPage).className = "question-wrapper-invisible";
    }

    const checkAnswer = (questionId, answerId, e) => {
        if (Object.keys(checkedAnswers).includes(e.target.id) && checkedAnswers[e.target.id] === true) {
            const newAnswers = { ...checkedAnswers };
            delete newAnswers[e.target.id];
            setCheckedAnswers(newAnswers);
            //setCheckedAnswers(checkedAnswers => ({...checkedAnswers, [e.target.id]: false}));
            document.getElementById(e.target.id).className = "answer";
        } else {
            setCheckedAnswers(checkedAnswers => ({ ...checkedAnswers, [e.target.id]: true }))
            document.getElementById(e.target.id).className = "answer_active";
        }
    }

    const finishQuiz = () => {
        let current_points = 0;
        let current_correct_answers = 0;
        if (quizFinished === false) {
            Object.keys(questions).map(function (question) {
                if (questions[question].evaluate === true) {
                    const numberCorrectA = Object.keys(questions[question].correctAnswer).length;
                    let counter = 0;
                    Object.keys(checkedAnswers).map(function (checkedAnswer) {
                        let correct = false;
                        const currentQuestion = checkedAnswer.substring(0, checkedAnswer.lastIndexOf("-"));
                        Object.values(questions[question].correctAnswer).map(function (correctA) {
                            const id = question + "-" + correctA;
                            if (checkedAnswer === id && checkedAnswers[id] === true) {correct=true}
                        })
                        if (correct === true) { counter += 1}
                        if (correct === false && question === currentQuestion) { counter -=1 }
                    })
                    if (counter === numberCorrectA) {
                        current_points += questions[question].point;
                        current_correct_answers += 1;
                    }
                    
                    Object.values(questions[question].correctAnswer).map(function (correctA) {
                        const id = question + "-" + correctA;
                        const current_class = document.getElementById(id).className;
                        document.getElementById(id).className = current_class + " answer_finish_correct";
                        /*
                        if (Object.keys(checkedAnswers).includes(id) && checkedAnswers[id] === true) {
                            counter += 1;
                        }
                        */
                    })
                    /*
                    if (counter === numberCorrectA) {
                        current_points += questions[question].point;
                        current_correct_answers += 1;
                    }
                    */
                    
                } else {
                    current_points += questions[question].point;
                    current_correct_answers += 1;
                    Object.values(questions[question].correctAnswer).map(function (correctA) {
                        const id = question + "-" + correctA;
                        const current_class = document.getElementById(id).className;
                        document.getElementById(id).className = current_class + " answer_finish_correct";
                    })
                }
            })
            setPoints(current_points);
            setCorrectQuestions(current_correct_answers);
            setQuizFinished(true);
            finishFunction(current_points);
        }
    }

    return (
        <>
            <div className="quiz-title">
                <h2>{title}</h2>
                <h3 id={-1}>{intro}</h3>
            </div>
            <div className="quiz-content">
                {
                    Object.keys(questions).map(function (question) {
                        return (
                            <Question inputQuestion={{ question: questions[question], id: question }} key={question} checkAnswer={checkAnswer} images={images} />
                        )
                    })
                }
            </div>
            <div className="question-wrapper-invisible" id={countQuestions}>
                {quizFinished === false &&
                    <button onClick={() => finishQuiz()}>{inputQuiz.general.finishButton}</button>
                }
                {quizFinished === true &&
                    <FinishState points={points} correctQuestions={correctQuestions} pointsMax={inputQuiz.general.maxPoints} questions={inputQuiz.general.nrQuestions} text={inputQuiz.general.resultText} title={inputQuiz.general.resultTitle} />
                }
            </div>
            <div className="quiz_nav">
                {currentPage > -1 && <div className="quiz_back"><button onClick={() => changePage(-1)}>{inputQuiz.general.button_back}</button></div>}
                {currentPage < countQuestions && <div className="quiz_forward"><button onClick={() => changePage(1)}>{inputQuiz.general.button_forward}</button></div>}
            </div>
        </>
    )
}