import { useTranslation } from 'react-i18next';
import "../../CSS/ExternalVideo.css";
import { useUserContext } from '../../../hooks/useUserContext';
import CustomQuiz from '../../games/CustomQuiz/CustomQuiz';
import { quiz_de } from './schutz_privatleben/quiz_de';
import { quiz_fr } from './schutz_privatleben/quiz_fr';
import { quiz_it } from './schutz_privatleben/quiz_it';

export default function BeteiligungSchule2({ oldAndNewScore }) {
    const { t } = useTranslation();

    const { games, updateGames, calculateScore, score } = useUserContext();
    const quiz_game = games.schutz_privatleben;

    const { i18n } = useTranslation();
    let quiz = '';
    switch(i18n.language) {
        case 'de':
            quiz = quiz_de;
            break;
        case 'fr':
            quiz = quiz_fr;
            break;
        case 'it':
            quiz = quiz_it;
            break;
        default:
            quiz = quiz_de;
    };

    const setQuizResult = (achieved_points) => {
        //console.log(obj.correctPoints);
        const schutz_privatleben = { ...quiz_game, state: 'done', points_achieved: achieved_points };
        const games_new = { ...games, schutz_privatleben: schutz_privatleben };
        updateGames(games_new);
        const current_game = "schutz_privatleben";
        let total_score = calculateScore(current_game);
        total_score += achieved_points;
        const oldScore = score;
        const newScore = total_score;
        oldAndNewScore(oldScore, newScore);
    };

    return (
        <>
            <div>
                <CustomQuiz inputQuiz={quiz} finishFunction={setQuizResult}/>
            </div>
        </>
    )
}
