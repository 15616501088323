import { image_importer } from "../../helper/helper_functions";

//import components
import { useEffect, useState } from "react";
import ImageMap from "./ImageMap";
import ContentPlacer from "./ContentPlacer";
import React from "react";
import { useUserContext } from "../../hooks/useUserContext";
import AvatarIcon from "../AvatarIcon";

//import styles
import './welten.css';

//import for logging friendly analytics
import { useMatomo } from "@datapunt/matomo-tracker-react";

export default function Foerderrechte({ height, clicked, startSound }) {
    useEffect(() => {
        //const backgroundSong = '../../songs/acoustic-vibe-124586.mp3';
        startSound(backgroundSong, false);
        //logging for friendly analytics
        trackPageView({
            documentTitle: 'Welt Förderrechte',
            href: '/Welt/foerderrechte',
        })
        const new_position_x = games['kindeswohl'].position.x * factor + games['kindeswohl'].height * factor * 0.1;
        const new_position_y = games['kindeswohl'].position.y * factor - games['kindeswohl'].height * factor * 0.9;
        document.getElementById("walking_avatar").className = "is_walking_avatar_right";
        document.getElementById("walking_avatar").style.position = "absolute";
        document.getElementById("walking_avatar").style.left = new_position_x + "px";
        document.getElementById("walking_avatar").style.top = new_position_y + "px";
    }, []);

    const [currentGame, setCurrentGame] = useState('kindeswohl');
    useEffect(() => {
        walk_and_open(currentGame, "", 0, false, false)
    }, [height])

    //import images
    const scenes = image_importer(require.context('../../assets/scenes/foerderrechte', false, /\.(png|jpe?g|svg)$/));
    var backgroundSong;
    if (window.location.hostname !== "localhost") {
        const bgSounds = image_importer(require.context('../../assets/mp3/bg', false, /\.(mp3)$/));
        backgroundSong = bgSounds['acoustic-vibe-124586.mp3']
    } else {
        backgroundSong = '../../songs/acoustic-vibe-124586.mp3';
    }

    //initialize function for logging friendly analytics
    const { trackPageView } = useMatomo();

    //inititalize variable for user data
    const { games } = useUserContext();

    //initialize basic variables
    const imgSrc = "Vulcan_Welt.png";
    const imgWidth = (height / 1280) * 3840;
    const viewbox = "0 0 " + imgWidth.toString() + " " + height.toString();
    const factor = height / 1280;
    const imgMap = {
        imgSrc: imgSrc,
    }

    //set up var for contentPlacer
    const contentKindeswohl = games['kindeswohl'];
    const sceneKindeswohl = scenes[contentKindeswohl.src];
    const contentBildung = games['bildung'];
    const sceneBildung = scenes[contentBildung.src];
    const contentFoerderleistung = games['foerderleistung'];
    const sceneFoerderleistung = scenes[contentFoerderleistung.src];
    const contentFreizeit = games['freizeit'];
    const sceneFreizeit = scenes[contentFreizeit.src];
    const contentGesundheit = games['gesundheit'];
    const sceneGesundheit = scenes[contentGesundheit.src];
    const contentGesundheitPsychisch = games['gesundheit_psychisch'];
    const sceneGesundheitPsychisch = scenes[contentGesundheitPsychisch.src];
    const contentLebensstandard = games['lebensstandard'];
    const sceneLebensstandard = scenes[contentLebensstandard.src];

    //function for avatar-walk and open content
    const walk_and_open = (name, sound, pages, video, open = true) => {
        setCurrentGame(name);
        const old_position_x = document.getElementById("walking_avatar").style.left;
        const data = games[name];
        let new_position_x = data.position.x * factor + data.height * factor * 0.2;
        const new_position_y = data.position.y * factor - data.height * factor * 0.9;
        if (Number(old_position_x.substring(0, old_position_x.lastIndexOf("p"))) > new_position_x) {
            document.getElementById("walking_avatar").className = "is_walking_avatar_left";
            new_position_x = data.position.x * factor + data.height * factor * 0.2;
        } else {
            document.getElementById("walking_avatar").className = "is_walking_avatar_right";
            new_position_x = data.position.x * factor + data.height * factor * 0.1;
        }
        document.getElementById("walking_avatar").style.position = "absolute";
        document.getElementById("walking_avatar").style.left = new_position_x + "px";
        document.getElementById("walking_avatar").style.top = new_position_y + "px";
        if (open === true) {
            if (Math.abs(Number(old_position_x.substring(0, old_position_x.lastIndexOf("p"))) - new_position_x) > 1) {
                setTimeout(() => clicked(name, sound, pages, video), 1300);
            } else {
                clicked(name, sound, pages, video);
            }
        }

    }

    return (
        <div className="background-image" style={{ maxWidth: '100%' }}>
            <div id="walking_avatar">
                <AvatarIcon />
            </div>
            <ImageMap clicked={clicked} factor={factor} imgWidth={imgWidth} viewbox={viewbox} height={height} imgMap={imgMap} folder="foerderrechte" />
            <ContentPlacer data={contentKindeswohl} factor={factor} clicked={walk_and_open} sound={false} pages={1} id="1" scene={sceneKindeswohl} video={false} />
            <ContentPlacer data={contentBildung} factor={factor} clicked={walk_and_open} sound={false} pages={1} id="2" scene={sceneBildung} video={false} />
            <ContentPlacer data={contentFoerderleistung} factor={factor} clicked={walk_and_open} sound={false} pages={1} id="3" scene={sceneFoerderleistung} video={false} />
            <ContentPlacer data={contentFreizeit} factor={factor} clicked={walk_and_open} sound={false} pages={1} id="4" scene={sceneFreizeit} video={false} />
            <ContentPlacer data={contentGesundheit} factor={factor} clicked={walk_and_open} sound={false} pages={1} id="5" scene={sceneGesundheit} video={false} />
            <ContentPlacer data={contentLebensstandard} factor={factor} clicked={walk_and_open} sound={false} pages={1} id="6" scene={sceneLebensstandard} video={false} />
            <ContentPlacer data={contentGesundheitPsychisch} factor={factor} clicked={walk_and_open} sound={false} pages={1} id="5" scene={sceneGesundheitPsychisch} video={false} />
        </div>
    )
}

export const MemoizedFoerderrechte = React.memo(Foerderrechte);