import * as htmlToImage from 'html-to-image';
import React from 'react';

export const LinkText = (props) => {
    return (
        <a href={props.to || '#'} target="_blank" title={props.title || ''}>
            {props.children}
        </a>
    )
}

export function CreateScreenshot() {
    //create complete Pic of Avatar and save to localStorage
    return new Promise(resolve => {
        let node = document.getElementById('avatar_advanced');

        htmlToImage.toPng(node)
        .then(function (dataUrl) {
            let img = new Image();
            img.src = dataUrl;
            //document.body.appendChild(img);
            //let image_frame = window.open("","_blank");
            //image_frame.document.body.appendChild(img);

            //let imgData = getBase64Image(img);
            localStorage.setItem("avatarImg", dataUrl);
            resolve('resolved');
        })
        .catch(function (error) {
            console.error('oops, something went wrong!', error);
        });
    });
};

export function ShuffleObject(objectShuffle) {
    let arrayShuffle = [];
    for (const key in objectShuffle) {
        arrayShuffle.push(objectShuffle[key]);
        arrayShuffle.sort(() => Math.random() - 0.5);
    };
    return arrayShuffle;
};

const delay = (ms) => new Promise(res => setTimeout(res, ms));
export async function FadeInBGSound(objectSound, audioSrc) {
    objectSound.src = audioSrc;
    objectSound.volume = 0;
    objectSound.play();
    let i=0;
    while (i<=0.8) {
        objectSound.volume = i;
        await delay(300);
        i += 0.1;
    };
}
export async function FadeOutBGSound(objectSound) {
    objectSound.volume = 0.8;
    objectSound.play();
    let i=0.8;
    while (i>=0) {
        objectSound.volume = i;
        await delay(100);
        i -= 0.2;
    };
}

export function image_importer(dir) {
    let images = {};
    dir.keys().map((item, index) => { images[item.replace('./', '')] = dir(item); });
    return images
}