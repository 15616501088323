import { useState, useEffect } from 'react';
import { useUserContext } from '../../../hooks/useUserContext';
import { useTranslation } from 'react-i18next';
import ImageMap from '../../welten/ImageMap';
import "./Kindeswohl.css";

export default function Kindeswohl({oldAndNewScore}) {
    const { t } = useTranslation();
    //variables for score and state updating
    const { games, updateGames, calculateScore, score, language } = useUserContext();
    const kindeswohl = games.kindeswohl;
    const max_points = games.kindeswohl.points_max;

    //set up image map variables
    var imgSrc;
    switch(language) {
      case 'de':
        imgSrc = "Kindeswohl_Wimmelbild.png";
        break;
      case 'fr':
        imgSrc = "Kindeswohl_Wimmelbild_fr.png";
        break;
      case 'it':
        imgSrc = "Kindeswohl_Wimmelbild_it.png";
        break;
    }
    const height = window.innerHeight*0.6;
    const imgWidth = (height/2086)*3769;
    const viewbox = "0 0 " + imgWidth.toString() + " " + height.toString();
    const factor = height/2086;
    const imgMap = {
        imgSrc: imgSrc,
        areas: [
          {
            id: 1,
            name: 'grundrecht1',
            type: 'rect',
            x: 317,
            y: 671,
            width: 915,
            height: 392,
          },
          {
            id: 2,
            name: 'grundrecht2',
            type: 'rect',
            x: 206,
            y: 1694,
            width: 915,
            height: 392,
          },
          {
            id:3,
            name: 'grundrecht3',
            type: 'rect',
            x: 1611,
            y: 360,
            width: 915,
            height: 392,
          },
          {
            id: 4,
            name: 'grundrecht4',
            type: 'rect',
            x: 2781,
            y: 2,
            width: 915,
            height: 392,
          },
          {
            id: 5,
            name: 'nope',
            type: 'rect',
            x: 2846,
            y: 1698,
            width: 915,
            height: 392,
          },
          {
            id: 6,
            name: 'nope',
            type: 'rect',
            x: 2191,
            y: 930,
            width: 915,
            height: 392,
          },
          {
            id: 7,
            name: 'nope',
            type: 'rect',
            x: 1044,
            y: 1253,
            width: 915,
            height: 392,
          },
          {
            id: 8,
            name: 'nope',
            type: 'rect',
            x: 1,
            y: 162,
            width: 915,
            height: 392,
          }
        ],
    }

    const [wimmelbilder, setWimmelbilder] = useState({
        grundrecht1: false,
        grundrecht2: false,
        grundrecht3: false,
        grundrecht4: false
    });

    const [text, setText] = useState(t('kindeswohl.start_text'));

    const clicked = (event, name) => {
        if (name!=="nope") {
            setWimmelbilder(wimmelbilder => ({...wimmelbilder, [name]: true}));
            event.target.id="active";
            show_message("good");
        } else if (name === "nope") {
            event.target.id="wrong";
            show_message("bad");
        };
        
    };

    const [wimmelbild_msg, setWimmelbildMsg] = useState("");
    const show_message = (state) => {
        if (state === "good") {
            setWimmelbildMsg(t('kindeswohl.correct'));
        } else if (state === "bad") {
            setWimmelbildMsg(t('kindeswohl.wrong'));
        };
        document.getElementById("wimmelbild_message").style.display = "flex";
        setTimeout(() => document.getElementById("wimmelbild_message").style.display="none", 1000);
    };

    useEffect(() => {
        let finished_temp = true;
        for (const value of Object.values(wimmelbilder)) {
            if (value === false) {
                finished_temp = false;
            };
        };
        if (finished_temp === true) {
            setText(t('kindeswohl.finish_text'));
            const kindeswohl_new = { ...kindeswohl, state: 'done', points_achieved: max_points };
            const games_new = { ...games, kindeswohl: kindeswohl_new };
            updateGames(games_new);
            const current_game = "kindeswohl";
            let total_score = calculateScore(current_game);
            total_score += max_points;
            const oldScore = score;
            const newScore = total_score;
            oldAndNewScore(oldScore, newScore);
            //updateScore(total_score);
            setTimeout(() => {
                setWimmelbildMsg(t('kindeswohl.finished'));
                document.getElementById("wimmelbild_message").style.display="flex";
                }
                , 1500);
        };
    },[wimmelbilder]);

    return (
        <div className="wimmelbild">
            <h2>{text}</h2>
            <div className="wrapper_wimmelbild" style={{ width: "100%", height: "65vh", overflowX: "auto", overflowY: "auto" }}>
                <ImageMap clicked={clicked} factor={factor} imgWidth={imgWidth} viewbox={viewbox} height={height} imgMap={imgMap} folder="game"/>
            </div>
            <div className="wimmelbild_message" id="wimmelbild_message">
                <p>{wimmelbild_msg}</p>
            </div>
        </div>
    )
}