import { useTranslation } from 'react-i18next';

//import für User-Daten
import { useUserContext } from '../hooks/useUserContext';

//imort Styles
import "./CSS/GamesOverview.css";

export default function GamesOverview( {currentWorld} ) {
    const { games } = useUserContext();
    const { t } = useTranslation();

    return (
        <> 
            <div className={currentWorld+"_games_overview"}></div>
            <div className="games_overview_wrapper">
                <div className={"title_"+currentWorld}><h2>{t('general.games_overview')}</h2></div>
                <div className="games_overview_content">
                    <div className={"games_overview_"+currentWorld}>
                        <div className="games_header"><h3>{t('welt.schutzrecht')}</h3></div>
                        <div className="games_content">
                            <div><b>{t('games.game')}</b></div>
                            <div><b>{t('games.points_achieved')}</b></div>
                            <div><b>{t('games.state')}</b></div>
                            {
                                Object.keys(games).map(function (element) {
                                    if (games[element].world==="schutzrecht") {
                                        let game_name = "games."+games[element].name;
                                        let game_points_achieved = games[element].points_achieved;
                                        let game_points_max = games[element].points_max;
                                        let game_state = "games."+games[element].state;
                                        return (
                                            <>
                                                <div>{t(game_name)}</div>
                                                <div>{game_points_achieved} / {game_points_max}</div>
                                                <div>{t(game_state)}</div>
                                            </>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                    <div className={"games_overview_"+currentWorld}>
                        <div className="games_header"><h3>{t('welt.foerderrecht')}</h3></div>
                        <div className="games_content">
                            <div><b>{t('games.game')}</b></div>
                            <div><b>{t('games.points_achieved')}</b></div>
                            <div><b>{t('games.state')}</b></div>
                            {
                                Object.keys(games).map(function (element) {
                                    if (games[element].world==="foerderrecht") {
                                        let game_name = "games."+games[element].name;
                                        let game_points_achieved = games[element].points_achieved;
                                        let game_points_max = games[element].points_max;
                                        let game_state = "games."+games[element].state;
                                        return (
                                            <>
                                                <div>{t(game_name)}</div>
                                                <div>{game_points_achieved} / {game_points_max}</div>
                                                <div>{t(game_state)}</div>
                                            </>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                    <div className={"games_overview_"+currentWorld}>
                        <div className="games_header"><h3>{t('welt.beteiligungsrecht')}</h3></div>
                        <div className="games_content">
                            <div><b>{t('games.game')}</b></div>
                            <div><b>{t('games.points_achieved')}</b></div>
                            <div><b>{t('games.state')}</b></div>
                            {
                                Object.keys(games).map(function (element) {
                                    if (games[element].world==="beteiligungsrecht") {
                                        let game_name = "games."+games[element].name;
                                        let game_points_achieved = games[element].points_achieved;
                                        let game_points_max = games[element].points_max;
                                        let game_state = "games."+games[element].state;
                                        return (
                                            <>
                                                <div>{t(game_name)}</div>
                                                <div>{game_points_achieved} / {game_points_max}</div>
                                                <div>{t(game_state)}</div>
                                            </>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}