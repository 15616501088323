//Imports für das Routing -> OHNE Reloading der Page
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

//Import für Page Transition
import { AnimatePresence } from "framer-motion";

//User-Daten importieren (mittels eigenem Hook)
import { useUserContext } from '../hooks/useUserContext';

//Eigene Komponenten importieren
import Welt from './Welt';
import Settings from "./Settings";
import Hilfe from './Hilfe';
import Avatar from "./Avatar";
import ShareScore from './ShareScore';
/*import PushMessages from "./PushMessages";*/
import QRCodeGenerator from "./QRCodeGenerator";
import QRCodeReader from "./QRCodeReader";
import HomescreenNew from "./HomescreenNew";
import InfoPage from "./InfoPage";
import Startpage from "./Startpage";

function AnimatedRoutes({startSound, stopSound}) {
  const location = useLocation();

  const { name } = useUserContext();

  return (
    <AnimatePresence mode="wait">
      <Routes key={location.pathname} location={location}>
        <Route path="/Startpage" element={<Startpage />} /> 
        <Route path="/Home" element={ <HomescreenNew startSound={startSound} stopSound={stopSound} />} />
        <Route path="/Home/:origin" element={ <HomescreenNew startSound={startSound} stopSound={stopSound} />} />
        <Route path="/" element={<Startpage />} />
        <Route path="/Welt/:world" element={ name===null ? <Navigate to="/" /> : <Welt startSound={startSound} stopSound={stopSound} /> } />
        <Route path="/settings" element={ name===null ? <Navigate to="/" /> : <Settings startSound={startSound} stopSound={stopSound} /> } />
        <Route path="/avatar" element={ name===null ? <Navigate to="/" /> : <Avatar startSound={startSound} stopSound={stopSound} /> } />
        <Route path="/help" element={ name===null ? <Navigate to="/" /> : <Hilfe startSound={startSound} stopSound={stopSound} /> } />
        <Route path="/share_score" element={ name===null ? <Navigate to="/" /> : <ShareScore startSound={startSound} stopSound={stopSound} /> } />
        <Route path="/qr_generator/" element={ name ===null ? <Navigate to="/" /> : <QRCodeGenerator startSound={startSound} stopSound={stopSound} />} />
        <Route path="/qr_reader/" element={<QRCodeReader startSound={startSound} stopSound={stopSound} />} />
        <Route path="/info_page" element={<InfoPage startSound={startSound} stopSound={stopSound} />} />
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;

/*<Route path="/push_messages" element={ name===null ? <Navigate to="/" /> : <PushMessages startSound={startSound} stopSound={stopSound} /> } />*/