//import components
import AvatarAdvancedBubbles from "./AvatarAdvancedBubbles";
import Navbar from "./Navbar";
import { useEffect } from "react";

//Import für Friendly Analytics
import { useMatomo } from '@datapunt/matomo-tracker-react';

//import style
import "./CSS/Avatar_change.css";

export default function Avatar({startSound, stopSound}) {
    //logging for friendly analytics
    const { trackPageView } = useMatomo();
    useEffect(() => {
        //logging for friendly analytics
        trackPageView({
            documentTitle: 'Avatar',
            href: '/avatar',
        });
    }, []);

    return (
        <div className="wrapper_avatar_change">
            <Navbar value="avatar" startSound={startSound} stopSound={stopSound}/>
            <AvatarAdvancedBubbles />
        </div>
    )
}
