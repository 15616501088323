import FillInTheGapSelect from "./FillTheGapSelect";
import reactStringReplace from "react-string-replace";
import { useState, useEffect } from 'react';

export default function FillInTheGap({text, setState, prefix}) {
    const matches = Array.from(text.matchAll(/\[([^\][]*)]/g), x => x[1]);
    const text_with_gap = text.replace(/\[([^\][]*)]/g, '$');

    let evaluation_initial = {};
    matches.forEach((value,index) => {
        evaluation_initial = {...evaluation_initial, [index]: {value: value, state: false}};
    });
    
    const [evaluation, setEvaluation] = useState(evaluation_initial);

    const checkSelection = (id) => {
        const value = document.getElementById(id).value;
        const new_id = id.substring(id.lastIndexOf("_")+1,);
        if (new_id.toString() === value) {
            setEvaluation({...evaluation, [new_id]: {value: evaluation[new_id].value, state: true}})
        }
    };

    const checkAllSelections = () => {
        let finished = true;
        Object.keys(evaluation).map((key, i) => {
            if (evaluation[key].state === false) {
                finished = false;
            }
        })
        if (finished===true) {setState("finished")};
    };

    useEffect(() => {
        checkAllSelections();
    }, [evaluation]);

    return (
        <div>
            {reactStringReplace(text_with_gap, "$", (match, i) => (
                <FillInTheGapSelect key={(i-1)/2} name={(i-1)/2} id={(i-1)/2} data={evaluation} checkSelection={checkSelection} prefix={prefix}/>
            ))}
        </div>
    )
}