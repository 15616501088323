//import components
import QRCode from "react-qr-code";
import { useUserContext } from "../hooks/useUserContext";
import { useTranslation } from "react-i18next";
import Navbar from "./Navbar";
import { useEffect } from "react";

//Import für Friendly Analytics
import { useMatomo } from '@datapunt/matomo-tracker-react';

//import styles
import "./CSS/QRCodeGenerator.css";

import avatar_config from "./settings/avatar_modification.json";

export default function QRCodeGenerator({startSound, stopSound}) {
    //logging for friendly analytics
    const { trackPageView } = useMatomo();
    useEffect(() => {
        //logging for friendly analytics
        trackPageView({
            documentTitle: 'QR-Code Generator',
            href: '/qr_generator',
        });
    }, []);
    //initialize variables / functions for user data and translation
    const { name, avatarParts, score, games, language } = useUserContext();
    const { t } = useTranslation();

    let URL = window.location.origin + "/qr_reader";

    //Name des Avatars
    URL += `?n=${name}`;
    //Total Score aller Games
    URL += `&s=${score}`;
    //Sprache
    URL += `&l=${language}`;
    //Alle AvatarParts
    const cB = avatar_config.clothes_bottom[avatarParts.clothes_bottom.substring(avatarParts.clothes_bottom.lastIndexOf("/") + 1, avatarParts.clothes_bottom.lastIndexOf("."))].nr;
    const cT = avatar_config.clothes_top[avatarParts.clothes_top.substring(avatarParts.clothes_top.lastIndexOf("/") + 1, avatarParts.clothes_top.lastIndexOf("."))].nr;
    const f = avatar_config.face[avatarParts.face.substring(avatarParts.face.lastIndexOf("/") + 1, avatarParts.face.lastIndexOf("."))].nr;
    const h = avatar_config.hair[avatarParts.hair.substring(avatarParts.hair.lastIndexOf("/") + 1, avatarParts.hair.lastIndexOf("."))].nr;
    const sh = avatar_config.shoes[avatarParts.shoes.substring(avatarParts.shoes.lastIndexOf("/") + 1, avatarParts.shoes.lastIndexOf("."))].nr;
    const sk = avatar_config.skin[avatarParts.skin.substring(avatarParts.skin.lastIndexOf("/") + 1, avatarParts.skin.lastIndexOf("."))].nr;
    const a = avatarParts.accessoire.substring(avatarParts.accessoire.lastIndexOf("/") + 1, avatarParts.accessoire.lastIndexOf(".")) == "empty" ? "0" : avatar_config.accessoire[avatarParts.accessoire.substring(avatarParts.accessoire.lastIndexOf("/") + 1, avatarParts.accessoire.lastIndexOf("."))].nr;
    const s = avatarParts.surprise.substring(avatarParts.surprise.lastIndexOf("/") + 1, avatarParts.surprise.lastIndexOf(".")) == "empty" ? "0" : avatar_config.surprise[avatarParts.surprise.substring(avatarParts.surprise.lastIndexOf("/") + 1, avatarParts.surprise.lastIndexOf("."))].nr;
    URL += `&aP=${cB},${cT},${f},${h},${sh},${sk},${a},${s}`;
    //Spielstände der Games
    URL += `&g=`;
    for (const [name, values] of Object.entries(games)) {
        const temp_state = values.state === "active" ? 1 : 0;
        URL += `${values.id}:${values.points_achieved}:${temp_state},`;
    };
    URL = URL.slice(0, -1);

    return (
        <div className="qr_code_generator">
            <header>
                <Navbar value="qr_code" startSound={startSound} stopSound={stopSound} />
            </header>
            <main>
                <div className="qr_wrapper">
                    <h2>{t('qr_code.title')}</h2>
                    <div className="qr_code">
                        <QRCode
                            size={300}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={URL}
                            viewBox={`0 0 300 300`}
                        />
                    </div>
                    <div>
                        <a href={URL} target="_blank">{t('qr_code.link')}</a>
                    </div>
                </div>
            </main>
        </div>
    )
}