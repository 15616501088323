import UseExternalVideo from "../../games/UseExternalVideo";
import { useTranslation } from 'react-i18next';
import "../../CSS/ExternalVideo.css";
import "../../CSS/Texte.css";

export default function SchutzPrivatleben() {
    const { t } = useTranslation();
    const src = t('source.schutz_privatleben');

    return (
        <>
            <div className="content-video" id="video">
                <h3>{t('schutz_privatleben.title')}</h3>
                <UseExternalVideo src={src} />
            </div>
        </>
    )
}
