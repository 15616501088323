export const quiz_it =  {
  "general": {
    "title": "Partecipazione politica",
    "intro": "Come puoi dire la tua? Risolvete il quiz!",
    "finishButton": "Controllare le risposte",
    "nrQuestions": 4,
    "maxPoints": 40,
    "resultTitle": "Ce l'hai Fatto!",
    "resultText": "Hai risposto correttamente a <correctQuestions> domande su <questions> e quindi hai ottenuto <points> punti su <pointsMax>.",
    "button_back": "indietro",
    "button_forward": "prosegui"
  },
  "questions": [
    {
      "question": "Che cosa significa la parola 'partecipazione'?",
      "picture": "",
      "answers": [
        "Posso decidere tutto da solo.",
        "Ho lo stesso diritto degli adulti di dire la mia opinione.",
        "Non mi è permesso dire nulla se qualcosa mi dà fastidio."
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Dove si può dire la propria in politica?",
      "picture": "",
      "answers": [
        "nelle lezioni di nuoto",
        "nel Parlamento dei bambini",
        "durante le feste"
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Dove sta scritto che avete voce in capitolo?",
      "picture": "",
      "answers": [
        "nella Convenzione sui diritti dell'infanzia",
        "nel canzoniere",
        "sul giornale"
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Su quale argomento i bambini non possono esprimersi?",
      "picture": "",
      "answers": [
        "Come dovrebbe essere il nuovo parco giochi del quartiere",
        "Come dovrebbe essere ricostruita la biblioteca per i bambini",
        "Chi diventa presidente"
      ],
      "correctAnswer": [2],
      "point": 10,
      "evaluate": true
    },
  ]
} 