export const quiz_fr =  {
  "general": {
    "title": "Droit aux loisirs",
    "intro": "Résous le quiz suivant pour gagner des points...",
    "finishButton": "Vérifier les réponses",
    "nrQuestions": 6,
    "maxPoints": 35,
    "resultTitle": "C'est fait!",
    "resultText": "Tu as répondu correctement à <correctQuestions> de <questions> et tu as donc obtenu <points> de <pointsMax> points.",
    "button_back": "retour",
    "button_forward": "prochaine"
  },
  "questions": [
    {
      "question": "Qu'est-ce que tu préfères faire?",
      "picture": "",
      "answers": [
        "Lire un livre",
        "Peindre un tableau",
        "Bricoler quelque chose"
      ],
      "correctAnswer": [0,1,2],
      "point": 5,
      "evaluate": false
    },
    {
      "question": "Où est-ce que tu préfères nager?",
      "picture": "",
      "answers": [
        "Dans la piscine",
        "Dans le lac",
        "Dans la rivière",
        "Dans la piscine couverte"
      ],
      "correctAnswer": [0,1,2,3],
      "point": 5,
      "evaluate": false
    },
    {
      "question": "Où aimes-tu passer du temps avec tes amis?",
      "picture": "",
      "answers": [
        "Dans le jardin",
        "A la maison",
        "À l'école",
        "Dans la forêt",
        "Sur le terrain de sport"
      ],
      "correctAnswer": [0,1,2,3,4],
      "point": 5,
      "evaluate": false
    },
    {
      "question": "Quelle musique aimes-tu écouter?",
      "picture": "",
      "answers": [
        "Pop",
        "Rock",
        "Hip-Hop",
        "Variété"
      ],
      "correctAnswer": [0,1,2,3],
      "point": 5,
      "evaluate": false
    },
    {
      "question": "Quelle est la meilleure façon de se reposer?",
      "picture": "",
      "answers": [
        "Dormir",
        "Lire",
        "Rencontrer des amis",
        "écouter de la musique"
      ],
      "correctAnswer": [0,1,2,3],
      "point": 5,
      "evaluate": false
    },
    {
      "question": "Super, distingue maintenant ce qui relève de la détente, du jeu et de la créativité.",
      "picture": "frage6_fr.png",
      "answers": [
        "Bleu : loisirs, rouge : détente, orange : être créatif",
        "Bleu : repos, rouge : être créatif, orange : loisirs",
        "Bleu : repos, Rouge : loisirs, Orange : être créatif",
        "Bleu : être créatif, Rouge : repos, Orange : loisirs"
      ],
      "correctAnswer": [2],
      "point": 10,
      "evaluate": true
    },
    
  ]
} 