import { image_importer } from "../helper/helper_functions";

//import styles
import "./CSS/InfoPage.css";

//Import für Friendly Analytics
import { useMatomo } from '@datapunt/matomo-tracker-react';

//Import Components
import Navbar from "./Navbar";
import { useEffect } from "react";
import { useAccessibilityTransition } from "../hooks/useAccessibility";
import { LinkText } from "../helper/helper_functions";
import { motion } from "framer-motion";
import { useTranslation, Trans } from 'react-i18next';

export default function InfoPage({startSound, stopSound}) {
    //logging for friendly analytics
    const { trackPageView } = useMatomo();
    useEffect(() => {
        //logging for friendly analytics
        trackPageView({
            documentTitle: 'Info Page',
            href: '/info_page',
        });
    }, []);

    const logo = image_importer(require.context('../assets/logo', false, /\.(png|jpe?g|svg)$/));

    //initialize variables / functions for translation and page transition
    const { t } = useTranslation();
    const { MotionInitial, MotionAnimate, MotionExit, MotionTransition } = useAccessibilityTransition('OPACITY');

    return (
        <div className="infopage_wrapper">
            <motion.div
                initial={MotionInitial}
                animate={MotionAnimate}
                exit={MotionExit}
                transition={MotionTransition}
            >
                <header>
                    <Navbar value="infopage" startSound={startSound} stopSound={stopSound} />
                </header>
                <main>
                    <div className="info-page">
                        <img id="infologo" src={logo["kidimo.png"]} alt="KIDIMO" />
                        <h2 className="info-section">{t('info_page.title')}</h2>
                        <p>{t('info_page.text')}</p>

                        <h2 className="info-section">{t('info_page.hintergrund_title')}</h2>
                        <p>{t('info_page.hintergrund_intro')}</p>
                        <ul>
                            <li>{t('info_page.hintergrund_ost')}</li>
                            <li>{t('info_page.hintergrund_phlu')}</li>
                            <li>{t('info_page.hintergrund_unicef')}</li>
                        </ul>

                        <p>{t('info_page.hintergrund_partnernetzwerk')}</p>
                        <ul>
                            <li>{t('info_page.hintergrund_education21')}</li>
                            <li>{t('info_page.hintergrund_integras')}</li>
                            <li>{t('info_page.hintergrund_oskr')}</li>
                            <li>{t('info_page.hintergrund_pach')}</li>
                            <li>{t('info_page.hintergrund_procap')}</li>
                            <li>{t('info_page.hintergrund_projuventute')}</li>
                            <li>{t('info_page.hintergrund_tdhs')}</li>
                        </ul>

                        <h2 className="info-section">{t('info_page.fachperson_title')}</h2>
                        <p><Trans i18nKey="info_page.fachperson" components={{
                            link1: <LinkText to={t('info_page.fachperson_link')} title={t('info_page.fachperson_link_title')} />
                        }} /></p>

                        <h2 className="info-section">{t('info_page.mitwirkung_title')}</h2>
                        <p><Trans i18nKey="info_page.mitwirkung_intro" components={{
                            link1: <LinkText to={t('info_page.mitwirkung_link')} title={t('info_page.mitwirkung_link_title')} />
                        }} /></p>

                        <p>{t('info_page.mitwirkung_praxisorganisationen')}</p>
                        <ul>
                            <li>{t('info_page.mitwirkung_projuventute')}</li>
                            <li>{t('info_page.mitwirkung_unicefdeutschland')}</li>
                            <li>{t('info_page.mitwirkung_unicefschweiz')}</li>
                            <li>{t('info_page.mitwirkung_projunior')}</li>
                            <li>{t('info_page.mitwirkung_kinderschutzschweiz')}</li>
                            <li>{t('info_page.mitwirkung_zebis')}</li>
                            <li>{t('info_page.mitwirkung_pestalozzi')}</li>
                            <li>{t('info_page.mitwirkung_uniwien')}</li>
                        </ul>
                        <p>{t('info_page.mitwirkung_outro')}</p>

                        <h2 className="info-section">{t('info_page.daten_title')}</h2>
                        <p>{t('info_page.daten1')}</p>
                        <p>
                            <Trans i18nKey="info_page.daten2" components={{
                                link1: <LinkText to={t('info_page.daten_link1')} title={t('info_page.daten_link1_title')} />
                            }} />
                        </p>

                        <h2 className="info-section">{t('info_page.fragen_title')}</h2>
                        <p>{t('info_page.fragen1')}</p>
                        <p>
                            <Trans i18nKey="info_page.fragen2" components={{
                                link1: <LinkText to={t('info_page.fragen2_link')} title={t('info_page.fragen2_link_title')} />
                            }} />
                        </p>
                        <p>
                            <Trans i18nKey="info_page.fragen3" components={{
                                link1: <LinkText to={t('info_page.fragen3_link')} title={t('info_page.fragen3_link_title')} />
                            }} />
                        </p>

                        <h2 className="info-section">{t('info_page.impressum_title')}</h2>
                        <p style={{ textAlign: 'center' }}><Trans i18nKey="info_page.impressum" /></p>
                        <h3 className="info-section">{t('info_page.haftungsausschluss_title')}</h3>
                        <p>{t('info_page.haftungsausschluss')}</p>
                        <h3 className="info-section">{t('info_page.haftung_links_title')}</h3>
                        <p>{t('info_page.haftung_links')}</p>
                        <h3 className="info-section">{t('info_page.urheberrechte_title')}</h3>
                        <p>{t('info_page.urheberrechte')}</p>
                    </div>
                </main>
            </motion.div>
        </div>
    )
}