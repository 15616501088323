export const quiz_it =  {
  "general": {
    "title": "Diritto all'educazione, alla scuola e alla formazione professionale",
    "intro": "Cosa sai del diritto alla scuola? Risolvi il quiz.",
    "finishButton": "Controllare le risposte",
    "nrQuestions": 4,
    "maxPoints": 40,
    "resultTitle": "ce l'hai fatta!",
    "resultText": "Avete risposto correttamente a <correctQuestions> domande su <questions> e quindi avete ottenuto <points> punti su <pointsMax> .",
    "button_back": "indietro",
    "button_forward": "prosegui"
  },
  "questions": [
    {
      "question": "I bambini devono andare a scuola?",
      "picture": "",
      "answers": [
        "Solo se i genitori lo vogliono.",
        "Sì, devono andare a scuola.",
        "Possono decidere da soli se e per quanto tempo andare a scuola."
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Quanti bambini nel mondo non possono andare a scuola?",
      "picture": "",
      "answers": [
        "20 Milioni",
        "50 Milioni",
        "473 Milioni"
      ],
      "correctAnswer": [2],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "L'articolo 29 della Convenzione ONU sui diritti dell'infanzia e dell'adolescenza afferma che l'educazione deve mirare a ...",
      "picture": "",
      "answers": [
        "... mirare a garantire che bambini conoscono tutte le formule e siano fluenti in almeno tre lingue entro il momento in cui lasciano la scuola.",
        "... mirare a consentire ai bambini di svilupparsi liberamente e di esprimere i propri talenti.",
        "... essere orientata a far sì che bambini siano bravi e ben educati."
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Perché molte ragazze non vanno a scuola nei paesi più poveri?",
      "picture": "",
      "answers": [
        "Perché devono aiutare a casa nelle faccende domestiche.",
        "Perché non ne hanno voglia.",
        "Perché la scuola è troppo faticosa."
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
  ]
} 