//Import für Check ob Mobile/Desktop
import { isMobile } from 'react-device-detect';

//import components
import { useTranslation } from 'react-i18next';
import { getCountry } from "../helper/country";
import { useEffect } from "react";

//Import für Page Transitions
import { motion } from "framer-motion";
import { useAccessibilityTransition } from "../hooks/useAccessibility";

//Import für Friendly Analytics
import { useMatomo } from "@datapunt/matomo-tracker-react";

//Import components
import Navbar from "./Navbar";

//import styles
import "./CSS/Hilfe.css";

import phone147 from '../assets/btn/147phone.png';
import contact147 from '../assets/btn/147contact.png';

export default function Hilfe({startSound, stopSound}) {
    const { t } = useTranslation();

    //Pageview mit Friendly Analytics tracken
    const { trackPageView } = useMatomo();
    useEffect(() => {
        trackPageView({
            documentTitle: 'Hilfe',
            href: '/help',
        })
    }, []);

    console.log("country: " + getCountry());

    const { MotionInitial, MotionAnimate, MotionExit, MotionTransition } = useAccessibilityTransition('OPACITY');

    return (
        <div className="hilfe_wrapper">
            <motion.div
                initial={MotionInitial}
                animate={MotionAnimate}
                exit={MotionExit}
                transition={MotionTransition}
            >
                <header>
                    <Navbar value="help" startSound={startSound} stopSound={stopSound} />
                </header>
                <main>
                    <div className="hilfe_content">
                        <h2>{t('hilfe.title')}</h2>
                        <div>{t('hilfe.intro')}</div>
                        <div id="hilfe-container">
                            <div id="hilfe-telefon">
                                <p>{isMobile && t('hilfe.telefon-mobil')}</p>
                                <p>{!isMobile && t('hilfe.telefon-nichtmobil')}</p>
                                <a href="tel:+41147"><img src={phone147} alt={t('hilfe.phone_147')} ></img>
                                    <p>{t('hilfe.telefon-linktext')}</p></a>
                            </div>
                            <div id="hilfe-homepage">
                                <p>{t('hilfe.homepage')}</p>
                                <a href={t('hilfe.homepage-link')} target="_blank" rel="noreferrer"><img src={contact147} alt={t('hilfe.contact_147')} ></img>
                                    <p>{t('hilfe.homepage-linktext')}</p></a>
                            </div>
                        </div>
                    </div>
                </main>
            </motion.div>
        </div >
    )
}