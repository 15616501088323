export const quiz_it =  {
    "general": {
      "title": "Divieto di discriminazione",
      "intro": "Quali sono le tre parole che si possono trovare nel seguente puzzle di ricerca?",
      "finishButton": "Controllare le risposte",
      "nrQuestions": 1,
      "maxPoints": 50,
      "resultTitle": "ce l'hai fatta!",
      "resultText": "Avete risposto correttamente a <correctQuestions> domande su <questions> e quindi avete ottenuto <points> punti su <pointsMax> .",
      "button_back": "indietro",
      "button_forward": "prosegui"
    },
    "questions": [
      {
        "question": "Quali sono le tre parole che trovate?",
        "picture": "suchsel_IT.png",
        "answers": [
          "ORIGINE",
          "CONDIZIONE",
          "RELIGIONE",
          "ALTERARE",
          "SESSO",
          "LINGUA",
          "COLORE DELLA PELLE"
        ],
        "correctAnswer": [2,4,5],
        "point": 50,
        "evaluate": true
      },
    ]
  } 