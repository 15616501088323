export const quiz_fr =  {
  "general": {
    "title": "Quiz sur le travail des enfants",
    "intro": "Que sais-tu du travail des enfants ? Résous le quiz et gagne des points !",
    "finishButton": "Vérifier les réponses",
    "nrQuestions": 7,
    "maxPoints": 70,
    "resultTitle": "C'est fait!",
    "resultText": "Tu as répondu correctement à <correctQuestions> de <questions> et tu as donc obtenu <points> de <pointsMax> points.",
    "button_back": "retour",
    "button_forward": "suivant"
  },
  "questions": [
    {
      "question": "Combien d'enfants dans le monde doivent travailler ?",
      "picture": "",
      "answers": [
        "70 Mille",
        "68 Millions",
        "168 Millions"
      ],
      "correctAnswer": [2],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Le travail des enfants ...",
      "picture": "",
      "answers": [
        "n'est pas autorisés.",
        "est un changement intéressant.",
        "est bon pour gagner de l'argent pour maman et papa."
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Qu'est-ce que le travail des enfants ?",
      "picture": "",
      "answers": [
        "quand les enfants livrent des journaux et promènent le chien du voisin une fois par semaine.",
        "lorsque les enfants doivent aider à la maison 1 à 2 heures par jour.",
        "si le travail est dangereux pour la santé, s'il dure trop longtemps ou si les enfants n'ont pas le temps d'aller à l'école."
      ],
      "correctAnswer": [2],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Où y a-t-il le plus grand nombre d'enfants qui travaillent dans le monde ?",
      "picture": "",
      "answers": [
        "en Afrique sub-saharienne",
        "en Inde et en Chine",
        "en Europe"
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Certaines formes de travail des enfants sont particulièrement graves parce que",
      "picture": "",
      "answers": [
        "les enfants n'ont pas la force ni le temps d'aller à l'école en raison de leur travail long et fatigant",
        "les enfants doivent se lever tous les matins.",
        "les enfants ont beaucoup de temps pour jouer"
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "La plupart des enfants doivent travailler parce que",
      "picture": "",
      "answers": [
        "ils souhaitent essayer le travail qu'ils aimeraient faire plus tard.",
        "ils utilisent l'argent pour payer les repas de leurs parents et de leurs frères et sœurs.",
        "ils achètent des jeux informatiques avec l'argent."
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Nous pouvons tous faire quelque chose contre le travail des enfants en",
      "picture": "",
      "answers": [
        "achète toujours le produit le moins cher.",
        "en demandant, lors de l'achat, si le produit a été fabriqué par des enfants",
        "pense que tout le monde pense toujours aux besoins des enfants."
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
  ]
}