export const quiz_fr =  {
  "general": {
    "title": "Participation politique",
    "intro": "Comment peux-tu participer aux décisions ? Résous le quiz !",
    "finishButton": "Vérifier les réponses",
    "nrQuestions": 4,
    "maxPoints": 40,
    "resultTitle": "C'est fait!",
    "resultText": "Tu as répondu correctement à <correctQuestions> de <questions> et tu as donc obtenu <points> de <pointsMax> points.",
    "button_back": "retour",
    "button_forward": "suivant"
  },
  "questions": [
    {
      "question": "Que signifie le mot 'cogestion'?",
      "picture": "",
      "answers": [
        "Je peux décider de tout moi-même.",
        "J'ai le même droit que les adultes de donner mon avis.",
        "Je ne dois rien dire si quelque chose me dérange."
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Où peux-tu participer à la politique'?",
      "picture": "",
      "answers": [
        "en cours de natation",
        "au Parlement des enfants",
        "pendant les vacances"
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Où est-il écrit que tu as ton mot à dire ?",
      "picture": "",
      "answers": [
        "dans la Convention sur les droits de l'enfant",
        "dans le livre de chant",
        "dans le journal"
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Sur quel sujet les enfants n'ont-ils pas le droit de participer?",
      "picture": "",
      "answers": [
        "A quoi doit ressembler la nouvelle aire de jeux du quartier",
        "Comment la bibliothèque doit-elle être transformée pour les enfants",
        "Qui sera président ou présidente"
      ],
      "correctAnswer": [2],
      "point": 10,
      "evaluate": true
    },
  ]
} 