export const quiz_fr =  {
  "general": {
    "title": "Droit à l'éducation, à l'école et à la formation professionnelle",
    "intro": "Que sais-tu sur le droit à l'école? Résous le quiz.",
    "finishButton": "Vérifier les réponses",
    "nrQuestions": 4,
    "maxPoints": 40,
    "resultTitle": "C'est fait!",
    "resultText": "Tu as répondu correctement à <correctQuestions> de <questions> et tu as donc obtenu <points> de <pointsMax> points.",
    "button_back": "retour",
    "button_forward": "prochaine"
  },
  "questions": [
    {
      "question": "Les enfants doivent-ils aller à l'école?",
      "picture": "",
      "answers": [
        "Seulement si leurs parents le souhaitent.",
        "Oui, ils doivent aller à l'école.",
        "Ils peuvent décider eux-mêmes s'ils veulent aller à l'école et pendant combien de temps."
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Combien d'enfants dans le monde ne peuvent pas aller à l'école?",
      "picture": "",
      "answers": [
        "20 millions",
        "50 millions",
        "473 millions"
      ],
      "correctAnswer": [2],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "L'article 29 de la Convention des Nations Unies relative aux droits de l'enfant stipule que l'éducation doit viser à ...",
      "picture": "",
      "answers": [
        "... qu'après l'école, les enfants parlent couramment toutes les formules et au moins trois langues.",
        "... les enfants peuvent évoluer librement et développer leurs talents en toute liberté.",
        "... que les enfants soient sages et bien éduqués."
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Pourquoi de nombreuses filles ne vont-elles pas à l'école dans les pays pauvres?",
      "picture": "",
      "answers": [
        "Parce qu'ils doivent aider à la maison pour les tâches ménagères.",
        "Parce qu'ils n'ont pas envie.",
        "Parce que l'école est trop fatigante."
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
  ]
} 