export const quiz_it =  {
  "general": {
    "title": "Diritto alla propria immagine",
    "intro": "Volete realizzare un album fotografico della vostra classe. Non avete chiesto il permesso ai bambini. Per questo motivo non potete mostrare alcun volto. Quali foto potete usare?",
    "finishButton": "Controllare le risposte",
    "nrQuestions": 5,
    "maxPoints": 50,
    "resultTitle": "Ce l'hai fatta!",
    "resultText": "Hai risposto correttamente a <correctQuestions> domande su <questions> e quindi hai ottenuto <points> punti su <pointsMax> .",
    "button_back": "indietro",
    "button_forward": "prosegui"
  },
  "questions": [
    {
      "question": "È possibile utilizzare questa immagine?",
      "picture": "sharenting1.jpg",
      "answers": [
        "Sì",
        "No"
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "È possibile utilizzare questa immagine?",
      "picture": "sharenting2.jpg",
      "answers": [
        "Sì",
        "No"
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "È possibile utilizzare questa immagine?",
      "picture": "sharenting3.jpg",
      "answers": [
        "Sì",
        "No"
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "È possibile utilizzare questa immagine?",
      "picture": "sharenting4.jpg",
      "answers": [
        "Sì",
        "No"
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "È possibile utilizzare questa immagine?",
      "picture": "sharenting5.jpg",
      "answers": [
        "Sì",
        "No"
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
  ]
} 