import { useState } from "react";

export default function FillInTheGapSelect({name, id, data, checkSelection, prefix}) {
    const index_temp = [...Array(Object.keys(data).length).keys()]
        .sort(() => Math.random() - 0.5);

    const [index, setIndex] = useState(index_temp);

    return (
        <select name={name} id={prefix + "_" + id} onChange={() => checkSelection(prefix + "_" + id)}>
            <option key="0" value="-1">...</option>
            {index.map((key, i) => {
                return (
                    <option key={i} value={key}>{data[key].value}</option>
                )
            })}
        </select>
    )
}