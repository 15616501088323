import { useState, useEffect } from 'react';
import { useUserContext } from '../../../hooks/useUserContext';
import { useTranslation } from 'react-i18next';
import ImageMap from '../../welten/ImageMap';
import "./DirekteGewalt.css";

export default function IndirekteGewalt({oldAndNewScore}) {    
    const { t } = useTranslation();
    //variables for score and state updating
    const { games, updateGames, calculateScore, score } = useUserContext();
    const indirekte_gewalt = games.indirekte_gewalt;
    const max_points = games.indirekte_gewalt.points_max;

    //set up image map variables
    //const height = window.innerHeight*0.6;
    const height = 2484/2;
    const imgSrc = 'wimmelbild.jpg';
    //const imgWidth = (height/1500)*2446;
    const imgWidth = 3500/2;
    const viewbox = "0 0 " + imgWidth.toString() + " " + height.toString();
    //const factor = height/1500;
    const factor = 0.5;
    const imgMap = {
        imgSrc: imgSrc,
        areas: [
          {
            id: 1,
            name: 'scene1',
            type: 'rect',
            x: 524,
            y: 1769,
            width: 412,
            height: 370,
          },
          {
            id: 2,
            name: 'scene2',
            type: 'rect',
            x: 378,
            y: 888,
            width: 276,
            height: 222,
          },
          {
            id: 3,
            name: 'scene3',
            type: 'rect',
            x: 798,
            y: 466,
            width: 276,
            height: 308,
          },
          {
            id: 4,
            name: 'scene4',
            type: 'rect',
            x: 1070,
            y: 376,
            width: 242,
            height: 252,
          }
        ],
    }
    const [wimmelbilder, setWimmelbilder] = useState({
        scene1: false,
        scene2: false,
        scene3: false,
        scene4: false
    });
    const [text, setText] = useState(t('indirekte_gewalt.start_text'));

    const clicked = (event, name) => {
        if (name!=="nope") {
            setWimmelbilder(wimmelbilder => ({...wimmelbilder, [name]: true}));
            event.target.id="active";
        } else if (name === "nope") {
            event.target.id="wrong";
        };
        
    };
    useEffect(() => {
        let finished_temp = true;
        for (const value of Object.values(wimmelbilder)) {
            if (value === false) {
                finished_temp = false;
            };
        };
        if (finished_temp === true) {
            setText(t('direkte_gewalt.finish_text'));
            const indirekte_gewalt_new = { ...indirekte_gewalt, state: 'done', points_achieved: max_points };
            const games_new = { ...games, indirekte_gewalt: indirekte_gewalt_new };
            updateGames(games_new);
            const current_game = "indirekte_gewalt";
            let total_score = calculateScore(current_game);
            total_score += max_points;
            const oldScore = score;
            const newScore = total_score;
            oldAndNewScore(oldScore, newScore);
            //updateScore(total_score);
            document.getElementById("msg").style.visibility = "visible";
        };
    },[wimmelbilder]);

    return (
        <div className="wimmelbild">
            <h2>{text}</h2>
            <div style={{ width: "100%", height: "65vh", overflowX: "scroll", overflowY: "scroll" }}>
                <ImageMap clicked={clicked} factor={factor} imgWidth={imgWidth} viewbox={viewbox} height={height} imgMap={imgMap} folder="game"/>
            </div>
            <div className="msg_success_wimmelbild" id="msg">
              <p>{t('indirekte_gewalt.msg_success')}</p>
            </div>
        </div>
    )
}