import './ContentPlacer.css';

export default function ContentPlacer({ data, factor, clicked, sound, pages, id, scene, video }) {
    const styleDiv = {
        height: data.height*factor,
        position: "absolute",
        left: data.position.x*factor,
        top: data.position.y*factor
    };
    const styleImg = {
        height: data.height*factor
    };

    return (
        <div style={styleDiv} className="content-placer" id={"content_"+id}>
            <img id={data.name} src={scene} className={data.state} style={styleImg} onClick={() => clicked(data.name, sound, pages, video)} alt={data.description} tabIndex={data.id+6} />
        </div>
    )
}
