import { image_importer } from "../helper/helper_functions";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function Speaker( {file, clickSpeak} ) {
    const {t} = useTranslation();
    const btn = image_importer(require.context('../assets/btn', false, /\.(png|jpe?g|svg)$/));
    const [online, setOnline] = useState(navigator.onLine);
    window.addEventListener("online", () => {setOnline(true)});
    window.addEventListener("offline", () => {setOnline(false)});

    return (
        <>
            {online && (<img className="btn_speaker" src={btn["btnspeak.svg"]} onClick={()=>clickSpeak(file)} alt={t('general.speak')} />)}
        </>
    )    
}