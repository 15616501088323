//Import für User-Daten
import { useUserContext } from "../hooks/useUserContext";
import { useAccessibilityTransition } from "../hooks/useAccessibility";

//Import für Friendly Analytics
import { useMatomo } from "@datapunt/matomo-tracker-react";

//Import für Page Transitions
import { motion } from "framer-motion";

//Import Components
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";

//Import für Notification
import OneSignal from "react-onesignal";

//import styles
import "./CSS/Settings.css";

export default function Settings({ stopSound, startSound }) {
    //Pageview mittels Friendly Analytics tracken
    const { trackPageView } = useMatomo();
    useEffect(() => {
        trackPageView({
            documentTitle: 'Settings',
            href: '/settings',
        })
    }, []);

    //initialize variables for user data and translation
    const { deleteUser, settings, updateSettings, updateLanguage, language } = useUserContext();
    const { t, i18n } = useTranslation();

    //initialize variables with current settings
    const lngs = {
        de: { nativeName: 'Deutsch' },
        fr: { nativeName: 'Français' },
        it: { nativeName: 'Italiano' }
    };
    const [languageState, setLanguageState] = useState(language);
    const [font, setFont] = useState(settings.font);
    const [transition, setTransition] = useState(settings.transition);
    const [sound, setSound] = useState(settings.sound);
    const [animation, setAnimation] = useState(settings.animation);

    //function to handle change of language
    const handleChangeLng = (e) => {
        setLanguageState(e.target.value);
        updateLanguage(e.target.value);
        i18n.changeLanguage(e.target.value);
    };

    //function to delete user data -> reset
    const app_reset = () => {
        if (window.confirm(t('general.reset_app')) === true) {
            deleteUser();
            //OneSignal.setSubscription(false); Aktivieren wenn live!
            window.location.reload();
        }
    };

    //initialize variables for page transition
    const { MotionInitial, MotionAnimate, MotionExit, MotionTransition } = useAccessibilityTransition('OPACITY');

    //function to handle the change of the different settings
    const handleChange = async (e) => {
        let newSettings = '';
        switch (e.target.name) {
            case 'font':
                newSettings = {
                    font: e.target.value, transition: transition, sound: sound, animation: animation,
                    beteiligungsrecht: settings.beteiligungsrecht, schutzrecht: settings.schutzrecht, foerderrecht: settings.foerderrecht, general: settings.general
                };
                await updateSettings(newSettings);
                setFont(e.target.value);
                return;
            case 'transition':
                newSettings = {
                    font: font, transition: e.target.value, sound: sound, animation: animation,
                    beteiligungsrecht: settings.beteiligungsrecht, schutzrecht: settings.schutzrecht, foerderrecht: settings.foerderrecht, general: settings.general
                };
                await updateSettings(newSettings);
                setTransition(e.target.value);
                window.location.reload();
                return;
            case 'sound':
                setSound(e.target.value);
                newSettings = {
                    font: font, transition: transition, sound: e.target.value, animation: animation,
                    beteiligungsrecht: settings.beteiligungsrecht, schutzrecht: settings.schutzrecht, foerderrecht: settings.foerderrecht, general: settings.general
                };
                await updateSettings(newSettings);
                if (e.target.value === "soundOff") { stopSound() };
                if (e.target.value === "soundOn") {
                    startSound(settings.general, true);
                };
                return;
            case 'animation':
                newSettings = {
                    font: font, transition: transition, sound: sound, animation: e.target.value,
                    beteiligungsrecht: settings.beteiligungsrecht, schutzrecht: settings.schutzrecht, foerderrecht: settings.foerderrecht, general: settings.general
                };
                await updateSettings(newSettings);
                setAnimation(e.target.value);
                return;
            default:
                return;
        };
    };

    //An- / Abmelden zu Push Notification handeln
    const [permissionGiven, setPermissionGiven] = useState(false);
    const [activeSubscription, setActiveSubscription] = useState(false);
    useEffect(() => {
        OneSignal.getNotificationPermission()
            .then((permission) => {
                if (permission === 'granted') { setPermissionGiven(true) };
            });

        OneSignal.isPushNotificationsEnabled()
            .then((subscription) => {
                if (subscription === true) { setActiveSubscription(true) };
            });
    }, [activeSubscription, permissionGiven]);
    const muteNotifications = () => {
        OneSignal.setSubscription(false)
            .then(() => {
                setActiveSubscription(false);
            });
    };
    const unMuteNotifications = () => {
        OneSignal.setSubscription(true)
            .then(() => {
                setActiveSubscription(true);
            });
    };

    //Native Meldung anzeigen für Notification zulassen
    const buttonClick = () => {
        OneSignal.showNativePrompt()
            .then(() => {
                setPermissionGiven(true);
            });
    };

    return (
        <div className="settings_wrapper">
            <motion.div
                initial={MotionInitial}
                animate={MotionAnimate}
                exit={MotionExit}
                transition={MotionTransition}
            >
                <header>
                    <Navbar value="settings" startSound={startSound} stopSound={stopSound} />
                </header>
                <main>
                    <div className="settings_content">
                        <h2>{t('settings.settings')}</h2>
                        <div className="settings_selections">
                            <div>
                                <fieldset>
                                    <legend>{t('settings.font_size')}</legend>
                                    <label>{t('settings.small')}</label>
                                    <input type="radio" id="fontSmall" value="fontSmall" name="font" checked={font === "fontSmall"} onChange={(e) => handleChange(e)}></input>
                                    <label>{t('settings.medium')}</label>
                                    <input type="radio" id="fontMiddle" value="fontMiddle" name="font" checked={font === "fontMiddle"} onChange={(e) => handleChange(e)}></input>
                                    <label>{t('settings.large')}</label>
                                    <input type="radio" id="fontBig" value="fontBig" name="font" checked={font === "fontBig"} onChange={(e) => handleChange(e)}></input>
                                </fieldset>
                            </div>
                            <div>
                                <fieldset>
                                    <legend>{t('settings.page_transition')}</legend>
                                    <label>{t('settings.on')}
                                        <input type="radio" id="transitionOn" value="transitionOn" name="transition" checked={transition === "transitionOn"} onChange={(e) => handleChange(e)}></input>
                                    </label>
                                    <label>{t('settings.off')}
                                        <input type="radio" id="transitionOff" value="transitionOff" name="transition" checked={transition === "transitionOff"} onChange={(e) => handleChange(e)}></input>
                                    </label>
                                </fieldset>
                            </div>
                            <div>
                                <fieldset>
                                    <legend>{t('settings.background_sound')}</legend>
                                    <label>{t('settings.on')}
                                        <input type="radio" id="soundOn" value="soundOn" name="sound" checked={sound === "soundOn"} onChange={(e) => handleChange(e)}></input>
                                    </label>
                                    <label>{t('settings.off')}
                                        <input type="radio" id="soundOff" value="soundOff" name="sound" checked={sound === "soundOff"} onChange={(e) => handleChange(e)}></input>
                                    </label>
                                </fieldset>
                            </div>
                            {/*<div>
                                <fieldset>
                                    <legend>{t('settings.animations')}</legend>
                                    <label>{t('settings.on')}
                                    <input type="radio" id="animationOn" value="animationOn" name="animation" checked={animation==="animationOn"} onChange={(e) => handleChange(e)}></input>
                                    </label>
                                    <label>{t('settings.off')}
                                    <input type="radio" id="animationOff" value="animationOff" name="animation" checked={animation==="animationOff"} onChange={(e) => handleChange(e)}></input>
                                    </label>
                                </fieldset>
                            </div>*/}
                            <div>
                                <fieldset>
                                    <legend>{t('settings.language')}</legend>
                                    {Object.keys(lngs).map((lng) => (
                                        <div key={lng}>
                                            <label key={lngs[lng].nativeName} htmlFor={lngs[lng].nativeName}>{lngs[lng].nativeName}</label>
                                            <input type="radio" name={lngs[lng].nativeName} id={lng} value={lng} key={lng} checked={languageState === lng} onChange={handleChangeLng} />
                                        </div>
                                    ))}
                                </fieldset>
                            </div>
                            {/*<div>
                                <fieldset>
                                    <legend>{t('settings.notification')}</legend>
                                    <p>{t('settings.ask_notification')}</p>
                                    {!permissionGiven && <button onClick={buttonClick} className="button">{t('settings.yes_notification')}</button>}
                                    {permissionGiven && activeSubscription && <button onClick={muteNotifications}>{t('settings.no_notification')}</button>}
                                    {permissionGiven && !activeSubscription && <button onClick={unMuteNotifications}>{t('settings.notification_again')}</button>}
                                </fieldset>
                            </div>*/}
                            <div>
                                <fieldset>
                                    <legend>{t('settings.qr')}</legend>
                                    <p>{t('settings.ask_qr')}</p>
                                    <Link to="/qr_generator" className="qr_code">{t('settings.qr_code')}</Link>
                                </fieldset>
                            </div>
                            <div>
                                <fieldset>
                                    <legend>{t('settings.share')}</legend>
                                    <p>{t('settings.share_text')}</p>
                                    <Link to="/share_score" className="qr_code">{t('settings.share_button')}</Link>
                                </fieldset>
                            </div>
                        </div>
                        <div>
                            <button onClick={app_reset}>{t('settings.app_reset')}</button>
                        </div>
                    </div>
                </main>
            </motion.div>
        </div>
    )
}
