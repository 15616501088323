import { useUserContext } from "./useUserContext"

export const useAccessibilityTransition = (type) => {
    const { settings } = useUserContext();
    let MotionInitial = {x:0};
    let MotionAnimate = {x:0};
    let MotionExit = {x:0};
    let MotionTransition = {duration: 0};

    if (settings.transition==="transitionOff") {
        return {MotionInitial, MotionAnimate, MotionExit, MotionTransition}
    } else {
        switch (type) {
            case 'SLIDER':
                MotionInitial = {x: "-100vh"};
                MotionAnimate = {x:0};
                MotionExit = {x: "100vh"};
                MotionTransition = {duration: 1};
                return {MotionInitial, MotionAnimate, MotionExit, MotionTransition};
            case 'OPACITY':
                MotionInitial = { opacity: 0 };
                MotionAnimate = { opacity: 1 };
                MotionExit = { opacity: 0 };
                MotionTransition = {duration: 1};
                return {MotionInitial, MotionAnimate, MotionExit, MotionTransition};
            case 'DROP':
                MotionInitial = { y: "-100vw" };
                MotionAnimate = { y: 0 };
                MotionExit = { y: "100vw" };
                MotionTransition = {duration: 1};
                return {MotionInitial, MotionAnimate, MotionExit, MotionTransition};
        };
    };
}