//import components
import { useTranslation } from 'react-i18next';

//import styles
import '../CSS/popup.css';

export default function UseExternalVideo({ src }) {
    const { t } = useTranslation();
    const connection = navigator.onLine;
    return (
        <div className="external-video">
            {connection && <iframe
                src={src}
                width="90%"
                height="100%"
                title={src}
                id="yt_video"
            />}
            {!connection && <p>{t('general.no_internet')}</p>}
        </div>
    )
}