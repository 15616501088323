import { useTranslation } from 'react-i18next';
import "./Informationsfreiheit.css";
import { useUserContext } from '../../../hooks/useUserContext';

export default function Informationsfreiheit() {
    const { t } = useTranslation();
    const { language } = useUserContext();
    const src=t('source.informationsfreiheit');

    return (
        <>
            <div className="wrapper_einfache_sprache">
                <h3>{t('informationsfreiheit.description')}</h3>
                <span>{t('informationsfreiheit.text1')}</span>
                <br/>
                <br/>
                <span>{t('informationsfreiheit.text2')}</span>
                <div className="komplizierte_sprache">
                    <h4>{t('informationsfreiheit.text_title_3')}:</h4>
                    <span>{t('informationsfreiheit.text3')}</span>
                </div>
                <div className="einfache_sprache">
                    <h4>{t('informationsfreiheit.text_title_4')}:</h4>
                    <span>{t('informationsfreiheit.text4')}</span>
                </div>
                {/*<UseExternalVideo src={src} />*/}
            </div>
        </>
    )
}