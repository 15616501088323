export const quiz_de =  {
    "general": {
      "title": "Einfache Sprache",
      "intro": "Welche Sätze sind in einfacher Sprache?",
      "finishButton": "Antworten prüfen",
      "nrQuestions": 1,
      "maxPoints": 10,
      "resultTitle": "Geschafft!",
      "resultText": "Du hast <correctQuestions> von <questions> Fragen richtig beantwortet und damit <points> von <pointsMax> Punkten erreicht.",
      "button_back": "zurück",
      "button_forward": "nächste"
    },
    "questions": [
      {
        "question": "Klicke die Sätze an, die in einfacher Sprache sind.",
        "picture": "",
        "answers": [
          "Jedes Kind darf von Neuigkeiten wissen.",
          "Es gilt zu beachten, dass jedes Kind wissen darf, was neues auf der Welt geschieht.",
          "Das recht auf freie Meinungsäusserung ist ein Recht, welches jedem Kind zusteht.",
          "Jedes Kind darf seine Meinung sagen.",
          "Wichtige Neuigkeiten erfährst du in den Medien.",
          "Du darfst Bücher und Zeitungen lesen.",
          "In Fachliteratur, welche man beispielsweise in Bibliotheken vorfindet, werden spannende Informationen vermittelt."
        ],
        "correctAnswer": [0,3,4,5],
        "point": 10,
        "evaluate": true
      },
    ]
  } 