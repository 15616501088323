import { image_importer } from "../helper/helper_functions";

//import components
import { useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

//Import für Friendly Analytics
import { useMatomo } from '@datapunt/matomo-tracker-react';

//import configs
import settings from './settings/settings.json';
import games from './settings/games_new.json';
import avatar_config from "./settings/avatar_modification.json";

//import styles
import "./CSS/QRReader.css";

export default function QRCodeReaer() {
    const avatar_img = image_importer(require.context('../assets/avatar', false, /\.(png|jpe?g|svg)$/));

    //logging for friendly analytics
    const { trackPageView } = useMatomo();
    useEffect(() => {
        //logging for friendly analytics
        trackPageView({
            documentTitle: 'QR-Code Reader',
            href: '/qr_reader',
        });
    }, []);

    //initialize variables/functions for translation, navigation and URL params
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const accessoire_scoring = games.settings.score_accessoires;
    const surprise_scoring = games.settings.score_surprise;

    //get params from URL (GET)
    const new_name = searchParams.get("n");
    const score = parseInt(searchParams.get("s"));
    const language = searchParams.get("l");
    const avatar_parts = searchParams.get("aP").split(",");
    const avatar_parts_label = ["clothes_bottom", "clothes_top", "face", "hair", "shoes", "skin", "accessoire", "surprise"]
    for (let i = 0; i < avatar_parts.length; i++) {
        Object.keys(avatar_config[avatar_parts_label[i]]).map(function (avatar_part) {
            if (parseInt(avatar_parts[i]) === 0) {
                avatar_parts[i] = "empty.png"
            } else if (parseInt(avatar_parts[i]) === avatar_config[avatar_parts_label[i]][avatar_part].nr) {
                avatar_parts[i] = avatar_config[avatar_parts_label[i]][avatar_part].big
            }
        })
    };
    const games_new = searchParams.get("g").split(",");
    for (let i = 0; i < games_new.length; i++) {
        games_new[i] = games_new[i].split(":");
        Object.keys(games["games"]).map(function (game) {
            if (games["games"][game].id === parseInt(games_new[i][0])) { games_new[i][0] = games["games"][game].name }
        })
    };
    const AvatarParts = {
        shoes: avatar_parts[4],
        hair: avatar_parts[3],
        skin: avatar_parts[5],
        clothes_top: avatar_parts[1],
        clothes_bottom: avatar_parts[0],
        face: avatar_parts[2],
        accessoire: avatar_parts[6],
        surprise: avatar_parts[7],
        face_small: avatar_parts[2],
        hair_small: avatar_parts[3],
        skin_small: avatar_parts[5].substring(0, avatar_parts[5].lastIndexOf(".")) + "_small.png",
        accessoire_small: avatar_parts[7]
    };

    //functions for updating Games & GamesInfos
    function update_game(games) {
        return new Promise((resolve) => {
            localStorage.setItem("games", JSON.stringify(games));
            resolve('resolved');
        });
    };

    async function updateGames(games) {
        await update_game(games);
        let done_beteiligungsrechte = 0;
        let done_foerderrechte = 0;
        let done_schutzrechte = 0;
        for (const [name, values] of Object.entries(JSON.parse(localStorage.getItem("games")))) {
            if (values.world === "beteiligungsrecht" && values.state === "done") {
                done_beteiligungsrechte += 1;
            } else if (values.world === "foerderrecht" && values.state === "done") {
                done_foerderrechte += 1;
            } else if (values.world === "schutzrecht" && values.state === "done") {
                done_schutzrechte += 1;
            };
        };
        let games_infos = JSON.parse(localStorage.getItem("gamesInfos"));
        games_infos['beteiligungsrechte']['done'] = done_beteiligungsrechte;
        games_infos['foerderrechte']['done'] = done_foerderrechte;
        games_infos['schutzrechte']['done'] = done_schutzrechte;
        games_infos['beteiligungsrechte']['done'] === games_infos['beteiligungsrechte']['total'] ? games_infos['beteiligungsrechte']['finished'] = 1 : games_infos['beteiligungsrechte']['finished'] = 0;
        games_infos['foerderrechte']['done'] === games_infos['foerderrechte']['total'] ? games_infos['foerderrechte']['finished'] = 1 : games_infos['foerderrechte']['finished'] = 0;
        games_infos['schutzrechte']['done'] === games_infos['schutzrechte']['total'] ? games_infos['schutzrechte']['finished'] = 1 : games_infos['schutzrechte']['finished'] = 0;
        localStorage.setItem("gamesInfos", JSON.stringify(games_infos));
    };

    const applyNewUser = async (e) => {
        e.preventDefault();
        //first: create new User
        const gamesInfos = games['infos'];
        const gamesGames = games['games'];

        localStorage.setItem("name", JSON.stringify(new_name));
        localStorage.setItem("language", JSON.stringify(language));
        localStorage.setItem("score", JSON.stringify(score));
        //localStorage.setItem("games", JSON.stringify(gamesGames));
        localStorage.setItem("gamesInfos", JSON.stringify(gamesInfos));
        localStorage.setItem("settings", JSON.stringify(settings));
        localStorage.setItem("avatarParts", JSON.stringify(AvatarParts));
        localStorage.setItem("intro", JSON.stringify(true));
        localStorage.setItem("show_notification", JSON.stringify(false));
        localStorage.setItem("show_badge_notification", JSON.stringify(false));
        if (score >= accessoire_scoring) {
            localStorage.setItem("accessoire", JSON.stringify(true));
        } else {
            localStorage.setItem("accessoire", JSON.stringify(false));
        }
        if (score >= surprise_scoring) {
            localStorage.setItem("surprise", JSON.stringify(true));
        } else {
            localStorage.setItem("surprise", JSON.stringify(false));
        }

        //second: update games
        let game_new = "";
        let game_new_complet = {};
        for (let i = 0; i < games_new.length; i++) {
            game_new = {
                points_achieved: parseInt(games_new[i][1]),
                state: (games_new[i][2] === "1" ? "active" : "done"),
                description: gamesGames[games_new[i][0]].description,
                height: gamesGames[games_new[i][0]].height,
                id: gamesGames[games_new[i][0]].id,
                intro: gamesGames[games_new[i][0]].intro,
                intro_audio: gamesGames[games_new[i][0]].intro_audio,
                name: gamesGames[games_new[i][0]].name,
                outro: gamesGames[games_new[i][0]].outro,
                outro: gamesGames[games_new[i][0]].outro_audio,
                points_max: gamesGames[games_new[i][0]].points_max,
                src: gamesGames[games_new[i][0]].src,
                srcIntro: gamesGames[games_new[i][0]].srcIntro,
                world: gamesGames[games_new[i][0]].world,
                position: gamesGames[games_new[i][0]].position
            }
            game_new_complet[games_new[i][0]] = game_new;
            game_new = "";
        };
        //console.log(game_new_complet);
        await updateGames(game_new_complet);

        document.getElementById('start_app').style.visibility = "visible";
    };

    return (
        <div className="qr_reader_wrapper">
            <h2>{t('qr_reader.title')}</h2>
            <div className="qr_reader_content">
                <div className="avatar_qr">
                    {
                        Object.keys(avatar_parts).map(function (part) {
                            return (
                                <div className={"avatar_" + part} key={"avatar_" + part}>
                                    <img src={avatar_img[avatar_parts[part]]} key={"avatar_pic_" + part} alt="" />
                                </div>
                            )
                        })
                    }
                </div>
                <div className="qr_reader_text">
                    <p><b>{t('qr_reader.name')}</b>: {new_name}</p>
                    <p><b>{t('qr_reader.score')}</b>: {score}</p>
                    <b>{t('qr_reader.games')}</b>:
                    {
                        Object.keys(games_new).map(function (game) {
                            return (
                                <>
                                    <p key={"game_" + game}>{games_new[game][0]}, {t('qr_reader.state')}: {games_new[game][2] === "1" ? "active" : "done"}, {t('qr_reader.points')}: {games_new[game][1]} </p>
                                </>
                            );
                        })
                    }
                </div>
            </div>
            <div>
                <button onClick={(e) => applyNewUser(e)}>{t('qr_reader.confirm')}</button>
                <div className="start_app" id="start_app"><a href="/">{t('qr_reader.continue')}</a></div>
            </div>
        </div>
    )

}