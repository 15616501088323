import { image_importer } from "../helper/helper_functions";

//Import für User-Daten
import { useUserContext } from "../hooks/useUserContext";

//import components
import { useState } from "react";
import AvatarIcon from "./AvatarIcon";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Speaker from "./Speaker";

//Import für Friendly Analytics
import { useMatomo } from '@datapunt/matomo-tracker-react';

//import styles
import "./CSS/Startpage.css";

export default function Startpage() {
    //initialize variables for user data and translation
    const { name } = useUserContext();
    const { t, i18n } = useTranslation();

    //import images
    const lng = image_importer(require.context('../assets/lng', false, /\.(png|jpe?g|svg)$/));
    const logo = image_importer(require.context('../assets/logo', false, /\.(png|jpe?g|svg)$/));
    const btn = image_importer(require.context('../assets/btn', false, /\.(png|jpe?g|svg)$/));

    //logging for friendly analytics
    const { trackPageView } = useMatomo();
    useEffect(() => {
        if (!i18n.language.includes("de") && !i18n.language.includes("fr") && !i18n.language.includes("it")) {
            i18n.changeLanguage("de");
        }
        //logging for friendly analytics
        trackPageView({
            documentTitle: 'Startpage',
            href: '/',
        });
    }, []);

    //function to handle the language change
    const handleChange = (e) => {
        i18n.changeLanguage(e.target.dataset.lng);
        setIntro(true);
    };

    //initialize variable for steering visibility of language selection / intro
    const [intro, setIntro] = useState(false)

    const clickSpeak = (audio) => {
        document.getElementById('audio_text').src = audio;
        document.getElementById('audio_text').play();
    }

    return (
        <main>
            <div className="start-page">
                <div className="logo">
                    <img src={logo["kidimo.png"]} alt="KIDIMO" />
                    <p className="slogan">{t('start-screen.slogan')}</p>
                </div>
                <div className="info-link">
                    <Link to="/info_page"><img src={btn["btninfo.svg"]} alt={t('general.infopage')} /></Link>
                </div>
                {name !== null && name !== "" && (
                    <>
                        <div className="start-content">
                            <h2>{t('start-screen.welcome')}<br /> {name}!</h2>
                        </div>
                        <div className="start-avatar-wrapper">
                            <div className="start-avatar">
                                <AvatarIcon shadow={false} />
                            </div>
                        </div>
                        <div className="bg-content">
                        </div>
                    </>)}
                {(name === null || name === "") && intro === false && (
                    <div className="start-language">
                        <div></div>
                        <div className={i18n.language.includes("de") ? "active" : "passive"} id="lng-select" onClick={handleChange} data-lng="de">
                            <img className="lngflag" src={lng["deutsch.png"]} data-lng="de" alt="Deutsch" />
                            <p data-lng="de">Deutsch</p>
                        </div>
                        <div className={i18n.language.includes("fr") ? "active" : "passive"} id="lng-select" onClick={handleChange} data-lng="fr">
                            <img className="lngflag" src={lng["franzoesisch.png"]} data-lng="fr" alt="Français" />
                            <p data-lng="fr">Français</p>
                        </div>
                        <div className={i18n.language.includes("it") ? "active" : "passive"} id="lng-select" onClick={handleChange} data-lng="it">
                            <img className="lngflag" src={lng["italienisch.png"]} data-lng="it" alt="Italiano" />
                            <p data-lng="it">Italiano</p>
                        </div>
                        <div></div>
                    </div>
                )}
                {(name === null || name === "") && intro === true && (
                    <div className="start-intro">
                        <p className="start-intro-header">{t('start-screen.title')}</p>
                        <p className="start-intro-text">{t('start-screen.explanation')}</p>
                        <p className="start-intro-text">{t('start-screen.start')}</p>
                        <div className="speaker-start-button"><Speaker file={t('start-screen.intro_audio')} clickSpeak={clickSpeak} /></div>
                    </div>
                )}
                <div className="forward-button">
                    {name !== null && name !== "" && (<Link to="/Home"><img src={btn["btncontinue.svg"]} className="next-button" alt={t('general.next')} /></Link>)}
                    {(name === null || name === "") && intro === false && (<img src={btn["btncontinue.svg"]} onClick={() => setIntro(true)} className="next-button" alt={t('general.next')} />)}
                    {(name === null || name === "") && intro === true && (<Link to="/Home"><img src={btn["btncontinue.svg"]} className="next-button" alt={t('general.next')} /></Link>)}
                </div>
                {(name===null || name==="") && intro===true && (
                    <div className="backward-button">
                        <img src={btn["btnback.svg"]} onClick={() => setIntro(false)} className="next-button" alt={t('general.back')} />
                    </div>
                )}
            </div>
            <div className="audio_text">
                <audio id="audio_text">
                    <source src="" type="audio/mp3" />
                </audio>
            </div>
        </main>
    )
}