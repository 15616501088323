import { useState, useEffect } from 'react';
import { useUserContext } from '../../../hooks/useUserContext';
import { useTranslation } from 'react-i18next';
import ImageMap from '../../welten/ImageMap';
import "./GesundheitPsychisch.css";

export default function GesundheitPsychisch({oldAndNewScore}) {
    const { t } = useTranslation();
    //variables for score and state updating
    const { games, updateGames, calculateScore, score, language } = useUserContext();
    const gesundheit_psychisch = games.gesundheit_psychisch;
    const max_points = games.gesundheit_psychisch.points_max;

    //set up image map variables
    var imgSrc;
    switch(language) {
      case 'de':
        imgSrc = "gesundheit_psychisch.png";
        break;
      case 'fr':
        imgSrc = "gesundheit_psychisch_fr.png";
        break;
      case 'it':
        imgSrc = "gesundheit_psychisch_it.png";
        break;
    }
    const height = window.innerHeight*0.6;
    const imgWidth = (height/1923)*3709;
    const viewbox = "0 0 " + imgWidth.toString() + " " + height.toString();
    const factor = height/1923;
    const imgMap = {
        imgSrc: imgSrc,
        areas: [
          {
            id: 1,
            name: 'nope',
            type: 'rect',
            x: 303,
            y: 0,
            width: 627,
            height: 301,
          },
          {
            id: 2,
            name: 'nope',
            type: 'rect',
            x: 386,
            y: 615,
            width: 627,
            height: 301,
          },
          {
            id: 3,
            name: 'gesundheit_psychisch1',
            type: 'rect',
            x: 0,
            y: 1171,
            width: 627,
            height: 301,
          },
          {
            id: 4,
            name: 'gesundheit_psychisch2',
            type: 'rect',
            x: 1212,
            y: 1041,
            width: 627,
            height: 301,
          },
          {
            id: 5,
            name: 'gesundheit_psychisch3',
            type: 'rect',
            x: 1354,
            y: 1615,
            width: 627,
            height: 301,
          },
          {
            id: 7,
            name: 'nope',
            type: 'rect',
            x: 1770,
            y: 395,
            width: 627,
            height: 301,
          },
          {
            id: 9,
            name: 'gesundheit_psychisch5',
            type: 'rect',
            x: 3045,
            y: 834,
            width: 627,
            height: 301,
          },
          {
            id: 10,
            name: 'gesundheit_psychisch4',
            type: 'rect',
            x: 3084,
            y: 1,
            width: 627,
            height: 301,
          }
        ],
    }

    const [wimmelbilder, setWimmelbilder] = useState({
        gesundheit_psychisch1: false,
        gesundheit_psychisch2: false,
        gesundheit_psychisch3: false,
        gesundheit_psychisch4: false,
        gesundheit_psychisch5: false
    });

    const [text, setText] = useState(t('gesundheit_psychisch.start_text'));

    const clicked = (event, name) => {
        if (name!=="nope") {
            setWimmelbilder(wimmelbilder => ({...wimmelbilder, [name]: true}));
            event.target.id="active";
            show_message("good");
        } else if (name === "nope") {
            event.target.id="wrong";
            show_message("bad");
        };
        
    };

    const [wimmelbild_msg, setWimmelbildMsg] = useState("");
    const show_message = (state) => {
        if (state === "good") {
            setWimmelbildMsg(t('gesundheit_psychisch.correct'));
        } else if (state === "bad") {
            setWimmelbildMsg(t('gesundheit_psychisch.wrong'));
        };
        document.getElementById("wimmelbild_message").style.display = "flex";
        setTimeout(() => document.getElementById("wimmelbild_message").style.display="none", 1000);
    };

    useEffect(() => {
        let finished_temp = true;
        for (const value of Object.values(wimmelbilder)) {
            if (value === false) {
                finished_temp = false;
            };
        };
        if (finished_temp === true) {
            setText(t('gesundheit_psychisch.finish_text'));
            const gesundheit_psychisch_new = { ...gesundheit_psychisch, state: 'done', points_achieved: max_points };
            const games_new = { ...games, gesundheit_psychisch: gesundheit_psychisch_new };
            updateGames(games_new);
            const current_game = "gesundheit_psychisch";
            let total_score = calculateScore(current_game);
            total_score += max_points;
            const oldScore = score;
            const newScore = total_score;
            oldAndNewScore(oldScore, newScore);
            //updateScore(total_score);
            setTimeout(() => {
                setWimmelbildMsg(t('gesundheit_psychisch.finished'));
                document.getElementById("wimmelbild_message").style.display="flex";
                }
                , 1500);
        };
    },[wimmelbilder]);

    return (
        <div className="wimmelbild">
            <h2>{text}</h2>
            <div className="wrapper_wimmelbild" style={{ width: "100%", height: "65vh", overflowX: "auto", overflowY: "auto" }}>
                <ImageMap clicked={clicked} factor={factor} imgWidth={imgWidth} viewbox={viewbox} height={height} imgMap={imgMap} folder="game"/>
            </div>
            <div className="wimmelbild_message" id="wimmelbild_message">
                <p>{wimmelbild_msg}</p>
            </div>
        </div>
    )
}