import { image_importer } from "../helper/helper_functions";

//import user data
import { useUserContext } from "../hooks/useUserContext";

//import components
import { useTranslation } from 'react-i18next';
import AvatarAdvancedBubbles from "./AvatarAdvancedBubbles";
import { useNavigate } from "react-router";

//import styles
import "./CSS/AvatarErstellen.css";

export default function Login() {
  const navigate = useNavigate();

  //import images
  const btn = image_importer(require.context('../assets/btn', false, /\.(png|jpe?g|svg)$/));
  const empty = image_importer(require.context('../assets/avatar/empty', false, /\.(png|jpe?g|svg)$/));
  const avatar_img_small = image_importer(require.context('../assets/avatar/small', false, /\.(png|jpe?g|svg)$/));
  const avatar_img = image_importer(require.context('../assets/avatar', false, /\.(png|jpe?g|svg)$/));

  //Funktionen für die Internationalisierung / Übersetzung
  const { i18n, t } = useTranslation();

  //user variabeln initialisieren -> userContext.js stellt entsprechende Function "createUser" zur Verfügung
  const { createUser } = useUserContext();

  const language = i18n.language;
  const score = 0;

  //Funktion um Submit abzufangen -> createUser aufrufen, um Daten in LocalStorage zu schreiben
  const handleSubmit = async (e) => {
    if (e) {e.preventDefault()};
    const name = document.getElementById('name').value;

    if (name !== "") {
      //get the images for the avatar
      const shoes = document.getElementById('shoes_img').getAttribute('src').substring(document.getElementById('shoes_img').getAttribute('src').lastIndexOf("/") + 1, document.getElementById('shoes_img').getAttribute('src').indexOf(".")) + ".png";
      const hair = document.getElementById('hair_img').getAttribute('src').substring(document.getElementById('hair_img').getAttribute('src').lastIndexOf("/") + 1, document.getElementById('hair_img').getAttribute('src').indexOf(".")) + ".png";
      const skin = document.getElementById('skin_img').getAttribute('src').substring(document.getElementById('skin_img').getAttribute('src').lastIndexOf("/") + 1, document.getElementById('skin_img').getAttribute('src').indexOf(".")) + ".png";
      const clothes_top = document.getElementById('clothes_top_img').getAttribute('src').substring(document.getElementById('clothes_top_img').getAttribute('src').lastIndexOf("/") + 1, document.getElementById('clothes_top_img').getAttribute('src').indexOf(".")) + ".png";
      const clothes_bottom = document.getElementById('clothes_bottom_img').getAttribute('src').substring(document.getElementById('clothes_bottom_img').getAttribute('src').lastIndexOf("/") + 1, document.getElementById('clothes_bottom_img').getAttribute('src').indexOf(".")) + ".png";
      const face = document.getElementById('face_img').getAttribute('src').substring(document.getElementById('face_img').getAttribute('src').lastIndexOf("/") + 1, document.getElementById('face_img').getAttribute('src').indexOf(".")) + ".png";
      const face_small = document.getElementById('face_img').getAttribute('src').substring(document.getElementById('face_img').getAttribute('src').lastIndexOf("/") + 1, document.getElementById('face_img').getAttribute('src').indexOf(".")) + ".png";
      const hair_small = document.getElementById('hair_img').getAttribute('src').substring(document.getElementById('hair_img').getAttribute('src').lastIndexOf("/") + 1, document.getElementById('hair_img').getAttribute('src').indexOf(".")) + ".png";
      const skin_small = document.getElementById('skin_img').getAttribute('src').substring(document.getElementById('skin_img').getAttribute('src').lastIndexOf("/") + 1, document.getElementById('skin_img').getAttribute('src').indexOf(".")) + "_small.png";

      const avatarParts = {
        shoes: shoes,
        hair: hair,
        skin: skin,
        clothes_top: clothes_top,
        clothes_bottom: clothes_bottom,
        face: face,
        accessoire: "empty.png",
        surprise: "empty.png",
        face_small: face_small,
        hair_small: hair_small,
        skin_small: skin_small,
        accessoire_small: "accessoire_empty.png",
      };
      const user = { name: name, language: language, score: score, avatarParts: avatarParts, intro: true, accessoire: false, suprise: false, show_notification: false, show_badge_notification: false }
      await createUser(user);

      //create IndexedDB for push Notification
      /*if (indexedDB) {
        const dbName = "Kinderrechte_PUSH";
        const request = indexedDB.open(dbName, 1);

        request.onerror = (event) => {
          //Handle Error
        };
        request.onupgradeneeded = (event) => {
          const db = event.target.result;
          const objectStore = db.createObjectStore("push", { keyPath: "id", autoIncrement: true });
          objectStore.createIndex("message", "message", { unique: false });
          objectStore.createIndex("title", "title", { unique: false });
          objectStore.createIndex("date", "date", { unique: false });
          objectStore.createIndex("read", "read", { unique: false });
        }
      }*/

      window.location.reload();
    } else {
      //document.getElementById('nameLabel').style.color = "red";
      document.getElementById("name").classList.add("shakeme");
      //document.getElementById('name').placeholder = "Bitte Namen eingeben";
      setTimeout(() => {
        document.getElementById("name").classList.remove("shakeme");
      }, 250);

    };
  };

  window.addEventListener("keypress", function(event) {
    if (event.key==="Enter") {
      handleSubmit();
      console.log("enter");
    }
  })

  return (
    <main>
      <div className="wrapper_avatar_change">
        <div className="avatar_advanced_config">
          <AvatarAdvancedBubbles initial={true} />
        </div>
        <div className="nav-bar-bottom">
          <div className="nav-left">
            <img src={btn["btnback.svg"]} className="btn" onClick={() => navigate(-1)} alt={t('general.back')} />
          </div>
          <div className="nav-center"></div>
          <div className="nav-right">
            <img className="btn" src={btn["btncontinue.svg"]} onClick={(e) => handleSubmit(e)} alt={t('general.next')} />
          </div>
        </div>
      </div>
    </main>
  )
}
