import { image_importer } from "../helper/helper_functions";

//import user data
import { useUserContext } from "../hooks/useUserContext";

//import styles
import "./CSS/AvatarIcon.css";

export default function AvatarIcon ({shadow}) {
    //import images
    const avatar_img = image_importer(require.context('../assets/avatar', false, /\.(png|jpe?g|svg)$/));
    const medals = image_importer(require.context('../assets/avatar/medals', false, /\.(png|jpe?g|svg)$/));

    //initialize variables for avatar parts
    const { avatarParts, gamesInfos } = useUserContext();
    const currentShoe = avatar_img[avatarParts.shoes];
    const currentHair = avatar_img[avatarParts.hair];
    const currentSkin = avatar_img[avatarParts.skin];
    const currentClothesTop = avatar_img[avatarParts.clothes_top];
    const currentClothesBottom = avatar_img[avatarParts.clothes_bottom];
    const currentFace = avatar_img[avatarParts.face];
    const currentAccessoire = avatar_img[avatarParts.accessoire];
    const currentSurprise = avatar_img[avatarParts.surprise];

    return (
        <div className="avatar_all">
            <div className="avatar_icon">
                <div className="skin_home"><img src={currentSkin} alt="" /></div>
                <div className="face_home"><img src={currentFace} alt="" /></div>
                <div className="hair_home"><img src={currentHair} alt="" /></div>
                <div className="clothes_top_home"><img src={currentClothesTop} alt="" /></div>
                <div className="clothes_bottom_home"><img src={currentClothesBottom} alt="" /></div>
                <div className="shoe_home"><img src={currentShoe} alt="" /></div>
                <div className="accessoire_home"><img src={currentAccessoire} alt="" /></div>
                <div className="surprise_home"><img src={currentSurprise} alt="" /></div>
                {shadow!==false && (<div className="avatar_shadow"><img src={avatar_img["schatten.png"]} alt="" /></div>)}
                {gamesInfos.schutzrechte.finished==1 && <div className="avatar_badge1"><img src={medals["badge_unterwasser.png"]} alt="" /></div>}
                {gamesInfos.beteiligungsrechte.finished==1 && <div className="avatar_badge1"><img src={medals["badge_weltall.png"]} alt="" /></div>}
                {gamesInfos.foerderrechte.finished==1 && <div className="avatar_badge1"><img src={medals["badge_vulkan.png"]} alt="" /></div>}
            </div>
        </div>
    );
}