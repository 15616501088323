export const quiz_fr =  {
  "general": {
    "title": "Droit à la promotion.",
    "intro": "Que vois-tu sur le détail de l'image? En quoi l'enfant est-il encouragé?",
    "finishButton": "Vérifier les réponses",
    "nrQuestions": 5,
    "maxPoints": 50,
    "resultTitle": "C'est fait!",
    "resultText": "Tu as répondu correctement à <correctQuestions> de <questions> et tu as donc obtenu <points> de <pointsMax> points.",
    "button_back": "retour",
    "button_forward": "prochaine"
  },
  "questions": [
    {
      "question": "Choisis ce qui est vrai:",
      "picture": "frage1.jpg",
      "answers": [
        "Nager",
        "Écrire",
        "Douche"
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Choisis ce qui est vrai:",
      "picture": "frage2.jpg",
      "answers": [
        "Faire du roller",
        "Faire du vélo",
        "Conduire une voiture"
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Choisis ce qui est vrai:",
      "picture": "frage3.jpg",
      "answers": [
        "Magie",
        "Lire",
        "Calculer"
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Choisis ce qui est vrai:",
      "picture": "frage4.jpg",
      "answers": [
        "Dessiner",
        "Calculer",
        "Sport"
      ],
      "correctAnswer": [2],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Choisis ce qui est vrai:",
      "picture": "frage5.jpg",
      "answers": [
        "Résoudre des énigmes",
        "Bricoler avec du bois",
        "Faire de la musique"
      ],
      "correctAnswer": [2],
      "point": 10,
      "evaluate": true
    }
    
  ]
} 