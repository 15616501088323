import { image_importer } from "../helper/helper_functions";

//Import für User-Daten
import { useUserContext } from "../hooks/useUserContext";

//Import, um aus HTML Bild zu erstellen für Sharing
import * as htmlToImage from 'html-to-image';

//Import für Page Transitions
import { motion } from "framer-motion";

//Import für Friendly Analytics
import { useMatomo } from "@datapunt/matomo-tracker-react";

//Import Components
import Navbar from "./Navbar";
import { useAccessibilityTransition } from "../hooks/useAccessibility";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import AvatarIcon from "./AvatarIcon";

//import styles
import "./CSS/ShareScore.css";

export default function ShareScore({startSound, stopSound}) {
    //import images
    const logo = image_importer(require.context('../assets/logo', false, /\.(png|jpe?g|svg)$/));

    const { t } = useTranslation();

    //Pageview mittels Friendly Analytics tracken
    const { trackPageView } = useMatomo();
    useEffect(() => {
        try { document.getElementById('avatar').src = localStorage.getItem('avatarImg') } catch { };
        trackPageView({
            documentTitle: 'Spielstand teilen',
            href: '/share_score',
        })
    }, []);

    //initialize variables for user data
    const { name, score } = useUserContext();

    //Create img in right format and share
    async function shareCanvas(dataUrl) {
        const blob = await (await fetch(dataUrl)).blob();
        const filesArray = [
            new File(
                [blob],
                'animation.png',
                {
                    type: blob.type,
                    lastModified: new Date().getTime()
                }
            )
        ];
        const shareData = {
            title: 'Kinderrechte-App',
            text: 'Schau dir meinen aktuellen Score an!',
            files: filesArray,
        };
        try {
            await navigator.share(shareData)
        } catch (error) {
            //console.error('ups, leider Firefox', error);
            if (error.name === "TypeError" || error.name === "NotAllowedError") {
                document.getElementById('error').className = "error_show";
                document.getElementById('main').style.display = "none";
                //console.log("Keine Freigabe");
            } else if (error.name === "AbortError") {
                document.getElementById('error').className = "error_hidden";
                //console.log("abgebrochen");
            };
        };
        document.getElementById('watermark').innerHTML = ""
    };

    //create "Screenshot" of the img
    const createScreenshot = () => {
        document.getElementById('error').className = "error_hidden";
        //document.getElementById('watermark').innerHTML = "Kinderrechte-App auf <a href='https://kinderrechte-app.ch'>https://kinderrechte-app.ch</a>";
        let node = document.getElementById('share_content');
        //node.className="share_content_screenshot";

        htmlToImage.toPng(node)
            .then(function (dataUrl) {
                let img = new Image();
                img.src = dataUrl;

                shareCanvas(dataUrl);
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    };

    //initialize variables for page transition
    const { MotionInitial, MotionAnimate, MotionExit, MotionTransition } = useAccessibilityTransition('OPACITY');

    return (
        <div className="share-score-wrapper">
            <motion.div
                initial={MotionInitial}
                animate={MotionAnimate}
                exit={MotionExit}
                transition={MotionTransition}
            >
                <div>
                    <header>
                        <Navbar value="share_score" startSound={startSound} stopSound={stopSound} />
                    </header>
                    <main id="main">
                        <div className="share-content-wrapper">
                            <div className="share_content" id="share_content" >
                                <div className="logo_div"><img src={logo['kidimo.png']} className="logo_img" alt="KIDIMO" /></div>
                                <div className="share_content_title">{name}</div>
                                <div className="share_avatar">
                                    <AvatarIcon shadow={false} />
                                </div>
                                <div className="share_points">
                                    <span>{t('navbar.points')}:</span>
                                    <span>{score}</span>
                                </div>
                                <p id="watermark"></p>
                            </div>
                        </div>
                        <p><button onClick={createScreenshot}>{t('share_score.share')}</button></p>
                    </main>
                </div>
                <div className="error_hidden" id="error">
                    <h2>{t('share_score.error_title')}</h2>
                    <p>{t('share_score.error')}</p>
                </div>
            </motion.div>
        </div>
    )
}
