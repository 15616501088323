import { image_importer } from "../helper/helper_functions";

export default function StarsHome( {total, done} ) {
    //import images
    const stars_nav = image_importer(require.context('../assets/stars', false, /\.(png|jpe?g|svg)$/));

    //set up array with correct number of "voll" and "leer" stars
    const stars = []
    for (let i=0; i<total; i++) {
        if (i<done) {stars.push(stars_nav["star_voll.png"])} else {stars.push(stars_nav["star_leer.png"])}
    }

    return (
        <div className="stars">
            {stars.map((star, index) => (
                <img src={star} key={index} className={star.includes("voll")?"star_voll":"star_leer"} alt="" />
            ))}
        </div>
    )
}