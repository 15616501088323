export const quiz_fr =  {
  "general": {
    "title": "Le droit à sa propre image",
    "intro": "Tu veux faire un album photo de ta classe. Tu n'as pas demandé la permission aux enfants. C'est pourquoi aucun visage ne doit être reconnaissable. Quelles photos peux-tu utiliser?",
    "finishButton": "Vérifier les réponses",
    "nrQuestions": 5,
    "maxPoints": 50,
    "resultTitle": "C'est fait!",
    "resultText": "Tu as répondu correctement à <correctQuestions> de <questions> et tu as donc obtenu <points> de <pointsMax> points.",
    "button_back": "retour",
    "button_forward": "prochaine"
  },
  "questions": [
    {
      "question": "Cette image peut-elle être utilisée?",
      "picture": "sharenting1.jpg",
      "answers": [
        "Oui",
        "Non"
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Cette image peut-elle être utilisée?",
      "picture": "sharenting2.jpg",
      "answers": [
        "Oui",
        "Non"
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Cette image peut-elle être utilisée?",
      "picture": "sharenting3.jpg",
      "answers": [
        "Oui",
        "Non"
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Cette image peut-elle être utilisée?",
      "picture": "sharenting4.jpg",
      "answers": [
        "Oui",
        "Non"
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Cette image peut-elle être utilisée?",
      "picture": "sharenting5.jpg",
      "answers": [
        "Oui",
        "Non"
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
  ]
} 