import { image_importer } from '../../../../helper/helper_functions';
import { useTranslation } from 'react-i18next';
import './singleCard.css';

export default function SingleCard({ card, handleChoice, flipped, disabled }) {
    const {t} = useTranslation();
    const cover = image_importer(require.context('../../../../assets/games/beteiligungsrechte/religionsfreiheit', false, /\.(png|jpe?g|svg)$/));
    
    const handleClick = () => {
        if (!disabled) {
          handleChoice(card);
        };
    }

    const height = window.innerHeight;
    const width = window.innerWidth;
    const imgHeight = height * 0.5 / 3;
    const imgWidth = width * 0.5 / 4;
    const size = Math.min(imgHeight, imgWidth);

  return (
    <div className="card">
        <div className={flipped ? "flipped" : ""}>
            <img className="front" src={card.src} alt={t('general.front')} style={{height: size, width: size}} />
            <img className="back" src={cover["cover.png"]} onClick={handleClick} alt={t('general.backside')} style={{height: size, width: size}} />
        </div>
    </div>
  )
};