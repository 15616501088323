import * as serviceWorkerRegistration from '../serviceWorkerRegistration';

/* update v1 - silent launch -> official launch */
export async function clear_sw(version) {
    if(JSON.parse(localStorage.getItem("name"))){
        console.log("start clear SW");
        //to delete pre-cache -> unregister sw, delete cache, reregister sw!
        if ('serviceWorker' in navigator) {
            serviceWorkerRegistration.unregister();
            caches.keys().then(cacheNames => {
                cacheNames.forEach(cacheName => {
                    if (cacheName === "json") {
                        caches.delete(cacheName);
                    }
                });
            });

            const DBOpenRequest =  indexedDB.open("workbox-expiration");
            DBOpenRequest.onsuccess = (event) => {
                console.log("indexedDB opened");

                clearData();
            }
            function clearData() {
                const db = DBOpenRequest.result;
                const transaction = db.transaction(["cache-entries"], "readwrite");
                const objectStore = transaction.objectStore("cache-entries");
                objectStore.clear();
                transaction.oncomplete = (event) => {
                    console.log("transaction completed")
                };
            }
        }
        console.log("finished clear SW");
        setTimeout(function() {
            localStorage.setItem("version", version);
            window.location.reload(true);
        }, 500);
    }
}