//import components
import { useUserContext } from '../../../hooks/useUserContext';
import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import FillInTheGap from '../../games/FillInTheGap/FillTheGap';

//import text for FillInTheGap
import texte_de from "./beteiligung_krise/texte.json";
import texte_fr from "./beteiligung_krise/texte_fr.json";
import texte_it from "./beteiligung_krise/texte_it.json";

//import styles
import "./BeteiligungKrisen2.css";

export default function BeteiligungKrisen2({oldAndNewScore}) {
    const { t, i18n } = useTranslation();
    let texte = '';
    switch(i18n.language) {
        case 'de':
            texte = texte_de;
            break;
        case 'fr':
            texte = texte_fr;
            break;
        case 'it':
            texte = texte_it;
            break;
        default:
            texte = texte_de;
    };

    const { games, updateGames, calculateScore, score } = useUserContext();
    const puzzle = games.beteiligung_krise;
    const max_points = games.beteiligung_krise.points_max;

    const was_ist_anhoerung = texte['was_ist_anhoerung'];
    const passiert_mit_antworten = texte['passiert_mit_antworten'];
    const geschieht_bei_anhoerung = texte['geschieht_bei_anhoerung'];
    const sonst_wichtig = texte['sonst_wichtig'];
    const [stateWas, setStateWas] = useState("running");
    const [stateAntworten, setStateAntworten] = useState("running");
    const [stateGeschieht, setStateGeschieht] = useState("running");
    const [stateWichtig, setStateWichtig] = useState("running");

    useEffect(() => {
        if (stateWas==="finished" && stateAntworten==="finished" && stateGeschieht==="finished" && stateWichtig==="finished") {
            const beteiligung_krise_new = { ...puzzle, state: 'done', points_achieved: max_points };
            const games_new = { ...games, beteiligung_krise: beteiligung_krise_new };
            updateGames(games_new);
            const current_game = "beteiligung_krise";
            let total_score = calculateScore(current_game);
            total_score += max_points;
            const oldScore = score;
            const newScore = total_score;
            oldAndNewScore(oldScore, newScore);
            document.getElementById("msg").style.visibility = "visible";
        }
    },[stateWas, stateAntworten, stateGeschieht, stateWichtig])

    return (
        <div className="beteiligung_krise_wrapper">
            <div className="beteiligung_krise_content">
                <div className={stateWas==="finished"?"correct":"open"}>
                    <h2>{t('beteiligung_krise.title_definition')}</h2>
                    <FillInTheGap text={was_ist_anhoerung} setState={setStateWas} prefix="one" />
                </div>
                <div className={stateAntworten==="finished"?"correct":"open"}>
                    <h2>{t('beteiligung_krise.title_answers')}</h2>
                    <FillInTheGap text={passiert_mit_antworten} setState={setStateAntworten} prefix="two" />
                </div>
                <div className={stateGeschieht==="finished"?"correct":"open"}>
                    <h2>{t('beteiligung_krise.title_procedure')}</h2>
                    <FillInTheGap text={geschieht_bei_anhoerung} setState={setStateGeschieht} prefix="three" />
                </div>
                <div className={stateWichtig==="finished"?"correct":"open"}>
                    <h2>{t('beteiligung_krise.title_important')}</h2>
                    <FillInTheGap text={sonst_wichtig} setState={setStateWichtig} prefix="four" />
                </div>
            </div>
            <div className="msg_success_gap" id="msg">
                <p>{t('beteiligung_krise.msg_success')}</p>
            </div>
        </div>
    )
}