import { image_importer } from "../helper/helper_functions";

import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

//Import User-Daten
import { useUserContext } from "../hooks/useUserContext";
import { useAccessibilityTransition } from "../hooks/useAccessibility";

//Import für Page Transitions
import { motion } from "framer-motion";

//Import für Friendly Analytics
import { useMatomo } from '@datapunt/matomo-tracker-react';

//Import Components
import Navbar from "./Navbar";
import AvatarErstellen from "./AvatarErstellen";

//import Styles
import './CSS/HomescreenNew.css';
import { Link, useNavigate } from 'react-router-dom';
import AvatarIcon from './AvatarIcon';
import StarsHome from './StarsHome';

export default function HomescreenNew({ startSound, stopSound }) {
  //import images
  const btn = image_importer(require.context('../assets/btn', false, /\.(png|jpe?g|svg)$/));
  const nav = image_importer(require.context('../assets/nav', false, /\.(png|PNG|jpe?g|svg)$/));
  var backgroundSong;
  if (window.location.hostname !== "localhost") {
    const bgSounds = image_importer(require.context('../assets/mp3/bg', false, /\.(mp3)$/));
    backgroundSong = bgSounds['summer-chill.mp3']
  } else {
    backgroundSong = '../../songs/summer-chill.mp3';
  }

  //initialize user data
  const { name, language, gamesInfos } = useUserContext();

  //read params from URL
  const { origin } = useParams();

  //initialize variables for avatar alignment
  const [activeImg, setActiveImg] = useState('schutzrechte_img');
  const [avatar_big_class, setAvatarBigClass] = useState('left');
  const [activeWorld, setActiveWorld] = useState('schutzrechte');

  const navigate = useNavigate();

  //functions for i18n (translation)
  const { t, i18n } = useTranslation();

  //function for friendly analytics
  const { trackPageView } = useMatomo();

  //initialize variables for page transitions
  const { MotionInitial, MotionAnimate, MotionExit, MotionTransition } = useAccessibilityTransition('OPACITY');

  //function for navigation -> click once: move avatar, click twice: open world
  const handleClick = (e) => {
    let target_world = "";

    //issue because of the layered divs -> check if any of the div is clicked on
    if (e.target.id === "") {
      if (e.target.parentElement.id === "") {
        if (e.target.parentElement.parentElement.id === "") {
          target_world = e.target.parentElement.parentElement.parentElement.id.substring(e.target.parentElement.parentElement.parentElement.id.lastIndexOf("_") + 1)
        } else {
          target_world = e.target.parentElement.parentElement.id.substring(e.target.parentElement.parentElement.id.lastIndexOf("_") + 1)
        }
      } else {
        target_world = e.target.parentElement.id.substring(e.target.parentElement.id.lastIndexOf("_") + 1)
      }
    } else if (e.target.id.includes("score")) {
      target_world = e.target.id.substring(e.target.id.lastIndexOf("_") + 1);
    } else {
      target_world = e.target.id;
    }
    //if clicked second time -> open the world
    if (target_world + "_img" === activeImg) {
      const nav_to = "/Welt/" + target_world;
      navigate(nav_to);
    } else {

      switch (target_world) {
        case 'schutzrechte':
          setAvatarBigClass("left");
          setActiveImg('schutzrechte_img');
          setActiveWorld('schutzrechte');
          break
        case 'foerderrechte':
          setAvatarBigClass("center");
          setActiveImg('foerderrechte_img');
          setActiveWorld('foerderrechte');
          break
        case 'beteiligungsrechte':
          setAvatarBigClass("right");
          setActiveImg('beteiligungsrechte_img');
          setActiveWorld('beteiligungsrechte');
          break
      };
    }
  };

  useEffect(() => {
    //const backgroundSong = '../../songs/summer-chill.mp3';
    startSound(backgroundSong, false);

    //logging for friendly analytics
    i18n.changeLanguage(language);
    trackPageView({
      documentTitle: 'Homescreen',
      href: '/Home',
    }, []);

    //set variables for avatar alignment
    switch (origin) {
      case 'schutzrechte':
        setAvatarBigClass("left");
        setActiveImg('schutzrechte_img');
        setActiveWorld('schutzrechte');
        break;
      case 'foerderrechte':
        setAvatarBigClass("center");
        setActiveImg('foerderrechte_img');
        setActiveWorld('foerderrechte');
        break;
      case 'beteiligungsrechte':
        setAvatarBigClass("right");
        setActiveImg('beteiligungsrechte_img');
        setActiveWorld('beteiligungsrechte');
        break;
      default:
    };
  }, []);

  //Navigation zu den Welten darstellen, wenn "angemeldet", sonst AvatarErstellen
  if (name && name !== "") {

    return (
      <div className="App homescreenbg">
        <motion.div
          initial={MotionInitial}
          animate={MotionAnimate}
          exit={MotionExit}
          transition={MotionTransition}
        >
          <header>
            <Navbar value="home" startSound={startSound} stopSound={stopSound} />
          </header>
          <main className="homescreen-main">
            <div className="avatar_wrapper">
              <div>
                <div className={"avatar_big_"+avatar_big_class} id="avatar_big">
                  <Link to="/avatar" id="link_avatar">
                    <AvatarIcon shadow={false} />
                  </Link>
                </div>
              </div>
            </div>
            <div className="nav_welten">
              <div className="welten_wrapper">
                <div className="schutzrechte" id="schutzrechte" onClick={(e) => handleClick(e)} >
                  <img src={nav["welt1.png"]} className={activeImg === "schutzrechte_img" ? "active no-visual-events" : "no-visual-events"} id="schutzrechte_img" alt={t('welt.schutzrecht')} />
                </div>
              </div>
              <div className="welten_wrapper">
                <div className="foerderrechte" id="foerderrechte" onClick={(e) => handleClick(e)}>
                  <img src={nav["welt2.png"]} className={activeImg === "foerderrechte_img" ? "active no-visual-events" : "no-visual-events"} id="foerderrechte_img" alt={t('welt.foerderrecht')} />
                </div>
              </div>
              <div className="welten_wrapper">
                <div className="beteiligungsrechte" id="beteiligungsrechte" onClick={(e) => handleClick(e)}>
                  <img src={nav["welt3.png"]} className={activeImg === "beteiligungsrechte_img" ? "active no-visual-events" : "no-visual-events"} id="beteiligungsrechte_img" alt={t('welt.beteiligungsrecht')} />
                </div>
              </div>
            </div>
            <div className="score_welten">
              <div className="score_wrapper">
                <div className={activeWorld === 'schutzrechte' ? "score_schutzrechte_active" : "score_schutzrechte"} id="score_schutzrechte" onClick={(e) => handleClick(e)}>
                  <div><StarsHome total={gamesInfos['schutzrechte']['total']} done={gamesInfos['schutzrechte']['done']} /></div>
                </div>
              </div>
              <div className="score_wrapper">
                <div className={activeWorld === 'foerderrechte' ? "score_foerderrechte_active" : "score_foerderrechte"} id="score_foerderrechte" onClick={(e) => handleClick(e)}>
                  <div><StarsHome total={gamesInfos['foerderrechte']['total']} done={gamesInfos['foerderrechte']['done']} /></div>
                </div>
              </div>
              <div className="score_wrapper">
                <div className={activeWorld === 'beteiligungsrechte' ? "score_beteiligungsrechte_active" : "score_beteiligungsrechte"} id="score_beteiligungsrechte" onClick={(e) => handleClick(e)}>
                  <div><StarsHome total={gamesInfos['beteiligungsrechte']['total']} done={gamesInfos['beteiligungsrechte']['done']} /></div>
                </div>
              </div>
            </div>
          </main>

        </motion.div>
      </div>
    );
  } else {
    return (
      <AvatarErstellen />
    );
  };
}
