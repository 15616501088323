import { useState, useEffect } from 'react';
import { useUserContext } from '../../../hooks/useUserContext';
import { useTranslation } from 'react-i18next';
import ImageMap from '../../welten/ImageMap';
import "./DirekteGewalt.css";

export default function DirekteGewalt({oldAndNewScore}) { 
    const { t } = useTranslation();
    //variables for score and state updating
    const { games, updateGames, calculateScore, score } = useUserContext();
    const direkte_gewalt = games.direkte_gewalt;
    const max_points = games.direkte_gewalt.points_max;

    //set up image map variables
    //const height = window.innerHeight*0.6;
    const height = 2484/2;
    const imgSrc = 'wimmelbild.jpg';
    //const imgWidth = (height/1500)*2446;
    const imgWidth = 3500/2;
    const viewbox = "0 0 " + imgWidth.toString() + " " + height.toString();
    //const factor = height/1500;
    const factor = 0.5;
    const imgMap = {
        imgSrc: imgSrc,
        areas: [
          {
            id: 1,
            name: 'scene1',
            type: 'rect',
            x: 20,
            y: 1005,
            width: 330,
            height: 238,
          },
          {
            id: 2,
            name: 'scene2',
            type: 'rect',
            x: 1491,
            y: 865,
            width: 217,
            height: 220,
          },
          {
            id:3,
            name: 'scene3',
            type: 'rect',
            x: 2193,
            y: 434,
            width: 188,
            height: 276,
          },
          {
            id: 4,
            name: 'scene4',
            type: 'rect',
            x: 2478,
            y: 0,
            width: 176,
            height: 276,
          },
          {
            id: 5,
            name: 'scene5',
            type: 'rect',
            x: 3078,
            y: 1554,
            width: 254,
            height: 200,
          },
        ],
    }
    const [wimmelbilder, setWimmelbilder] = useState({
      scene1: false,
      scene2: false,
      scene3: false,
      scene4: false,
      scene5: false
    });

    const count_areas = Object.keys(wimmelbilder).length;
    const [text, setText] = useState(t('direkte_gewalt.start_text'));

    const clicked = (event, name) => {
      if (name!=="nope") {
          setWimmelbilder(wimmelbilder => ({...wimmelbilder, [name]: true}));
          event.target.id="active";
      } else if (name === "nope") {
          event.target.id="wrong";
      };
      
  };

  useEffect(() => {
      let finished_temp = true;
      for (const value of Object.values(wimmelbilder)) {
          if (value === false) {
              finished_temp = false;
          };
      };
      if (finished_temp === true) {
          setText(t('direkte_gewalt.finish_text'));
          const direkte_gewalt_new = { ...direkte_gewalt, state: 'done', points_achieved: max_points };
          const games_new = { ...games, direkte_gewalt: direkte_gewalt_new };
          updateGames(games_new);
          const current_game = "direkte_gewalt";
          let total_score = calculateScore(current_game);
          total_score += max_points;
          const oldScore = score;
          const newScore = total_score;
          oldAndNewScore(oldScore, newScore);
          //updateScore(total_score);
          document.getElementById("msg").style.visibility = "visible";
      };
  },[wimmelbilder]);

    return (
        <div className="wimmelbild">
            <h2>{text}</h2>
            <div style={{ width: "100%", height: "65vh", overflowX: "scroll", overflowY: "scroll" }}>
                <ImageMap clicked={clicked} factor={factor} imgWidth={imgWidth} viewbox={viewbox} height={height} imgMap={imgMap} folder="game"/>
            </div>
            <div className="msg_success_wimmelbild" id="msg">
              <p>{t('direkte_gewalt.msg_success')}</p>
            </div>
        </div>
    )
}