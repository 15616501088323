export const quiz_it =  {
  "general": {
    "title": "Diritto alla salute fisica.",
    "intro": "Cosa fa bene alla salute fisica?",
    "finishButton": "Controllare le risposte",
    "nrQuestions": 7,
    "maxPoints": 35,
    "resultTitle": "ce l'hai fatta!",
    "resultText": "Avete risposto correttamente a <correctQuestions> domande su <questions> e quindi avete ottenuto <points> punti su <pointsMax> .",
    "button_back": "indietro",
    "button_forward": "prosegui"
  },
  "questions": [
    {
      "question": "Cosa fa bene alla salute fisica? Scegli ciò che è giusto:",
      "picture": "",
      "answers": [
        "Rumore",
        "Silenzio"
      ],
      "correctAnswer": [1],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "Cosa fa bene alla salute fisica? Scegli ciò che è giusto:",
      "picture": "",
      "answers": [
        "andare in macchina",
        "andare in bicicletta"
      ],
      "correctAnswer": [1],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "Cosa fa bene alla salute fisica? Scegli ciò che è giusto:",
      "picture": "",
      "answers": [
        "Tante patatine",
        "Tanta frutta"
      ],
      "correctAnswer": [1],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "Cosa fa bene alla salute fisica? Scegli ciò che è giusto:",
      "picture": "",
      "answers": [
        "Acqua pulita",
        "Acqua sporca"
      ],
      "correctAnswer": [0],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "Cosa fa bene alla salute fisica? Scegli ciò che è giusto:",
      "picture": "",
      "answers": [
        "Aria pulita",
        "Aria sporca"
      ],
      "correctAnswer": [0],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "Cosa fa bene alla salute fisica? Scegli ciò che è giusto:",
      "picture": "",
      "answers": [
        "Sedersi tanto",
        "Tanto movimento"
      ],
      "correctAnswer": [1],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "Cosa fa bene alla salute fisica? Scegli ciò che è giusto:",
      "picture": "",
      "answers": [
        "Molte strade",
        "Molti prati e alberi"
      ],
      "correctAnswer": [1],
      "point": 5,
      "evaluate": true
    }
    
  ]
} 