import { image_importer } from "../helper/helper_functions";

//Import für Page Transitions
import { motion } from "framer-motion";

//Import für Friendly Analytics
import { useMatomo } from "@datapunt/matomo-tracker-react";

//Import Components
import Schutzrechte from "./welten/Schutzrechte";
import MemoizedFoerderrechte from "./welten/Foerderrechte";
import Beteiligungsrechte from "./welten/Beteiligungsrechte";
import Navbar from "./Navbar";
import PopupNew from "./PopupNew";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAccessibilityTransition } from "../hooks/useAccessibility";
import { useUserContext } from "../hooks/useUserContext";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

//import styles
import './CSS/Welt.css';
import AvatarIcon from "./AvatarIcon";

export default function Welt({ startSound, stopSound }) {
  //import images
  const btn = image_importer(require.context('../assets/btn', false, /\.(png|jpe?g|svg)$/));
  const medals = image_importer(require.context('../assets/avatar/medals/small', false, /\.(png|jpe?g|svg)$/));

  //Event mittels Friendly Analytics tracken
  const { trackEvent } = useMatomo();

  //initialize basic variables
  const { updateScore, setNotification, show_notification, setBadgeNotification, show_badge_notification } = useUserContext();
  const { world } = useParams();
  const { t } = useTranslation();
  const [counting, setCounting] = useState('');

  //Popup for content
  const [showPopup, setShowPopup] = useState(false);
  const delay = (ms) => new Promise(res => setTimeout(res, ms));
  const handleClose = async (scoreBefore, scoreAfter) => {
    setShowPopup(false);
    if (scoreBefore !== '') {
      if (scoreBefore < scoreAfter) {
        setCounting('counting');
        await delay(300);
        for (let i = scoreBefore; i <= scoreAfter; i++) {
          await delay(50);
          updateScore(i);
        }
        await delay(300);
        setCounting('');
      };
    };
  };

  //useState for browser window size -> responsiveness
  const imgWidth = 4893;
  const imgHeight = 1500;
  const height = window.innerHeight;
  const [width, setWidth] = useState((height / imgHeight) * imgWidth);

  //resize image for "responsiveness"
  function handleResize() {
    setWidth((window.innerHeight / imgHeight) * imgWidth);
  };

  //Add listener for resizing browser window -> responsiveness
  window.addEventListener('resize', handleResize);

  //Accessibility -> wenn mit Tab und Entertaste die einzelnen Elemente ansteueren & öffnen will
  window.addEventListener('keypress', function (event) {
    if (event.key === "Enter") {
      clicked(document.activeElement.id);
    }
  });

  //handle click on image area and first set params for popup
  const [content, setContent] = useState('');
  const [isSound, setIsSound] = useState(false);
  const [nrPages, setNrPages] = useState(1);
  const [video, setVideo] = useState(false);
  const clicked = (name, sound, pages, video) => {
    setContent(name);
    setIsSound(sound);
    setNrPages(pages);
    setVideo(video);
    setShowPopup(true);

    //Click-Event mittels Friendly Analytics tracken
    trackEvent({
      category: 'welt-inhalt',
      action: 'click-event',
      name: name,
    })
  };

  //initialize variables for page transition
  const { MotionInitial, MotionAnimate, MotionExit, MotionTransition } = useAccessibilityTransition('OPACITY');

  //handle "notifications" if accessoire / surprise is unlocked
  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(false);
  const hideNotification = () => {
    setNotification(false);
  };
  const navigateToAvatar = () => {
    setNotification(false);
    navigate('/avatar', { replace: true });
  };
  useEffect(() => {
    setShowNotification(show_notification);
  }, [show_notification]);

  //handle "notification" if new badge
  const [showBadgeNotification, setShowBadgeNotification] = useState(false);
  const hideBadgeNotification = () => {
    setBadgeNotification(false);
  }
  useEffect(() => {
    setShowBadgeNotification(show_badge_notification)
  }, [show_badge_notification])

  return (
    <div className="welt_wrapper">
      <motion.div
        initial={MotionInitial}
        animate={MotionAnimate}
        exit={MotionExit}
        transition={MotionTransition}
      >
        <header>
          <Navbar value="welt" clicked={clicked} counting={counting} startSound={startSound} stopSound={stopSound} />
        </header>
        <main>
          <div style={{ width: width }}>
            {world === "schutzrechte" && <Schutzrechte height={height} clicked={clicked} startSound={startSound} />}
            {world === "foerderrechte" && <MemoizedFoerderrechte height={height} clicked={clicked} startSound={startSound} />}
            {world === "beteiligungsrechte" && <Beteiligungsrechte height={height} clicked={clicked} startSound={startSound} />}
          </div>
          <div className="temporary_arrow">
            <img src={btn["btnback.svg"]} alt={t('general.back')} />
          </div>
        </main>

        {showPopup && <PopupNew handleClose={handleClose} setShowPopup={setShowPopup} content={content} sound={isSound} pages={nrPages} stopSound={stopSound} startSound={startSound} video={video} />}
        {showNotification === true && (
          <div className="notification_wrapper">
            <div className="notification">
              <div className="notification_close">
                <img className="btn" src={btn["btnclose.svg"]} onClick={() => hideNotification()} alt={t('general.close')} />
              </div>
              <div className="notification_content">
                <p onClick={() => navigateToAvatar()} className="link_to_avatar">{t('welt.notification_parts')}</p>
                <div className="linkToAvatar">
                  <img src={btn["btncontinue.svg"]} className="btn btn_to_avatar" onClick={() => navigateToAvatar()} alt={t('general.next')} />
                </div>
              </div>
            </div>
          </div>
        )}
        {showBadgeNotification !== false && (
          <div className="notification_wrapper">
            <div className="notification">
              <div className="notification_close">
                <img className="btn" src={btn["btnclose.svg"]} onClick={() => hideBadgeNotification()} alt={t('general.close')} />
              </div>
              <div className="notification_content">
                <div id="img_wrapper">
                  <img src={showBadgeNotification === "schutzrechte" ? medals["badge_unterwasser_small.png"] : (showBadgeNotification === "foerderrechte" ? medals["badge_vulkan_small.png"] : medals["badge_weltall_small.png"])} className="badge_img" alt="" />
                  <div id="rays"></div>
                </div>
                <h3>{t('welt.notification_badge1')}{showBadgeNotification === "schutzrechte" ? "Schutzwelt" : (showBadgeNotification === "foerderrechte" ? "Förderwelt" : "Beteiligungswelt")}{t('welt.notification_badge2')}</h3>
              </div>
            </div>
          </div>
        )}
      </motion.div>
    </div>
  );
}