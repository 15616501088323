//Quiz
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../../hooks/useUserContext';
import CustomQuiz from '../../games/CustomQuiz/CustomQuiz';
import { quiz_de } from './schutz_vor_ausbeutung/quiz_de';
import { quiz_fr } from './schutz_vor_ausbeutung/quiz_fr';
import { quiz_it } from './schutz_vor_ausbeutung/quiz_it';

export default function SchutzVorAusbeutung({oldAndNewScore}) {
    const { games, updateGames, calculateScore, score } = useUserContext();
    const quiz_game = games.schutz_vor_ausbeutung;
    //const max_points = games.quiz1.points_max;

    const { i18n } = useTranslation();
    let quiz = '';
    switch(i18n.language) {
        case 'de':
            quiz = quiz_de;
            break;
        case 'fr':
            quiz = quiz_fr;
            break;
        case 'it':
            quiz = quiz_it;
            break;
        default:
            quiz = quiz_de;
    };

    const setQuizResult = (points) => {
        //console.log(obj.correctPoints);
        const schutz_vor_ausbeutung = { ...quiz_game, state: 'done', points_achieved: points };
        const games_new = { ...games, schutz_vor_ausbeutung: schutz_vor_ausbeutung };
        updateGames(games_new);
        const current_game = "schutz_vor_ausbeutung";
        let total_score = calculateScore(current_game);
        total_score += points;
        const oldScore = score;
        const newScore = total_score;
        oldAndNewScore(oldScore, newScore);
    };

    return (
        <div>
            <CustomQuiz inputQuiz={quiz} finishFunction={setQuizResult}/>
        </div>
    )
}
