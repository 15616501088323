export const quiz_de =  {
    "general": {
      "title": "Recht auf Förderung.",
      "intro": "Was siehst du auf dem Bildausschnitt? Worin wird das Kind gefördert?",
      "finishButton": "Antworten prüfen",
      "nrQuestions": 5,
      "maxPoints": 50,
      "resultTitle": "Geschafft!",
      "resultText": "Du hast <correctQuestions> von <questions> Fragen richtig beantwortet und damit <points> von <pointsMax> Punkten erreicht.",
      "button_back": "zurück",
      "button_forward": "nächste"
    },
    "questions": [
      {
        "question": "Wähle an was stimmt:",
        "picture": "frage1.jpg",
        "answers": [
          "Schwimmen",
          "Schreiben",
          "Duschen"
        ],
        "correctAnswer": [0],
        "point": 10,
        "evaluate": true
      },
      {
        "question": "Wähle an was stimmt:",
        "picture": "frage2.jpg",
        "answers": [
          "Rollschuh laufen",
          "Fahrrad fahren",
          "Auto fahren"
        ],
        "correctAnswer": [1],
        "point": 10,
        "evaluate": true
      },
      {
        "question": "Wähle an was stimmt:",
        "picture": "frage3.jpg",
        "answers": [
          "Zaubern",
          "Lesen",
          "Rechnen"
        ],
        "correctAnswer": [1],
        "point": 10,
        "evaluate": true
      },
      {
        "question": "Wähle an was stimmt:",
        "picture": "frage4.jpg",
        "answers": [
          "Zeichnen",
          "Rechnen",
          "Sport"
        ],
        "correctAnswer": [2],
        "point": 10,
        "evaluate": true
      },
      {
        "question": "Wähle an was stimmt:",
        "picture": "frage5.jpg",
        "answers": [
          "Rätsel lösen",
          "Mit Holz basteln",
          "Musik machen"
        ],
        "correctAnswer": [2],
        "point": 10,
        "evaluate": true
      }
      
    ]
  } 