export const quiz_de =  {
  "general": {
    "title": "Mobbing",
    "intro": "Was ist Mobbing? Beantworte die Fragen und sammle Punkte.",
    "finishButton": "Antworten prüfen",
    "nrQuestions": 4,
    "maxPoints": 40,
    "resultTitle": "Geschafft!",
    "resultText": "Du hast <correctQuestions> von <questions> Fragen richtig beantwortet und damit <points> von <pointsMax> Punkten erreicht.",
    "button_back": "zurück",
    "button_forward": "nächste"
  },
  "questions": [
    {
      "question": "Ist das Mobbing?",
      "picture": "mobbing1.png",
      "answers": [
        "Ja",
        "Nein"
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Ist das Mobbing?",
      "picture": "mobbing2.png",
      "answers": [
        "Ja",
        "Nein"
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Ist das Mobbing?",
      "picture": "mobbing3.png",
      "answers": [
        "Ja",
        "Nein"
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Ist das Mobbing?",
      "picture": "mobbing4.png",
      "answers": [
        "Ja",
        "Nein"
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
  ]
} 