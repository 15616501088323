import { image_importer } from "../helper/helper_functions";
import { useTranslation } from "react-i18next";

//styles
import './CSS/PopupNew.css';
import './CSS/Navbar.css';
import './CSS/SketchyBox.css';

//popup content components
import { useLocation } from 'react-router';
import { useUserContext } from '../hooks/useUserContext';
import { useEffect, useState } from 'react';
import SchutzPrivatleben from './popupcontent/schutz/SchutzPrivatleben';
import Diskriminierungsverbot from './popupcontent/schutz/Diskriminierungsverbot';
import AvatarIcon from './AvatarIcon';
import Sharenting from './popupcontent/schutz/Sharenting';
import DirekteGewalt from './popupcontent/schutz/DirekteGewalt';
import Mobbing from './popupcontent/schutz/Mobbing';
import SexualisierteGewalt from './popupcontent/schutz/SexualisierteGewalt';
import Loyalitaetskonflikt from './popupcontent/schutz/Loyalitaetskonflikt';
import SchutzVorAusbeutung from './popupcontent/schutz/SchutzVorAusbeutung';
import SchutzVorSuchtmittel from './popupcontent/schutz/SchutzVorSuchtmittel';
import IndirekteGewalt from './popupcontent/schutz/IndirekteGewalt';
import Kindeswohl from './popupcontent/foerderrechte/Kindeswohl';
import Gesundheit from './popupcontent/foerderrechte/Gesundheit';
import Lebensstandard from './popupcontent/foerderrechte/Lebensstandard';
import Bildung from './popupcontent/foerderrechte/Bildung';
import Foerderleistung from './popupcontent/foerderrechte/Foerderleistung';
import Freizeit from './popupcontent/foerderrechte/Freizeit';
import BeteiligungSchule from './popupcontent/beteiligungsrechte/BeteiligungSchule';
import BeteiligungSchule2 from './popupcontent/beteiligungsrechte/BeteiligungSchule2';
import BeteiligungKrise from './popupcontent/beteiligungsrechte/BeteiligungKrisen';
import BeteiligungKrise2 from './popupcontent/beteiligungsrechte/BeteiligungKrisen2';
import BeteiligungPolitik from './popupcontent/beteiligungsrechte/BeteiligungPolitik';
import BeteiligungFamilie from './popupcontent/beteiligungsrechte/BeteiligungFamilie';
import Informationsfreiheit from './popupcontent/beteiligungsrechte/Informationsfreiheit';
import Religionsfreiheit from './popupcontent/beteiligungsrechte/Religionsfreiheit';
import SchutzPrivatleben2 from './popupcontent/schutz/SchutzPrivatleben2';
import Informationsfreiheit2 from './popupcontent/beteiligungsrechte/Informationsfreiheit2';
import GamesOverview from './GamesOverview';
import GesundheitPsychisch from "./popupcontent/foerderrechte/GesundheitPsychisch";
import Speaker from "./Speaker";

export default function PopupNew({ handleClose, content, stopSound, startSound, sound, pages, video }) {
    const {t} = useTranslation();
    const { games, settings } = useUserContext();

    //import images
    const btn = image_importer(require.context('../assets/btn', false, /\.(png|jpe?g|svg)$/));
    if (content !== "games_overview") {
        var scenes;
        switch (games[content].world) {
            case 'schutzrecht':
                scenes = image_importer(require.context('../assets/scenes/schutzrechte', false, /\.(png|jpe?g|svg)$/));
                break;
            case 'foerderrecht':
                scenes = image_importer(require.context('../assets/scenes/foerderrechte', false, /\.(png|jpe?g|svg)$/));
                break;
            case 'beteiligungsrecht':
                scenes = image_importer(require.context('../assets/scenes/beteiligungsrechte', false, /\.(png|jpe?g|svg)$/));
                break;
        }
    }

    //initialize variable to track on which world
    const location = useLocation();
    const currentWorld = location.pathname.substring(location.pathname.lastIndexOf("/") + 1);

    /*typing animation*/
    const [textToWrite, setTextToWrite] = useState("");
    const [textPart, setTextPart] = useState("");
    const typewriter = (text) => {
        setTextPart("")
        setTimeout(() => {
            let i = 0;
            window.myInterval = setInterval(() => {
                if (i <= text.length) {
                    setTextPart(text.substring(0, i));
                    i++;
                } else {
                    return;
                }
            }, 70)
        }, 2000)
    };
    useEffect(() => {
        if (content !== "games_overview") { setTextToWrite(games[content].intro) };
    }, [])
    useEffect(() => {
        typewriter(textToWrite);
    }, [textToWrite])

    //track if "TTS" is clicked -> impact on bgsound start / stop
    const [clickedSpeak, setClickedSpeak] = useState(false);

    //variables and function to handle change of score -> animate score counting
    const [scoreBefore, setScoreBefore] = useState(0);
    const [scoreAfter, setScoreAfter] = useState(0);
    const oldAndNewScore = (oldScore, newScore) => {
        setScoreAfter(newScore);
        setScoreBefore(oldScore);
    };

    //variable to track on which page
    const [page, setPage] = useState(1);

    //function to handle clicking on "back" button
    const clickBack = (e) => {
        // if content is with sound, mute sound if on page 3, start background sound if on page 2
        if (settings.sound === "soundOn" && clickedSpeak === false) {
            if (sound === true && (page === 3)) {
                stopSound();
            } else if (sound === true && page === 2) {
                startSound(settings[games[content].world], true);
            };
        } else if (clickedSpeak === true && (page === 3 || page === 4)) {
            document.getElementById('audio_text').src = "";
        }
        if (page === 2) { setTextToWrite(games[content].intro) };
        if ((page === 3 && pages === 1) || (page === 4 && pages === 2)) {
            window.clearInterval(window.myInterval);
            setTextPart(textToWrite);
        };
        setPage(page - 1);
    };

    //function to handle clicking on "forward" button
    const clickForward = (e) => {
        if (video===true && page===2) {
            let yt_video = document.getElementById("yt_video");
            yt_video.contentWindow.postMessage( '{"event":"command", "func":"pauseVideo", "args":""}', '*');
        }
        //If content is with sound, mute the background sound if comes on site 2, if on site 3 start background sound
        if (settings.sound === "soundOn" && clickedSpeak === false) {
            if (sound === true && page === 1) {
                stopSound();
            } else if (sound === true && page === 2) {
                startSound(settings[games[content].world], true);
            };
        } else if (clickedSpeak === true && page === 1) {
            document.getElementById('audio_text').src = "";
        }
        if ((page === 2 && pages === 1) || (page === 3 && pages === 2)) { setTextToWrite(games[content].outro) }
        if (page === 1) {
            window.clearInterval(window.myInterval);
            setTextPart(textToWrite);
        };
        setPage(page + 1);
    };

    //function to handle clicking on "close" button
    const clickClose = (e) => {
        //setShowPopup(false);
        handleClose(scoreBefore, scoreAfter);
        //If content is with sound, start the background sound
        if (settings.sound === "soundOn" && clickedSpeak === false) {
            if (sound === true && page === 2) {
                startSound(settings[games[content].world], true);
            } else if (clickedSpeak === true) {
                startSound(settings[games[content].world], true)
            }
        } else if (settings.sound === "soundOn" && clickedSpeak === true) {
            startSound(settings[games[content].world], true);
            document.getElementById('audio_text').src = "";
        };

    };

    //function to handle clicking on "finish" on the last page
    const clickFinish = (e) => {
        if (clickedSpeak === true && settings.sound === "soundOn") {
            startSound(settings[games[content].world], true);
            document.getElementById('audio_text').src = "";
        };
        handleClose(scoreBefore, scoreAfter);
    }

    //function to handle clicking on "TTS" button -> set src and play audio
    const clickSpeak = (audio) => {
        setClickedSpeak(true);
        if (settings.sound === "soundOn") { stopSound() };
        document.getElementById('audio_text').src = audio;
        document.getElementById('audio_text').play();
        //speak({text: text, voice: voice, rate: rate, pitch: pitch});
        //if (settings.sound === "soundOn") {startSound(settings[current_world] ,false)};
    }

    if (content !== "games_overview") {
        return (
            <>
                <div className={currentWorld + "_content_games"}></div>
                <div id={currentWorld + "_popup"}>
                    <div className={(page !== 1) ? "intro inactive" : "intro"} id="page1">
                        <div className="intro-top">
                            <div className="intro-message">
                                <div className="message-text">{textPart}</div>
                                {/*<div className="speaker-button"><img src={btn["btnspeak.svg"]} onClick={()=>clickSpeak(games[content].intro_audio)} /></div>*/}
                                <div className="speaker-button"><Speaker file={games[content].intro_audio} clickSpeak={clickSpeak} /></div>
                            </div>
                        </div>
                        <div className="intro-bottom">
                            <div className="intro-bottom-left">
                                <AvatarIcon />
                            </div>
                            <div className="intro-bottom-right">
                                <img src={scenes[games[content].srcIntro]} alt={scenes[games[content].name]} />
                            </div>
                        </div>
                    </div>
                    <div className={(page !== 2) ? "content inactive" : "content"} id="page2">
                        {content === "sexualisierte_gewalt" && <SexualisierteGewalt oldAndNewScore={oldAndNewScore} stopSound={stopSound} />}
                        {content === "schutz_privatleben" && <SchutzPrivatleben oldAndNewScore={oldAndNewScore} stopSound={stopSound} />}
                        {content === "diskriminierungsverbot" && <Diskriminierungsverbot oldAndNewScore={oldAndNewScore} />}
                        {content === "direkte_gewalt" && <DirekteGewalt oldAndNewScore={oldAndNewScore} />}
                        {content === "loyalitaetskonflikt" && <Loyalitaetskonflikt oldAndNewScore={oldAndNewScore} />}
                        {content === "mobbing" && <Mobbing oldAndNewScore={oldAndNewScore} />}
                        {content === "sharenting" && <Sharenting oldAndNewScore={oldAndNewScore} />}
                        {content === "games_overview" && <GamesOverview />}
                        {content === "schutz_vor_ausbeutung" && <SchutzVorAusbeutung oldAndNewScore={oldAndNewScore} />}
                        {content === "schutz_vor_suchtmittel" && <SchutzVorSuchtmittel oldAndNewScore={oldAndNewScore} />}
                        {content === "indirekte_gewalt" && <IndirekteGewalt oldAndNewScore={oldAndNewScore} />}
                        {content === "kindeswohl" && <Kindeswohl oldAndNewScore={oldAndNewScore} />}
                        {content === "gesundheit" && <Gesundheit oldAndNewScore={oldAndNewScore} />}
                        {content === "gesundheit_psychisch" && <GesundheitPsychisch oldAndNewScore={oldAndNewScore} />}
                        {content === "lebensstandard" && <Lebensstandard oldAndNewScore={oldAndNewScore} />}
                        {content === "bildung" && <Bildung oldAndNewScore={oldAndNewScore} />}
                        {content === "foerderleistung" && <Foerderleistung oldAndNewScore={oldAndNewScore} />}
                        {content === "freizeit" && <Freizeit oldAndNewScore={oldAndNewScore} />}
                        {content === "beteiligung_schule" && <BeteiligungSchule2 oldAndNewScore={oldAndNewScore} />}
                        {content === "beteiligung_krise" && <BeteiligungKrise oldAndNewScore={oldAndNewScore} />}
                        {content === "beteiligung_politik" && <BeteiligungPolitik oldAndNewScore={oldAndNewScore} />}
                        {content === "beteiligung_familie" && <BeteiligungFamilie oldAndNewScore={oldAndNewScore} />}
                        {content === "informationsfreiheit" && <Informationsfreiheit oldAndNewScore={oldAndNewScore} />}
                        {content === "religionsfreiheit" && <Religionsfreiheit oldAndNewScore={oldAndNewScore} />}
                    </div>
                    <div className={(pages === 2 && page === 3) ? "page_2" : "page_2 inactive"} id="page2special">
                        {content === "schutz_privatleben" && <SchutzPrivatleben2 oldAndNewScore={oldAndNewScore} stopSound={stopSound} />}
                        {content === "beteiligung_krise" && <BeteiligungKrise2 oldAndNewScore={oldAndNewScore} />}
                        {content === "informationsfreiheit" && <Informationsfreiheit2 oldAndNewScore={oldAndNewScore} />}
                    </div>
                    <div className={((page === 3 && pages === 1) || (page === 4 && pages === 2)) ? "outro" : "outro inactive"} id="page3">
                        <div className="intro-top">
                            <div className="intro-message">
                                <div className="message-text">{textPart}</div>
                                {/*<div className="speaker-button"><img src={btn["btnspeak.svg"]} onClick={()=>clickSpeak(games[content].outro_audio)} /></div>*/}
                                <div className="speaker-button"><Speaker file={games[content].outro_audio} clickSpeak={clickSpeak} /></div>
                            </div>
                        </div>
                        <div className="intro-bottom">
                            <div className="intro-bottom-left">
                                <AvatarIcon />
                            </div>
                            <div className="intro-bottom-right">
                                <img src={scenes[games[content].srcIntro]} alt={scenes[games[content].name]} />
                            </div>
                        </div>
                    </div>

                    <div className="nav-bar">
                        <div className="nav-left">
                            <img className="btn" src={btn["btnclose.svg"]} onClick={(e) => clickClose(e)} alt={t('general.close')} />
                        </div>
                    </div>
                    <div className="nav-bar-bottom">
                        <div className="nav-left">
                            {(page === 2 || page === 3 || page === 4) &&
                                <img className="btn" src={btn["btnback.svg"]} onClick={(e) => clickBack(e)} alt={t('general.back')} />
                            }
                        </div>
                        <div className="nav-center"></div>
                        <div className="nav-right">
                            {(page === 1 || page === 2 || (page === 3 && pages === 2)) &&
                                <img className="btn" src={btn["btncontinue.svg"]} onClick={(e) => clickForward(e)} alt={t('general.next')} />
                            }
                            {((page === 3 && pages === 1) || (page === 4 && pages === 2)) &&
                                <img className="btn" src={btn["btnclose.svg"]} onClick={(e) => clickFinish(e)} alt={t('general.close')} />
                            }
                        </div>
                    </div>
                    <div className="audio_text">
                        <audio id="audio_text">
                            <source src="" type="audio/mp3" />
                        </audio>
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <div className="content_overview">
                <GamesOverview currentWorld={currentWorld} />
                <div className="nav-bar">
                    <div className="nav-left">
                        <img className="btn" src={btn["btnclose.svg"]} onClick={(e) => clickClose(e)} alt={t('general.close')}/>
                    </div>
                </div>
            </div>
        )
    }
}