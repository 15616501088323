import { useTranslation, Trans } from 'react-i18next';
import { LinkText } from '../../../helper/helper_functions';

export default function BeteiligungKrisen() {
    const { t } = useTranslation();
    return (
        <div className="outer_content">
            <div className="inner_content">
                <h2>{t('beteiligung_krise.main_title')}</h2>
                <p><b>{t('beteiligung_krise.instruction')}</b></p>
                <h3>{t('beteiligung_krise.title_definition')}</h3>
                <p>{t('beteiligung_krise.definition')}</p>
                <h3>{t('beteiligung_krise.title_answers')}</h3>
                <p>{t('beteiligung_krise.answers')}</p>
                <h3>{t('beteiligung_krise.title_procedure')}</h3>
                <p>{t('beteiligung_krise.procedure')}</p>
                <h3>{t('beteiligung_krise.title_important')}</h3>
                <p><Trans i18nKey={'beteiligung_krise.important'} components={{
                    link1: <LinkText to={t('beteiligung_krise.important_link')} title={t('beteiligung_krise.important_link_title')} />
                }}
                /></p>
            </div>
        </div>
    )
}