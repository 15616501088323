export const quiz_fr =  {
    "general": {
      "title": "Interdiction de discrimination",
      "intro": "Quels sont les trois mots que tu trouves dans le jeu de recherche ci-dessous?",
      "finishButton": "Vérifier les réponses",
      "nrQuestions": 1,
      "maxPoints": 50,
      "resultTitle": "C'est fait!",
      "resultText": "Tu as répondu correctement à <correctQuestions> de <questions> et tu as donc obtenu <points> de <pointsMax> points.",
      "button_back": "retour",
      "button_forward": "prochaine"
    },
    "questions": [
      {
        "question": "Quels sont les trois mots que tu trouves?",
        "picture": "suchsel_FR.png",
        "answers": [
          "FILIALITÉ",
          "HANDICAP",
          "RELIGION",
          "ÂGE",
          "MAUVAIS",
          "LANGUE",
          "TEINTE DE PEAU"
        ],
        "correctAnswer": [2,4,5],
        "point": 50,
        "evaluate": true
      },
    ]
  } 