export const quiz_fr =  {
  "general": {
    "title": "Mobbing",
    "intro": "Essaie de répondre aux questions suivantes. Tu recevras des points à la fin pour chaque réponse correcte. Amuse-toi bien et réussis! :-)",
    "finishButton": "Vérifier les réponses",
    "nrQuestions": 4,
    "maxPoints": 40,
    "resultTitle": "C'est fait!",
    "resultText": "Tu as répondu correctement à <correctQuestions> de <questions> et tu as donc obtenu <points> de <pointsMax> points.",
    "button_back": "retour",
    "button_forward": "prochaine"
  },
  "questions": [
    {
      "question": "Est-ce que c'est du mobbing?",
      "picture": "mobbing1.png",
      "answers": [
        "Oui",
        "Non"
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Est-ce que c'est du mobbing?",
      "picture": "mobbing2.png",
      "answers": [
        "Oui",
        "Non"
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Est-ce que c'est du mobbing?",
      "picture": "mobbing3.png",
      "answers": [
        "Oui",
        "Non"
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Est-ce que c'est du mobbing?",
      "picture": "mobbing4.png",
      "answers": [
        "Oui",
        "Non"
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
  ]
} 