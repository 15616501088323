import { image_importer } from '../../helper/helper_functions';

export default function ImageMap({ clicked, factor, imgWidth, viewbox, height, imgMap, folder }) {
    var images;
    switch (folder) {
        case 'foerderrechte':
            images = image_importer(require.context('../../assets/bg/foerderrechte', false, /\.(png|jpe?g|svg)$/));
            break;
        case 'beteiligungsrechte':
            images = image_importer(require.context('../../assets/bg/beteiligungsrechte', false, /\.(png|jpe?g|svg)$/));
            break;
        case 'schutzrechte':
            images = image_importer(require.context('../../assets/bg/schutzrechte', false, /\.(png|jpe?g|svg)$/));
            break;
        case 'game':
            images = image_importer(require.context('../../assets/games/imageMap', false, /\.(png|jpe?g|svg)$/));
            break
    }
    let points = '';
  return (
    <div style={{width: imgWidth}} className="image-mapper">
        <svg version="2" xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} >
        <image width={imgWidth} height={height} href={images[imgMap.imgSrc]}>
        <title>{imgMap.title}</title>
        <desc>{imgMap.desc}</desc>
        </image>

        {imgMap.areas && imgMap.areas.map((area) => (
            <a className="imgMap" id={area.name} key={area.id} onClick={(event) => clicked(event, area.name)} tabIndex={area.id + 6}>
                {area.type==='rect' && <rect x={area.x*factor} y={area.y*factor} width={area.width*factor} height={area.height*factor} alt={area.description} />}
                {area.type==='circle' && <circle cx={area.x*factor} cy={area.y*factor} r={area.r*factor} alt={area.description} />}
                {area.type==='polygon' && <polygon  alt={area.description}
                points={
                        area.coords.map((coord) => {
                            points = '';
                            points = points + Math.round(coord[0]*factor) + "," + Math.round(coord[1]*factor) + " ";
                            return points;
                        })
                    } 
                />}
            </a>
        ))}
        </svg>
    </div>
  )
}
