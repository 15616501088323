export const quiz_it =  {
  "general": {
    "title": "Protezione della privacy",
    "intro": "Risolvi il quiz!",
    "finishButton": "Controllare le risposte",
    "nrQuestions": 4,
    "maxPoints": 40,
    "resultTitle": "Ce l'hai fatta!",
    "resultText": "Hai risposto correttamente a <correctQuestions> domande su <questions> e quindi hai ottenuto <points> punti su <pointsMax>.",
    "button_back": "indietro",
    "button_forward": "prosegui"
  },
  "questions": [
    {
      "question": "Posso avere dei segreti?",
      "picture": "",
      "answers": [
        "Sì",
        "No"
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "La mia amica può scattarmi una foto senza chiedermelo?",
      "picture": "",
      "answers": [
        "Sì",
        "No"
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Devo dire a tutti che sono malato?",
      "picture": "",
      "answers": [
        "Sì",
        "No"
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Cosa posso fare se ho un segreto incriminante?",
      "picture": "",
      "answers": [
        "Parlarne con qualcuno (ad es. 147)",
        "Tenerlo per me"
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
  ]
}