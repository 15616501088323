export const quiz_de =  {
    "general": {
      "title": "Recht auf Freizeit",
      "intro": "Wie sieht deine Freizeit aus? Beantworte die Fragen.",
      "finishButton": "Antworten prüfen",
      "nrQuestions": 6,
      "maxPoints": 35,
      "resultTitle": "Geschafft!",
      "resultText": "Du hast <correctQuestions> von <questions> Fragen richtig beantwortet und damit <points> von <pointsMax> Punkten erreicht.",
      "button_back": "zurück",
      "button_forward": "nächste"
    },
    "questions": [
      {
        "question": "Was machst du lieber?",
        "picture": "",
        "answers": [
          "Ein Buch lesen",
          "Ein Bild malen",
          "Etwas basteln"
        ],
        "correctAnswer": [0,1,2],
        "point": 5,
        "evaluate": false
      },
      {
        "question": "Wo gehst du am liebsten schwimmen?",
        "picture": "",
        "answers": [
          "Im Pool",
          "Im See",
          "Im Fluss",
          "Im Hallenbad"
        ],
        "correctAnswer": [0,1,2,3],
        "point": 5,
        "evaluate": false
      },
      {
        "question": "Wo verbringst du gerne Zeit mit deinen Freund*innen?",
        "picture": "",
        "answers": [
          "Im Garten",
          "Zuhause",
          "In der Schule",
          "Im Wald",
          "Auf dem Sportplatz"
        ],
        "correctAnswer": [0,1,2,3,4],
        "point": 5,
        "evaluate": false
      },
      {
        "question": "Welche Musik hörst du gerne?",
        "picture": "",
        "answers": [
          "Pop",
          "Rock",
          "Hip-Hop",
          "Schlager"
        ],
        "correctAnswer": [0,1,2,3],
        "point": 5,
        "evaluate": false
      },
      {
        "question": "Wie erholst du dich am besten?",
        "picture": "",
        "answers": [
          "Beim Schlafen",
          "Beim Lesen",
          "Beim Freunde treffen",
          "Beim Musik hören"
        ],
        "correctAnswer": [0,1,2,3],
        "point": 5,
        "evaluate": false
      },
      {
        "question": "Super, nun unterscheide was Erholung, Spiel und kreativ sein ist?",
        "picture": "frage6.png",
        "answers": [
          "Blau: Freizeit, Rot: Erholung, Orange: kreativ sein",
          "Blau: Erholung, Rot: kreativ sein, Orange: Freizeit",
          "Blau: Erholung, Rot: Freizeit, Orange: kreativ sein",
          "Blau: kreativ sein, Rot: Erholung, Orange: Freizeit"
        ],
        "correctAnswer": [2],
        "point": 10,
        "evaluate": true
      },
      
    ]
  } 