import Answer from "./Answer.js";

export default function Question({ inputQuestion, checkAnswer, images }) {
    const answers = inputQuestion.question.answers;
    const image = inputQuestion.question.picture;

    return (
        <div className="question-wrapper-invisible" id={inputQuestion.id}>
            <div className="question-title">
                <h3>{inputQuestion.question['question']}</h3>
            </div>
            {image !== "" &&
                <div className="question-picture">
                    <img src={images[image]} alt="" />
                </div>
            }
            <div className="question-content">
                {
                    answers.map((answer, id) => {
                        return (
                            <Answer inputAnswer={{ answer: answer, id: id }} questionId={inputQuestion.id} key={id} checkAnswer={checkAnswer} />
                        )
                    })
                }
            </div>
        </div>
    )
}