export const quiz_de =  {
    "general": {
      "title": "Politische Beteiligung",
      "intro": "Wie kannst du mitbestimmen? Löse das Quiz!",
      "finishButton": "Antworten prüfen",
      "nrQuestions": 4,
      "maxPoints": 40,
      "resultTitle": "Geschafft!",
      "resultText": "Du hast <correctQuestions> von <questions> Fragen richtig beantwortet und damit <points> von <pointsMax> Punkten erreicht.",
      "button_back": "zurück",
      "button_forward": "nächste"
    },
    "questions": [
      {
        "question": "Was bedeutet das Wort 'Mitbestimmung'?",
        "picture": "",
        "answers": [
          "Ich darf alles selbst entscheiden.",
          "Ich habe das gleiche Recht wie die Erwachsenen, meine Meinung zu sagen.",
          "Ich darf nicht sagen, wenn mich etwas stört."
        ],
        "correctAnswer": [1],
        "point": 10,
        "evaluate": true
      },
      {
        "question": "Wo kannst du in der Politik mitbestimmen?",
        "picture": "",
        "answers": [
          "im Schwimmunterricht",
          "im Kinderparlament",
          "in den Ferien"
        ],
        "correctAnswer": [1],
        "point": 10,
        "evaluate": true
      },
      {
        "question": "Wo steht geschrieben, dass du mitbestimmen kannst?",
        "picture": "",
        "answers": [
          "in der Kinderrechtskonvention",
          "im Gesangsbuch",
          "in der Zeitung"
        ],
        "correctAnswer": [0],
        "point": 10,
        "evaluate": true
      },
      {
        "question": "Bei welchem Thema dürfen Kinder nicht mitbestimmen?",
        "picture": "",
        "answers": [
          "Wie der neue Spielplatz im Quartier aussehen soll",
          "Wie die Bibliothek für Kinder umgebaut werden soll",
          "Wer Präsidentin oder Präsident wird"
        ],
        "correctAnswer": [2],
        "point": 10,
        "evaluate": true
      },
    ]
  } 