export const quiz_it =  {
  "general": {
    "title": "Diritto al tempo libero",
    "intro": "Risolvi il seguente quiz per raccogliere punti...",
    "finishButton": "Controllare le risposte",
    "nrQuestions": 6,
    "maxPoints": 35,
    "resultTitle": "ce l'hai fatta!",
    "resultText": "Avete risposto correttamente a <correctQuestions> domande su <questions> e quindi avete ottenuto <points> punti su <pointsMax> .",
    "button_back": "indietro",
    "button_forward": "prosegui"
  },
  "questions": [
    {
      "question": "Cosa preferisce fare?",
      "picture": "",
      "answers": [
        "Leggere un libro",
        "Dipingere un quadro",
        "Fare bricolage"
      ],
      "correctAnswer": [0,1,2],
      "point": 5,
      "evaluate": false
    },
    {
      "question": "Dove ti piace di più nuotare?",
      "picture": "",
      "answers": [
        "In piscina",
        "Nel lago",
        "Nel fiume",
        "Nella piscina coperta"
      ],
      "correctAnswer": [0,1,2,3],
      "point": 5,
      "evaluate": false
    },
    {
      "question": "Dove ti piace trascorrere il tempo con i tuoi amici?",
      "picture": "",
      "answers": [
        "In giardino",
        "A casa",
        "A scuola",
        "Nella foresta",
        "Sul campo sportivo"
      ],
      "correctAnswer": [0,1,2,3,4],
      "point": 5,
      "evaluate": false
    },
    {
      "question": "Che musica ti piace ascoltare?",
      "picture": "",
      "answers": [
        "Pop",
        "Rock",
        "Hip-Hop",
        "Schlager"
      ],
      "correctAnswer": [0,1,2,3],
      "point": 5,
      "evaluate": false
    },
    {
      "question": "Come ti recuperi al meglio?",
      "picture": "",
      "answers": [
        "Dormire",
        "Leggere",
        "Incontrare Amici",
        "Ascoltare musica"
      ],
      "correctAnswer": [0,1,2,3],
      "point": 5,
      "evaluate": false
    },
    {
      "question": "Bene, ora distingui cosa è ricreazione, gioco, e creatività?",
      "picture": "frage6_it.png",
      "answers": [
        "Blu: gioco, rosso: ricreazione, arancione: creatività",
        "Blu: ricreazione, rosso: creatività, arancione: gioco",
        "Blu: ricreazione, rosso: gioco, arancione: creatività",
        "Blu: creatività, rosso: ricreazione, arancione: gioco"
      ],
      "correctAnswer": [2],
      "point": 10,
      "evaluate": true
    },
    
  ]
} 