export const quiz_fr =  {
  "general": {
    "title": "Protection de la vie privée",
    "intro": "Résous le quiz!",
    "finishButton": "Vérifier les réponses",
    "nrQuestions": 4,
    "maxPoints": 40,
    "resultTitle": "C'est fait!",
    "resultText": "Tu as répondu correctement à <correctQuestions> de <questions> et tu as donc obtenu <points> de <pointsMax> points.",
    "button_back": "retour",
    "button_forward": "suivant"
  },
  "questions": [
    {
      "question": "Puis-je avoir des secrets?",
      "picture": "",
      "answers": [
        "Oui",
        "Non"
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Mon amie peut-elle prendre une photo de moi sans me demander mon avis?",
      "picture": "",
      "answers": [
        "Oui",
        "Non"
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Dois-je dire à tout le monde que je suis malade?",
      "picture": "",
      "answers": [
        "Oui",
        "Non"
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Que puis-je faire si j'ai un secret compromettant ?",
      "picture": "",
      "answers": [
        "En parler à quelqu'un (ex. 147)",
        "Le garder pour moi"
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
  ]
}