export const quiz_it =  {
  "general": {
    "title": "Mobbing",
    "intro": "Cosa è il mobbing? Rispondi alle domande e raccogli punti.",
    "finishButton": "Controllare le risposte",
    "nrQuestions": 4,
    "maxPoints": 40,
    "resultTitle": "Ce l'hai fatta!",
    "resultText": "Hai risposto correttamente a <correctQuestions> domande su <questions> e quindi hoi ottenuto <points> punti su <pointsMax> .",
    "button_back": "indietro",
    "button_forward": "prosegui"
  },
  "questions": [
    {
      "question": "Si tratta di mobbing?",
      "picture": "mobbing1.png",
      "answers": [
        "Sì",
        "No"
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Si tratta di mobbing?",
      "picture": "mobbing2.png",
      "answers": [
        "Sì",
        "No"
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Si tratta di mobbing?",
      "picture": "mobbing3.png",
      "answers": [
        "Sì",
        "No"
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Si tratta di mobbing?",
      "picture": "mobbing4.png",
      "answers": [
        "Sì",
        "No"
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
  ]
} 