import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { UserProvider } from './context/UserContext';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

//For Friendly Analytics -> based on Matomo
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'

//internationalization
import './components/i18n';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";

//Library für Google-Analytics 4
//import ReactGA from 'react-ga4';

const instance = createInstance({
  urlBase: 'https://app.friendlyanalytics.ch/',
  siteId: 85,
  trackerUrl: 'https://app.friendlyanalytics.ch/matomo.php', // optional, default value: `${urlBase}matomo.php`
  srcUrl: 'https://app.friendlyanalytics.ch/matomo.js', // optional, default value: `${urlBase}matomo.js`
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: { // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10 // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  configurations: { // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: false,
    setRequestMethod: 'POST'
  }
});

//Google Analytics initialisieren und Pageviews tracken lassen
//ReactGA.initialize("G-GFZV92KL42");
//ReactGA.send("pageview");

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
//staging firebase
const firebaseConfig = {
  apiKey: "AIzaSyBhEaWoT0Ayy3mOI53lIn4rFUjTW6cZkDM",
  authDomain: "kinderrechteapptest.firebaseapp.com",
  projectId: "kinderrechteapptest",
  storageBucket: "kinderrechteapptest.appspot.com",
  messagingSenderId: "948406101180",
  appId: "1:948406101180:web:65dd79173ceae675b35cb9",
};

//live firebase
/*const firebaseConfig = {
  apiKey: "AIzaSyBmbsh9IHMP24NxJ_2MAnAGmxNcPczcvDY",
  authDomain: "kidimo.firebaseapp.com",
  projectId: "kidimo",
  storageBucket: "kidimo.appspot.com",
  messagingSenderId: "625276109064",
  appId: "1:625276109064:web:1a848feb48d5db1aaa6cbd"
};*/

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

//Service Worker registrieren -> Offline-First (funktioniert nur in Deployment)
serviceWorkerRegistration.register();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MatomoProvider value={instance}>
      <UserProvider>
        <App />
      </UserProvider>
    </MatomoProvider>
  </React.StrictMode>
);