export const quiz_de =  {
    "general": {
      "title": "Diskriminierungsverbot",
      "intro": "Welche drei Wörter findest du im nachfolgenden Buchstabensalat?",
      "finishButton": "Antworten prüfen",
      "nrQuestions": 1,
      "maxPoints": 50,
      "resultTitle": "Geschafft!",
      "resultText": "Du hast <correctQuestions> von <questions> Fragen richtig beantwortet und damit <points> von <pointsMax> Punkten erreicht.",
      "button_back": "zurück",
      "button_forward": "nächste"
    },
    "questions": [
      {
        "question": "Welche drei Wörter findest du?",
        "picture": "suchsel.png",
        "answers": [
          "HERKUNFT",
          "BEHINDERUNG",
          "RELIGION",
          "ALTER",
          "GESCHLECHT",
          "SPRACHE",
          "HAUTFARBE"
        ],
        "correctAnswer": [4,5,6],
        "point": 50,
        "evaluate": true
      },
    ]
  } 