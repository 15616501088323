export const quiz_it =  {
  "general": {
    "title": "Quiz sul lavoro minorile",
    "intro": "Cosa sai sul lavoro minorile? Risolvi il quiz e raccogli punti!",
    "finishButton": "Controllare le risposte",
    "nrQuestions": 7,
    "maxPoints": 70,
    "resultTitle": "Ce l'hai fatta!",
    "resultText": "Hai risposto correttamente a <correctQuestions> domande su <questions> e quindi hai ottenuto <points> punti su <pointsMax>.",
    "button_back": "indietro",
    "button_forward": "prosegui"
  },
  "questions": [
    {
      "question": "Quanti bambini nel mondo devono lavorare?",
      "picture": "",
      "answers": [
        "70 mila",
        "68 milioni",
        "168 milioni"
      ],
      "correctAnswer": [2],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Il lavoro minorile è ...",
      "picture": "",
      "answers": [
        "vietato.",
        "un cambiamento interessante.",
        "bene, per guadagnare soldi per mamma e papà."
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Che cos'è il lavoro minorile?",
      "picture": "",
      "answers": [
        "quando i bambini consegnano i giornali e portano a spasso il cane del vicino una volta alla settimana.",
        "se i bambini devono aiutare in casa per 1-2 ore al giorno.",
        "se il lavoro è dannoso per la salute, richiede troppo tempo o non lascia ai bambini il tempo di andare a scuola."
      ],
      "correctAnswer": [2],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Dove al mondo si trova la maggior parte dei bambini che lavora?",
      "picture": "",
      "answers": [
        "nell'Africa a sud del Sahara",
        "in India e Cina",
        "in Europa"
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Alcune forme di lavoro minorile sono particolarmente dannose perché",
      "picture": "",
      "answers": [
        "i bambini non hanno la forza e il tempo di frequentare la scuola a causa del loro lungo e faticoso lavoro.",
        "I bambini devono alzarsi ogni mattina.",
        "I bambini hanno molto tempo per giocare."
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Molti bambini devono lavorare perché",
      "picture": "",
      "answers": [
        "vogliono provare il lavoro che vorrebbero fare in seguito.",
        "usano il denaro per pagare il cibo ai genitori e ai fratelli.",
        "con i soldi comprano giochi per computer."
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Tutti noi possiamo fare qualcosa per il lavoro lavoro minorile, assicurandoci che",
      "picture": "",
      "answers": [
        "si acquisti sempre il prodotto più economico.",
        "si chieda al momento dell'acquisto se il prodotto è stato realizzato da bambini.",
        "si creda che tutte le persone pensino sempre alle esigenze dei bambini."
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
  ]
}