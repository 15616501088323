export const quiz_de = {
    "general": {
      "title": "Recht auf Bildung, Schule und Berufsausbildung",
      "intro": "Was weisst du alles über das Recht auf Schule? Löse das Quiz.",
      "finishButton": "Antworten prüfen",
      "nrQuestions": 4,
      "maxPoints": 40,
      "resultTitle": "Geschafft!",
      "resultText": "Du hast <correctQuestions> von <questions> Fragen richtig beantwortet und damit <points> von <pointsMax> Punkten erreicht.",
      "button_back": "zurück",
      "button_forward": "nächste"
    },
    "questions": [
      {
        "question": "Müssen Kinder zur Schule gehen?",
        "picture": "",
        "answers": [
          "Nur, wenn ihre Eltern es so wollen.",
          "Ja, sie müssen zur Schule gehen.",
          "Sie können selbst entscheiden, ob und wie lange sie zur Schule gehen."
        ],
        "correctAnswer": [1],
        "point": 10,
        "evaluate": true
      },
      {
        "question": "Wie viele Kinder auf der Welt können nicht zur Schule gehen?",
        "picture": "",
        "answers": [
          "20 Millionen",
          "50 Millionen",
          "473 Millionen"
        ],
        "correctAnswer": [2],
        "point": 10,
        "evaluate": true
      },
      {
        "question": "Artikel 29 der UN-Kinderrechtskonvention besagt, dass die Bildung darauf gerichtet sein muss, dass ...",
        "picture": "",
        "answers": [
          "... die Kinder nach der Schule alle Formeln und mindestens drei Sprachen fliessend sprechen.",
          "... die Kinder sich frei entwickeln und ihre Begabung frei entfalten können.",
          "... die Kinder brav und gut erzogen werden."
        ],
        "correctAnswer": [1],
        "point": 10,
        "evaluate": true
      },
      {
        "question": "Warum gehen in ärmeren Ländern viele Mädchen nicht zur Schule?",
        "picture": "",
        "answers": [
          "Weil sie zuhause im Haushalt helfen müssen.",
          "Weil sie keine Lust haben.",
          "Weil die Schule zu anstrengend ist."
        ],
        "correctAnswer": [0],
        "point": 10,
        "evaluate": true
      },
    ]
  } 