import { image_importer } from '../../../helper/helper_functions';

import { useEffect, useState } from 'react';
import { useUserContext } from '../../../hooks/useUserContext';
import '../../CSS/Memory.css';
import SingleCard from './religionsfreiheit/SingleCard';

import { useTranslation } from 'react-i18next';

export default function Religionsfreiheit({oldAndNewScore}) {
  const { t } = useTranslation();

  //infos for calculating and updating score of game and total score
  const { games, updateGames, calculateScore, score, language } = useUserContext();

  var images;
  if (language.includes("de")) {
    images = image_importer(require.context('../../../assets/games/beteiligungsrechte/religionsfreiheit/de', false, /\.(png|jpe?g|svg)$/));
  } else if (language.includes("fr")) {
    images = image_importer(require.context('../../../assets/games/beteiligungsrechte/religionsfreiheit/fr', false, /\.(png|jpe?g|svg)$/));
  } else if (language.includes("it")) {
    images = image_importer(require.context('../../../assets/games/beteiligungsrechte/religionsfreiheit/it', false, /\.(png|jpe?g|svg)$/));
  } else {
    images = image_importer(require.context('../../../assets/games/beteiligungsrechte/religionsfreiheit/de', false, /\.(png|jpe?g|svg)$/));
  }

  const cardImages = [
    { "src": images["regiligionsfreiheit_A1.png"], matched: false },
    { "src": images["regiligionsfreiheit_A2.png"], matched: false },
    { "src": images["regiligionsfreiheit_B1.png"], matched: false },
    { "src": images["regiligionsfreiheit_B2.png"], matched: false },
    { "src": images["regiligionsfreiheit_C1.png"], matched: false },
    { "src": images["regiligionsfreiheit_C2.png"], matched: false },
    { "src": images["regiligionsfreiheit_D1.png"], matched: false },
    { "src": images["regiligionsfreiheit_D2.png"], matched: false },
    { "src": images["regiligionsfreiheit_E1.png"], matched: false },
    { "src": images["regiligionsfreiheit_E2.png"], matched: false },
    { "src": images["regiligionsfreiheit_F1.png"], matched: false },
    { "src": images["regiligionsfreiheit_F2.png"], matched: false },
  ];

  const memory = games.religionsfreiheit;
  const max_points = games.religionsfreiheit.points_max;

  const [cards, setCards] = useState([]);
  const [turns, setTurns] = useState(0);
  const [choiceOne, setChoiceOne] = useState(null);
  const [choiceTwo, setChoiceTwo] = useState(null);
  const [disabled, setDisabled] = useState(false);

  //shuffle cards
  const shuffleCards = () => {
    //let shuffledCardImages = [...cardImages]
    //  .sort(() => Math.random() - 0.5)
    //  .map((card) => ({ ...card, id: Math.random() }));
    let shuffledCardImages = cardImages.slice(0, 12)
      //const shuffledCards = [...shuffledCardImages, ...shuffledCardImages]
      .sort(() => Math.random() - 0.5)
      .map((card) => ({ ...card, id: Math.random() }));

      setChoiceOne(null);
      setChoiceTwo(null);
      setCards(shuffledCardImages);
      setTurns(0);
  };

  //handle a choice
  const handleChoice = (card) => {
    choiceOne ? setChoiceTwo(card) : setChoiceOne(card);
  };

  //compare 2 selected cards
  useEffect(() => {
    if (choiceOne && choiceTwo) {
      setDisabled(true);

      if (String(choiceOne.src).substring(0, 35) === String(choiceTwo.src).substring(0, 35)) {
        setCards(prevCards => {
          return prevCards.map(card => {
            if ((card.src === choiceOne.src) || (card.src === choiceTwo.src)) {
              return { ...card, matched: true };
            } else {
              return card;
            }
          });
        });
        resetTurn();
      } else {
        setTimeout(() => resetTurn(), 4000);
      }
    }
  }, [choiceOne, choiceTwo])

  const resetTurn = () => {
    setChoiceOne(null);
    setChoiceTwo(null);
    setTurns(prevTurns => prevTurns + 1);
    setDisabled(false);
  };

  // check if all cards are turned -> yes: calculate score and total_score & safe it to localStorage
  useEffect(() => {
    if (cards.length > 0) {
      let finished = true;
      cards.map(card => {
        if (card.matched === false) {
          return finished = false;
        };
      });
      if (finished === true) {
        let temp_score = Math.round(max_points - (1/4)*turns);
        if (temp_score<0) {temp_score=0};
        const memory_new = { ...memory, state: 'done', points_achieved: temp_score };
        const games_new = { ...games, religionsfreiheit: memory_new };
        updateGames(games_new);
        const current_game = "religionsfreiheit";
        let total_score = calculateScore(current_game);
        total_score += temp_score;
        const oldScore = score;
        const newScore = total_score;
        oldAndNewScore(oldScore, newScore);
        //updateScore(total_score);
        document.getElementById("msg").style.visibility = "visible";
      };
    };
  }, [cards]);

  useEffect(() => {
    shuffleCards();
  }, []);

  return (
    <div className="Memory">
      <h3>{t('religionsfreiheit.description')}</h3>
      <div className="card-grid">
        {cards.map(card => (
          <SingleCard
          card={card}
          key={card.id}
          handleChoice={handleChoice}
          flipped={card === choiceOne || card === choiceTwo || card.matched === true}
          disabled={disabled}
          />
        ))}
      </div>
      {/*<p>Turns: {turns}</p>*/}
      <div className="msg_success_memory" id="msg">
          <p>{t('religionsfreiheit.msg_success')}</p>
        </div>
    </div>
  );
}
