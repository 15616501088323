import { image_importer } from "../helper/helper_functions";

//import components
import { ShuffleObject } from "../helper/helper_functions";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//import config
import avatar_config from "./settings/avatar_modification.json";

//import user data
import { useUserContext } from "../hooks/useUserContext";

//import styles
import "./CSS/AvatarAdvanced_bubbles.css";


export default function AvatarAdvancedBubbles({ initial }) {
    const { t } = useTranslation();
    //import images
    const btn = image_importer(require.context('../assets/btn', false, /\.(png|jpe?g|svg)$/));
    const avatar_img = image_importer(require.context('../assets/avatar', false, /\.(png|jpe?g|svg)$/));
    const avatar_img_small = image_importer(require.context('../assets/avatar/small', false, /\.(png|jpe?g|svg)$/));

    const [contentSelection, setContentSelection] = useState('hair');

    //read parts from config file
    const shoes = avatar_config.shoes;
    const hairs = avatar_config.hair;
    const skins = avatar_config.skin;
    const clothes_top = avatar_config.clothes_top;
    const clothes_bottom = avatar_config.clothes_bottom;
    const faces = avatar_config.face;

    //shuffle the arrays with the avatar parts
    const shoesArray = ShuffleObject(shoes);
    const hairsArray = ShuffleObject(hairs);
    const skinsArray = ShuffleObject(skins);
    const clothesTopArray = ShuffleObject(clothes_top);
    const clothesBottomArray = ShuffleObject(clothes_bottom);
    const facesArray = ShuffleObject(faces);

    const { name, avatarParts, updateAvatarParts, updateName, gamesInfos, accessoire, surprise } = useUserContext();

    //if already created user -> pick the parts from there
    try { var currentShoe = avatar_img[avatarParts.shoes] } catch { currentShoe = avatar_img[shoesArray[0].big] };
    try { var currentHair = avatar_img[avatarParts.hair] } catch { currentHair = avatar_img[hairsArray[0].big] };
    try { var currentSkin = avatar_img[avatarParts.skin] } catch { currentSkin = avatar_img[skinsArray[0].big] };
    try { var currentClothesTop = avatar_img[avatarParts.clothes_top] } catch { currentClothesTop = avatar_img[clothesTopArray[0].big] };
    try { var currentClothesBottom = avatar_img[avatarParts.clothes_bottom] } catch { currentClothesBottom = avatar_img[clothesBottomArray[0].big] };
    try { var currentFace = avatar_img[avatarParts.face] } catch { currentFace = avatar_img[facesArray[0].big] };
    try { var currentAccessoire = avatar_img[avatarParts.accessoire] } catch { currentAccessoire = avatar_img["empty.png"] };
    try { var currentSurprise = avatar_img[avatarParts.surprise] } catch { currentSurprise = avatar_img["empty.png"] };
    try { var currentBadge1 = gamesInfos.schutzrechte.finished } catch { currentBadge1 = 0 };
    try { var currentBadge2 = gamesInfos.beteiligungsrechte.finished } catch { currentBadge2 = 0 };
    try { var currentBadge3 = gamesInfos.foerderrechte.finished } catch { currentBadge3 = 0 };

    //function for changing the username
    const changeName = (e) => {
        if (!initial) {
            updateName(e.target.value);
        }
    };

    //function to show the menu with the specific avatar parts
    const clicked = (event, name) => {
        event.stopPropagation();
        const width = window.innerWidth;
        let offset_top = -20;
        setContentSelection(name);
        if (name === contentSelection && document.getElementById("selection_bubble").style.display == "block") {
            document.getElementById("selection_bubble").style.display = "none";
        } else {
            if (name == "clothes_top" || name == "clothes_bottom") {
                document.getElementById("selection_bubble").style.top = event.clientY - offset_top + "px";
                document.getElementById("selection_bubble").style.right = "";
                document.getElementById("selection_bubble").style.bottom = "";
                document.getElementById("selection_bubble").style.left = event.clientX + 10 + "px";
                document.getElementById("selection_bubble").style.display = "block";
            } else if (name == "accessoire" || name == "surprise") {
                document.getElementById("selection_bubble").style.top = "";
                document.getElementById("selection_bubble").style.right = "";
                document.getElementById("selection_bubble").style.bottom = window.innerHeight - event.clientY - 10 + "px";
                document.getElementById("selection_bubble").style.left = event.clientX + 10 + "px";
                document.getElementById("selection_bubble").style.display = "block";
            } else if (name == "skin" || name == "hair") {
                document.getElementById("selection_bubble").style.top = event.clientY - offset_top + "px";
                document.getElementById("selection_bubble").style.right = width - event.clientX + 10 + "px";
                document.getElementById("selection_bubble").style.bottom = "";
                document.getElementById("selection_bubble").style.left = "";
                document.getElementById("selection_bubble").style.display = "block";
            } else if (name == "shoes" || name == "face") {
                document.getElementById("selection_bubble").style.top = "";
                document.getElementById("selection_bubble").style.right = width - event.clientX + 10 + "px";
                document.getElementById("selection_bubble").style.bottom = window.innerHeight - event.clientY - 10 + "px";
                document.getElementById("selection_bubble").style.left = "";
                document.getElementById("selection_bubble").style.display = "block";
            };
        }
    };

    //function to update the localstorage with new parts
    const saveAvatarParts = () => {
        //get the images for the avatar
        const shoes = document.getElementById('shoes_img').getAttribute('src').substring(document.getElementById('shoes_img').getAttribute('src').lastIndexOf("/") + 1, document.getElementById('shoes_img').getAttribute('src').indexOf(".")) + ".png";
        const hair = document.getElementById('hair_img').getAttribute('src').substring(document.getElementById('hair_img').getAttribute('src').lastIndexOf("/") + 1, document.getElementById('hair_img').getAttribute('src').indexOf(".")) + ".png";
        const skin = document.getElementById('skin_img').getAttribute('src').substring(document.getElementById('skin_img').getAttribute('src').lastIndexOf("/") + 1, document.getElementById('skin_img').getAttribute('src').indexOf(".")) + ".png";
        const clothes_top = document.getElementById('clothes_top_img').getAttribute('src').substring(document.getElementById('clothes_top_img').getAttribute('src').lastIndexOf("/") + 1, document.getElementById('clothes_top_img').getAttribute('src').indexOf(".")) + ".png";
        const clothes_bottom = document.getElementById('clothes_bottom_img').getAttribute('src').substring(document.getElementById('clothes_bottom_img').getAttribute('src').lastIndexOf("/") + 1, document.getElementById('clothes_bottom_img').getAttribute('src').indexOf(".")) + ".png";
        const face = document.getElementById('face_img').getAttribute('src').substring(document.getElementById('face_img').getAttribute('src').lastIndexOf("/") + 1, document.getElementById('face_img').getAttribute('src').indexOf(".")) + ".png";
        const face_small = document.getElementById('face_img').getAttribute('src').substring(document.getElementById('face_img').getAttribute('src').lastIndexOf("/") + 1, document.getElementById('face_img').getAttribute('src').indexOf(".")) + ".png";
        const hair_small = document.getElementById('hair_img').getAttribute('src').substring(document.getElementById('hair_img').getAttribute('src').lastIndexOf("/") + 1, document.getElementById('hair_img').getAttribute('src').indexOf(".")) + ".png";
        const skin_small = document.getElementById('skin_img').getAttribute('src').substring(document.getElementById('skin_img').getAttribute('src').lastIndexOf("/") + 1, document.getElementById('skin_img').getAttribute('src').indexOf(".")) + "_small.png";
        const accessoire = document.getElementById('accessoire_img').getAttribute('src').substring(document.getElementById('accessoire_img').getAttribute('src').lastIndexOf("/") + 1, document.getElementById('accessoire_img').getAttribute('src').indexOf(".")) + ".png";
        const surprise = document.getElementById('surprise_img').getAttribute('src').substring(document.getElementById('surprise_img').getAttribute('src').lastIndexOf("/") + 1, document.getElementById('surprise_img').getAttribute('src').indexOf(".")) + ".png";
        const accessoire_small = document.getElementById('accessoire_img').getAttribute('src').substring(document.getElementById('accessoire_img').getAttribute('src').lastIndexOf("/") + 1, document.getElementById('accessoire_img').getAttribute('src').indexOf(".")) + ".png";
        const newAvatarParts = {
            shoes: shoes,
            hair: hair,
            skin: skin,
            clothes_top: clothes_top,
            clothes_bottom: clothes_bottom,
            face: face,
            accessoire: accessoire,
            surprise: surprise,
            face_small: face_small,
            hair_small: hair_small,
            skin_small: skin_small,
            accessoire_small: accessoire_small,
        };
        updateAvatarParts(newAvatarParts);
    };

    //function to handle the click on one specific avatar part to change it
    const changeElement = (event, id, src) => {
        document.getElementById(id).src = avatar_img[src];
        //update localStorage with avatarParts
        saveAvatarParts();
    };

    //on initial load -> save shuffledObjects, otherwise it will always change if another part is picked
    useEffect(() => {
        saveAvatarParts();
    }, []);

    return (
        <>
            <div className="avatar-container" onClick={() => document.getElementById("selection_bubble").style.display = "none"}>
                <div className="avatar-config">
                    <div className="avatar-config-name">
                        <input id="name" className="avatar-name" defaultValue={name} name="userName" type="text" maxLength="20" autoComplete="off" placeholder={t('avatar_erstellen.name')} required aria-required="true" onChange={(e) => changeName(e)} />
                    </div>
                    <div className="avatar_config_bubbles" id="avatar_config_bubbles">
                        <div className="avatar_bubbles_left">
                            <img src={btn['btnbody.svg']} className="avatarbtn active" onClick={(event) => clicked(event, "skin")} alt={t('avatar_erstellen.avatar_skin')} ></img>
                            <img src={btn["btnhair.svg"]} className="avatarbtn active" onClick={(event) => clicked(event, "hair")} alt={t('avatar_erstellen.avatar_hair')} ></img>
                            <img src={btn["btnface.svg"]} className="avatarbtn active" onClick={(event) => clicked(event, "face")} alt={t('avatar_erstellen.avatar_face')} ></img>
                            <img src={btn["btnshoes.svg"]} className="avatarbtn active" onClick={(event) => clicked(event, "shoes")} alt={t('avatar_erstellen.avatar_shoes')} ></img>
                        </div>
                        <div className="avatar_advanced_bubbles" id="avatar_advanced">
                            <div className="skin_bubbles"><img id="skin_img" src={currentSkin} className="no-visual-events" alt="" /></div>
                            <div className="face_bubbles"><img id="face_img" src={currentFace} className="no-visual-events" alt="" /></div>
                            <div className="hair_bubbles"><img id="hair_img" src={currentHair} className="no-visual-events" alt="" /></div>
                            <div className="clothes_top_bubbles"><img id="clothes_top_img" src={currentClothesTop} className="no-visual-events" alt="" /></div>
                            <div className="clothes_bottom_bubbles"><img id="clothes_bottom_img" src={currentClothesBottom} className="no-visual-events" alt="" /></div>
                            <div className="shoe_bubbles"><img id="shoes_img" src={currentShoe} className="no-visual-events" alt="" /></div>
                            <div className="accessoire_bubbles"><img id="accessoire_img" src={currentAccessoire} className="no-visual-events" alt="" /></div>
                            <div className="surprise_bubbles"><img id="surprise_img" src={currentSurprise} className="no-visual-events" alt="" /></div>
                            {currentBadge1 == 1 && <div className="avatar_badge"><img src={avatar_img['badge_unterwasser.png']} className="no-visual-events" alt="" /></div>}
                            {currentBadge2 == 1 && <div className="avatar_badge"><img src={avatar_img["badge_weltall.png"]} className="no-visual-events" alt="" /></div>}
                            {currentBadge3 == 1 && <div className="avatar_badge"><img src={avatar_img["badge_vulkan.png"]} className="no-visual-events" alt="" /></div>}
                        </div>
                        <div className="avatar_bubbles_right">
                            <img src={btn["btntop.svg"]} className="avatarbtn active" onClick={(event) => clicked(event, "clothes_top")} alt={t('avatar_erstellen.avatar_top')} ></img>
                            <img src={btn["btnbottom.svg"]} className="avatarbtn active" onClick={(event) => clicked(event, "clothes_bottom")} alt={t('avatar_erstellen.avatar_bottom')} ></img>
                            <img src={accessoire === true ? btn["btnaccessoires.svg"] : btn["btnlocked.svg"]} className={accessoire === true ? "avatarbtn active" : "avatarbtn locked"} onClick={accessoire === true ? ((event) => clicked(event, "accessoire")) : (() => console.log())} alt={t('avatar_erstellen.avatar_accessoire')} ></img>
                            <img src={surprise === true ? btn["btnsurprise.svg"] : btn["btnlocked.svg"]} className={surprise === true ? "avatarbtn active" : "avatarbtn locked"} onClick={surprise === true ? ((event) => clicked(event, "surprise")) : (() => console.log())} alt={t('avatar_erstellen.avatar_surprise')} ></img>
                        </div>
                    </div>
                </div>
                <div className="selection_bubble" id="selection_bubble">
                    <p>
                        {avatar_config[contentSelection] != null &&
                            Object.keys(avatar_config[contentSelection]).map(function (element) {
                                return (
                                    <img className="contentItem" src={avatar_img_small[avatar_config[contentSelection][element].small]} onClick={(event) => changeElement(event, avatar_config[contentSelection][element].id, avatar_config[contentSelection][element].big)} key={"face" + element} alt="" />
                                )
                            })
                        }
                    </p>
                </div>
            </div>
        </>
    )
}
