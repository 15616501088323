export const quiz_fr =  {
  "general": {
    "title": "Droit à la santé physique.",
    "intro": "Qu'est-ce qui est bon pour la santé physique?",
    "finishButton": "Vérifier les réponses",
    "nrQuestions": 7,
    "maxPoints": 35,
    "resultTitle": "C'est fait!",
    "resultText": "Tu as répondu correctement à <correctQuestions> de <questions> et tu as donc obtenu <points> de <pointsMax> points.",
    "button_back": "retour",
    "button_forward": "prochaine"
  },
  "questions": [
    {
      "question": "Qu'est-ce qui est bon pour la santé physique? Choisis ce qui est vrai:",
      "picture": "",
      "answers": [
        "Bruit",
        "Calme"
      ],
      "correctAnswer": [1],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "Qu'est-ce qui est bon pour la santé physique? Choisis ce qui est vrai:",
      "picture": "",
      "answers": [
        "aller en voiture",
        "rouler le vélo"
      ],
      "correctAnswer": [1],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "Qu'est-ce qui est bon pour la santé physique? Choisis ce qui est vrai:",
      "picture": "",
      "answers": [
        "Beaucoup de frites",
        "Beaucoup de fruits"
      ],
      "correctAnswer": [1],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "Qu'est-ce qui est bon pour la santé physique? Choisis ce qui est vrai:",
      "picture": "",
      "answers": [
        "Eau propre",
        "Eau sale"
      ],
      "correctAnswer": [0],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "Qu'est-ce qui est bon pour la santé physique? Choisis ce qui est vrai:",
      "picture": "",
      "answers": [
        "Air pur",
        "Air sale"
      ],
      "correctAnswer": [0],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "Qu'est-ce qui est bon pour la santé physique? Choisis ce qui est vrai:",
      "picture": "",
      "answers": [
        "Beaucoup d'assise",
        "Beaucoup de mouvement"
      ],
      "correctAnswer": [1],
      "point": 5,
      "evaluate": true
    },
    {
      "question": "Qu'est-ce qui est bon pour la santé physique? Choisis ce qui est vrai:",
      "picture": "",
      "answers": [
        "Beaucoup de routes",
        "Beaucoup de prairies et d'arbres"
      ],
      "correctAnswer": [1],
      "point": 5,
      "evaluate": true
    }
    
  ]
} 