export const quiz_it =  {
  "general": {
    "title": "Diritto alla promozione.",
    "intro": "Cosa vedi nell'immagine? In cosa viene sostenuto il bambino?",
    "finishButton": "Controllare le risposte",
    "nrQuestions": 5,
    "maxPoints": 50,
    "resultTitle": "ce l'hai fatta!",
    "resultText": "Avete risposto correttamente a <correctQuestions> domande su <questions> e quindi avete ottenuto <points> punti su <pointsMax> .",
    "button_back": "indietro",
    "button_forward": "prosegui"
  },
  "questions": [
    {
      "question": "Scegliere ciò che è giusto:",
      "picture": "frage1.jpg",
      "answers": [
        "Nuotare",
        "Scrivere",
        "Fare una doccia"
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Scegliere ciò che è giusto:",
      "picture": "frage2.jpg",
      "answers": [
        "Pattinare a rotelle",
        "Andare in bicicletta",
        "Guidare un'auto"
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Scegliere ciò che è giusto:",
      "picture": "frage3.jpg",
      "answers": [
        "Fare giochi di prestigo",
        "Leggere",
        "Calcolare"
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Scegliere ciò che è giusto:",
      "picture": "frage4.jpg",
      "answers": [
        "Disegnare",
        "Calcolare",
        "Fare sport"
      ],
      "correctAnswer": [2],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Scegliere ciò che è giusto:",
      "picture": "frage5.jpg",
      "answers": [
        "Risolvere gli indovinelli",
        "Armeggiare con il legno",
        "Fare musica"
      ],
      "correctAnswer": [2],
      "point": 10,
      "evaluate": true
    }
    
  ]
} 