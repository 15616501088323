export const quiz_de =  {
  "general": {
    "title": "Quiz zu Kinderarbeit",
    "intro": "Was weisst du über Kinderarbeit? Löse das Quiz und sammle Punkte!",
    "finishButton": "Antworten prüfen",
    "nrQuestions": 7,
    "maxPoints": 70,
    "resultTitle": "Geschafft!",
    "resultText": "Du hast <correctQuestions> von <questions> Fragen richtig beantwortet und damit <points> von <pointsMax> Punkten erreicht.",
    "button_back": "zurück",
    "button_forward": "nächste"
  },
  "questions": [
    {
      "question": "Wie viele Kinder auf der Welt müssen arbeiten?",
      "picture": "",
      "answers": [
        "70 Tausend",
        "68 Millionen",
        "168 Millionen"
      ],
      "correctAnswer": [2],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Kinderarbeit ist ...",
      "picture": "",
      "answers": [
        "nicht erlaubt.",
        "eine interessante Abwechslung.",
        "gut, um Geld für Mama und Papa zu verdienen."
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Was ist Kinderarbeit?",
      "picture": "",
      "answers": [
        "wenn Kinder Zeitungen austragen und einmal die Woche mit dem Nachbarshund spazieren gehen.",
        "wenn Kinder 1-2 Stunden pro Tag im Haushalt mithelfen müssen.",
        "wenn die Arbeit gesundheitsschädlich ist, zu lange dauert oder den Kindern keine Zeit für die Schule lässt."
      ],
      "correctAnswer": [2],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Wo gibt es weltweit am meisten arbeitende Kinder?",
      "picture": "",
      "answers": [
        "in Afrika südlich der Sahara",
        "in Indien und China",
        "in Europa"
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Manche Formen der Kinderarbeit sind besonders schlimm, weil",
      "picture": "",
      "answers": [
        "Kinder aufgrund ihrer langen und anstrengenden Arbeit keine Kraft und Zeit haben, eine Schule zu besuchen.",
        "Kinder jeden Morgen aufstehen müssen.",
        "Kinder viel Zeit zum Spielen bekommen."
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Die meisten Kinder müssen arbeiten, weil",
      "picture": "",
      "answers": [
        "sie ausprobieren möchten, welchen Job sie später machen möchten.",
        "sie mit dem Geld, das Essen für ihre Eltern und Geschwister bezahlen.",
        "sie mit dem Geld Computerspiele kaufen."
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Wir alle können etwas gegen Kinderarbeit unternehmen, indem man",
      "picture": "",
      "answers": [
        "immer das günstigste Produkt kauft.",
        "beim Einkauf nachfragt, ob das Produkt von Kindern hergestellt wurde.",
        "glaubt, dass alle Menschen immer an die Bedürfnisse von Kindern denken."
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
  ]
}