export const quiz_de =  {
  "general": {
    "title": "Schutz der Privatsphäre",
    "intro": "Löse das Quiz!",
    "finishButton": "Antworten prüfen",
    "nrQuestions": 4,
    "maxPoints": 40,
    "resultTitle": "Geschafft!",
    "resultText": "Du hast <correctQuestions> von <questions> Fragen richtig beantwortet und damit <points> von <pointsMax> Punkten erreicht.",
    "button_back": "zurück",
    "button_forward": "nächste"
  },
  "questions": [
    {
      "question": "Darf ich Geheimnisse haben?",
      "picture": "",
      "answers": [
        "Ja",
        "Nein"
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Darf meine Freundin ein Foto von mir machen, ohne mich zu fragen?",
      "picture": "",
      "answers": [
        "Ja",
        "Nein"
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Muss ich jedem erzählen, dass ich krank bin?",
      "picture": "",
      "answers": [
        "Ja",
        "Nein"
      ],
      "correctAnswer": [1],
      "point": 10,
      "evaluate": true
    },
    {
      "question": "Was kann ich tun, wenn ich ein belastendes Geheimnis habe?",
      "picture": "",
      "answers": [
        "Mit jemandem darüber reden (z.B. 147 anrufen)",
        "Es für mich behalten"
      ],
      "correctAnswer": [0],
      "point": 10,
      "evaluate": true
    },
  ]
}