export const quiz_de =  {
    "general": {
      "title": "Recht am eigenen Bild",
      "intro": "Du möchtest ein Fotoalbum von deiner Klasse basteln. Du hast die Kinder nicht um Erlaubnis gefragt. Deshalb dürfen keine Gesichter erkennbar sein. Welche Fotos darfst du benutzen?",
      "finishButton": "Antworten prüfen",
      "nrQuestions": 5,
      "maxPoints": 50,
      "resultTitle": "Geschafft!",
      "resultText": "Du hast <correctQuestions> von <questions> Fragen richtig beantwortet und damit <points> von <pointsMax> Punkten erreicht.",
      "button_back": "zurück",
      "button_forward": "nächste"
    },
    "questions": [
      {
        "question": "Darf dieses Bild genutzt werden?",
        "picture": "sharenting1.jpg",
        "answers": [
          "Ja",
          "Nein"
        ],
        "correctAnswer": [1],
        "point": 10,
        "evaluate": true
      },
      {
        "question": "Darf dieses Bild genutzt werden?",
        "picture": "sharenting2.jpg",
        "answers": [
          "Ja",
          "Nein"
        ],
        "correctAnswer": [0],
        "point": 10,
        "evaluate": true
      },
      {
        "question": "Darf dieses Bild genutzt werden?",
        "picture": "sharenting3.jpg",
        "answers": [
          "Ja",
          "Nein"
        ],
        "correctAnswer": [0],
        "point": 10,
        "evaluate": true
      },
      {
        "question": "Darf dieses Bild genutzt werden?",
        "picture": "sharenting4.jpg",
        "answers": [
          "Ja",
          "Nein"
        ],
        "correctAnswer": [1],
        "point": 10,
        "evaluate": true
      },
      {
        "question": "Darf dieses Bild genutzt werden?",
        "picture": "sharenting5.jpg",
        "answers": [
          "Ja",
          "Nein"
        ],
        "correctAnswer": [1],
        "point": 10,
        "evaluate": true
      },
    ]
  } 