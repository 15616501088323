export const quiz_de =  {
    "general": {
      "title": "Recht auf körperliche Gesundheit.",
      "intro": "Was tut der körperlichen Gesundheit gut?",
      "finishButton": "Antworten prüfen",
      "nrQuestions": 7,
      "maxPoints": 35,
      "resultTitle": "Geschafft!",
      "resultText": "Du hast <correctQuestions> von <questions> Fragen richtig beantwortet und damit <points> von <pointsMax> Punkten erreicht.",
      "button_back": "zurück",
      "button_forward": "nächste"
    },
    "questions": [
      {
        "question": "Was tut der körperlichen Gesundheit gut? Wähle an, was stimmt:",
        "picture": "",
        "answers": [
          "Lärm",
          "Ruhe"
        ],
        "correctAnswer": [1],
        "point": 5,
        "evaluate": true
      },
      {
        "question": "Was tut der körperlichen Gesundheit gut? Wähle an, was stimmt:",
        "picture": "",
        "answers": [
          "Auto fahren",
          "Fahrrad fahren"
        ],
        "correctAnswer": [1],
        "point": 5,
        "evaluate": true
      },
      {
        "question": "Was tut der körperlichen Gesundheit gut? Wähle an, was stimmt:",
        "picture": "",
        "answers": [
          "Viele Pommes",
          "Viel Obst"
        ],
        "correctAnswer": [1],
        "point": 5,
        "evaluate": true
      },
      {
        "question": "Was tut der körperlichen Gesundheit gut? Wähle an, was stimmt:",
        "picture": "",
        "answers": [
          "Sauberes Wasser",
          "Dreckiges Wasser"
        ],
        "correctAnswer": [0],
        "point": 5,
        "evaluate": true
      },
      {
        "question": "Was tut der körperlichen Gesundheit gut? Wähle an, was stimmt:",
        "picture": "",
        "answers": [
          "Saubere Luft",
          "Dreckige Luft"
        ],
        "correctAnswer": [0],
        "point": 5,
        "evaluate": true
      },
      {
        "question": "Was tut der körperlichen Gesundheit gut? Wähle an, was stimmt:",
        "picture": "",
        "answers": [
          "Viel Sitzen",
          "Viel Bewegung"
        ],
        "correctAnswer": [1],
        "point": 5,
        "evaluate": true
      },
      {
        "question": "Was tut der körperlichen Gesundheit gut? Wähle an, was stimmt:",
        "picture": "",
        "answers": [
          "Viele Strassen",
          "Viele Wiesen und Bäume"
        ],
        "correctAnswer": [1],
        "point": 5,
        "evaluate": true
      }
      
    ]
  } 