export default function FinishState({ points, pointsMax, correctQuestions, questions, text, title }) {
    text = text.replace("<points>", points);
    text = text.replace("<pointsMax>", pointsMax);
    text = text.replace("<correctQuestions>", correctQuestions);
    text = text.replace("<questions>", questions);
    return(
        <>
            <h3>{title}</h3>
            <p>{text}</p>
        </>
    )
}