/* if update of i18n files
    1) create new folder (e.g. locales_v2) with the translation files
    2) change the settings in the i18n.js file (e.g. loadPath: '/locales_v2/{{lng}}/{{ns}}.json')
*/

/* update v1 - silent launch -> official launch */
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';
import games_de from '../components/settings/games_new.json';
import games_fr from '../components/settings/games_new_fr.json';
import games_it from '../components/settings/games_new_it.json';

export function update_v1(version) {
    //only do update if already created a user
    if(JSON.parse(localStorage.getItem("name"))){  
        //only do update, if not already updated
        console.log("start update");
        //get current games from localstorage
        let games = JSON.parse(localStorage.getItem("games"));
        let games_new = "";

        //set new game from json-file
        switch (JSON.parse(localStorage.getItem("language"))) {
            case "de":
                games_new = games_de["games"];
                break;
            case "fr":
                games_new = games_fr["games"];
                break;
            case "it":
                games_new = games_it["games"];
                break;
        }

        //replace the intro / outro texts in the current games with new ones
        let game_temp = "";
        Object.keys(games).map(function (element) {
            game_temp = { ...games_new[element], points_achieved: games[element].points_achieved, state: games[element].state };
            games_new = { ...games_new, [element]: game_temp }
        });

        //write the updated games into localstorage
        localStorage.setItem("games", JSON.stringify(games_new));

        //set version after update
        localStorage.setItem("version", version);

        console.log("finised update");
        
        serviceWorkerRegistration.register();
        setTimeout(function() {window.location.reload(true);}, 1000);
        //setTimeout(function() {document.getElementById("update_msg").style.display = "block";}, 500);
    }
}