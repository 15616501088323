import { useTranslation, Trans } from 'react-i18next';
import { LinkText } from "../../../helper/helper_functions";
import { useUserContext } from '../../../hooks/useUserContext';
import CustomQuiz from '../../games/CustomQuiz/CustomQuiz';
import { quiz_de } from './informationsfreiheit/quiz_de';
import { quiz_fr } from './informationsfreiheit/quiz_fr';
import { quiz_it } from './informationsfreiheit/quiz_it';

import "./Informationsfreiheit.css";

export default function Informationsfreiheit2({ oldAndNewScore }) {
    const { t, i18n } = useTranslation();
    const { games, updateGames, calculateScore, score } = useUserContext();
    const quiz_game = games.informationsfreiheit;

    let quiz = '';
    switch(i18n.language) {
        case 'de':
            quiz = quiz_de;
            break;
        case 'fr':
            quiz = quiz_fr;
            break;
        case 'it':
            quiz = quiz_it;
            break;
        default:
            quiz = quiz_de;
    };

    const setQuizResult = (points) => {
        const quiz_new = { ...quiz_game, state: 'done', points_achieved: points };
        const games_new = { ...games, informationsfreiheit: quiz_new };
        updateGames(games_new);
        const current_game = "informationsfreiheit";
        let total_score = calculateScore(current_game);
        total_score += points;
        const oldScore = score;
        const newScore = total_score;
        oldAndNewScore(oldScore, newScore);
        document.getElementById("links_informationsfreiheit").className = "outer_content_visible";
    };
    return(
        <>
            <div>
                <CustomQuiz inputQuiz={quiz} finishFunction={setQuizResult} />
            </div>
            <div className="outer_content_invisible" id="links_informationsfreiheit">
                <div className="inner_content">
                    <h3>{t('informationsfreiheit.title')}</h3>
                    <p>{t('informationsfreiheit.intro')}</p>
                        <p><Trans i18nKey="informationsfreiheit.link1_text" components={{
                            link1: <LinkText to={t('informationsfreiheit.link1')} title={t('informationsfreiheit.link1_title')} />
                        }}/></p>
                        <p><Trans i18nKey="informationsfreiheit.link2_text" components={{
                            link2: <LinkText to={t('informationsfreiheit.link2')} title={t('informationsfreiheit.link2_title')} />
                        }}/></p>
                        <p><Trans i18nKey="informationsfreiheit.link3_text" components={{
                            link3: <LinkText to={t('informationsfreiheit.link3')} title={t('informationsfreiheit.link3_title')} />
                        }}/></p>
                        <p><Trans i18nKey="informationsfreiheit.link4_text" components={{
                            link4: <LinkText to={t('informationsfreiheit.link4')} title={t('informationsfreiheit.link4_title')} />
                        }}/></p>
                </div>
            </div>
        </>
    )
}