import { image_importer } from "../helper/helper_functions";

//import components
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";

//Import für User-Daten
import { useUserContext } from "../hooks/useUserContext";

//Import Styles
import './CSS/Navbar.css';

//Value steuert, ob "zurück Button" angezeigt wird oder nicht
//Dark steuert, ob die Icons farblich invertiert werden - outdated
export default function Navbar({ value, clicked, counting, stopSound, startSound }) {
  //import images
  const btn = image_importer(require.context('../assets/btn', false, /\.(png|jpe?g|svg)$/));
  const avatar_small = image_importer(require.context('../assets/avatar/small', false, /\.(png|jpe?g|svg)$/));
  const logo = image_importer(require.context('../assets/logo', false, /\.(png|jpe?g|svg)$/));

  //initialize variables for src of buttons
  const arrowSrc = btn["btnback.svg"];
  const helpSrc = btn["btncontact.svg"];
  const settingSrc = btn["btnsettings.svg"];
  const infoPageSrc = btn["btninfo.svg"];

  //initialize variables for navigation
  const navigate = useNavigate();
  const currentLocation = useLocation();

  //initialize variables for parts of avatar (small)
  const { score, avatarParts, name, settings, updateSettings } = useUserContext();
  let face_small = "";
  let hair_small = "";
  let skin_small = "";
  let accessoire_small = "";
  if (avatarParts !== null) {
    face_small = avatar_small[avatarParts.face_small];
    hair_small = avatar_small[avatarParts.hair_small];
    skin_small = avatar_small[avatarParts.skin_small];
    accessoire_small = avatar_small[avatarParts.accessoire_small];
  }

  //initialize function for translation
  const { t } = useTranslation();

  //CSS-Klasse für den score festlegen...
  if (typeof (counting) === 'undefined') { counting = '' };
  let classScore = 'score';
  if (counting !== '') classScore += ' ' + counting;

  //navigation due to firefox
  const nav_to_target = (target) => {
    navigate(target);
  }

  const nav_to_home = () => {
    navigate({ pathname: `/Home${currentLocation.pathname.substring(currentLocation.pathname.lastIndexOf("/"))}` });
  }

  const startFullScreen = () => {
    var elem = document.body;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    }
  }

  const changeSoundSetting = async () => {
    let song = "";
    if (currentLocation.pathname.includes("Welt")) {
      if (currentLocation.pathname.includes("schutzrechte")) {
        song = settings.schutzrecht;
      } else if (currentLocation.pathname.includes("foerderrechte")) {
        song = settings.foerderrecht;
      } else {
        song = settings.beteiligungsrecht;
      }
    } else {
      song = settings.general;
    }
    let newSettings = "";
    if (settings.sound === "soundOff") {
      newSettings = {...settings, sound: "soundOn"};
      startSound(song, true);
    } else {
      newSettings = {...settings, sound: "soundOff"};
      stopSound();
    }
    await updateSettings(newSettings);
  }

  return (
    <nav className='nav-bar' aria-label="Hauptnavigation">
      <div className="nav-left">
        {value === "home" && <img src={logo["kidimo.png"]} className="navbar_logo" alt="KIDIMO" />}
        {value === "home" && (<span className="welcome">{t('description.hello')} <span className="upper_name">{name}</span>!</span>)}
        {(value === "welt" || value === "avatar" || value === "settings" || value === "help") && <img src={arrowSrc} alt={t('general.back')} className="btn" onClick={() => nav_to_home()} />}
        {(value === "infopage" || value === "share_score" || value === "qr_code") && <img src={arrowSrc} alt={t('general.back')} className="btn" onClick={() => navigate(-1)} />}
      </div>
      <div className="nav-center"></div>
      <div className="nav-right">
        {
          value === "welt" && (
            <div className="nav-avatar">
              <img id="skin_img" src={skin_small} alt="" />
              <img id="face_img" src={face_small} alt="" />
              <img id="hair_img" src={hair_small} alt="" />
              <img id="accessoire_img" src={accessoire_small} onClick={() => clicked('games_overview')} alt=""  />
            </div>
          )}
        {name !== null && name !== "" && (
          <div className={classScore} onClick={startFullScreen}>
            <span className="label">{t('navbar.points')}:</span>
            <span className="points">{score}</span>
          </div>
        )}
        {settings.sound === "soundOff" && <img src={btn["btnspeak.svg"]} className="btn" onClick={() => changeSoundSetting()} alt={t('general.sound_on')} /> }
        {settings.sound === "soundOn" && <img src={btn["btnspeak_no.svg"]} className="btn" onClick={() => changeSoundSetting()} alt={t('general.sound_off')} /> }
        {value==="avatar" &&
          <div className={"share_link"}>
          <Link to="/share_score">
            <img src={btn["btnshare.svg"]} className="btn" alt={t('general.share')} />
          </Link>
        </div>
        }
        {value === "home" && <img src={settingSrc} alt={t('general.settings')} className="btn" {...(currentLocation.pathname === '/settings' ? { "aria-current": "page" } : {})} onClick={() => nav_to_target("/settings")} />}
        {value === "home" && <img src={infoPageSrc} alt={t('general.infopage')} className="btn" {...(currentLocation.pathname === '/info_page' ? { "aria-current": "page" } : {})} onClick={() => nav_to_target("/info_page")} />}
      </div>
      {value === "home" && (<div className="help_navbar">
        <img id="help" src={helpSrc} alt={t('general.help')} className="btn" {...(currentLocation.pathname === '/help' ? { "aria-current": "page" } : {})} onClick={() => nav_to_target("/help")} />
      </div>)}
    </nav>
  )
}
