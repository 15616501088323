export const quiz_it =  {
  "general": {
    "title": "Linguaggio semplice",
    "intro": "Quali frasi sono in linguaggio semplice?",
    "finishButton": "Controllare le risposte",
    "nrQuestions": 1,
    "maxPoints": 10,
    "resultTitle": "Ce l'hai fatta!",
    "resultText": "Hai risposto correttamente a <correctQuestions> domande su <questions> e quindi hai ottenuto <points> punti su <pointsMax>.",
    "button_back": "indietro",
    "button_forward": "prosegui"
  },
  "questions": [
    {
      "question": "Clicca sulle frasi in linguaggio semplice.",
      "picture": "",
      "answers": [
        "Ogni bambino ha il diritto di conoscere le notizie.",
        "È importante sottolineare che ogni bambino ha il diritto di sapere cosa succede nel mondo.",
        "La libertà di espressione è un diritto che ogni bambino ha.",
        "Ogni bambino ha il diritto di esprimere la propria opinione.",
        "È possibile scoprire notizie importanti dai media",
        "Si possono leggere libri e giornali.",
        "La letteratura specializzata, che si trova ad esempio nelle bibliotece, fornisce informazioni interessanti."
      ],
      "correctAnswer": [0,3,4,5],
      "point": 10,
      "evaluate": true
    },
  ]
} 