import games_de from '../components/settings/games_new.json';
import games_fr from '../components/settings/games_new_fr.json';
import games_it from '../components/settings/games_new_it.json';
import settings from '../components/settings/settings.json';
import { createContext, useReducer } from "react";

export const UserContext = createContext();

const gamesInfos = games_de['infos'];
let games = games_de['games'];

//Dispatch-Funktion, um unterschiedliche Aktionen im LocalStorage auszuführen
const userReducer = (state, action) => {
    switch (action.type) {
        case 'CREATE_USER':
            if ((action.payload.language).includes("de")) {
                games = games_de['games']
            } else if ((action.payload.language).includes("fr")) {
                games = games_fr['games']
            } else if ((action.payload.language).includes("it")) {
                games = games_it['games']
            } else {
                games = games_de['games']
            }
            localStorage.setItem("name", JSON.stringify(action.payload.name));
            localStorage.setItem("language", JSON.stringify(action.payload.language));
            localStorage.setItem("score", JSON.stringify(action.payload.score));
            localStorage.setItem("games", JSON.stringify(games));
            localStorage.setItem("gamesInfos", JSON.stringify(gamesInfos));
            localStorage.setItem("settings", JSON.stringify(settings));
            localStorage.setItem("avatarParts", JSON.stringify(action.payload.avatarParts));
            localStorage.setItem("intro", JSON.stringify(action.payload.intro));
            localStorage.setItem("accessoire", JSON.stringify(action.payload.accessoire));
            localStorage.setItem("surprise", JSON.stringify(action.payload.suprise));
            localStorage.setItem("show_notification", JSON.stringify(action.payload.show_notification));
            localStorage.setItem("show_badge_notification", JSON.stringify(action.payload.show_badge_notification));
            localStorage.setItem("version", 4);
            return
        case 'UPDATE_LANGUAGE':
            switch (action.payload) {
                case 'de':
                    games = games_de['games']
                    break;
                case 'fr':
                    games = games_fr['games']
                    break;
                case 'it':
                    games = games_it['games']
                    break;
            };
            let games_new = JSON.parse(localStorage.getItem("games"));
            for (const [name, values] of Object.entries(games_new)) {
                //if (name=="diskriminierungsverbot") {console.log(games_new[name].intro)};
                games_new[name].intro = games[name].intro;
                //if (name=="diskriminierungsverbot") {console.log(games_new[name].intro)};
                games_new[name].outro = games[name].outro;
                games_new[name].intro_audio = games[name].intro_audio;
                games_new[name].outro_audio = games[name].outro_audio;
            };
            localStorage.setItem("games", JSON.stringify(games_new));
            localStorage.setItem("language", JSON.stringify(action.payload));
            return { ...state, games: games_new, language: action.payload }
        case 'UPDATE_NAME':
            localStorage.setItem("name", JSON.stringify(action.payload));
            return { ...state, name: action.payload }
        case 'DELETE_USER':
            localStorage.removeItem("name");
            localStorage.removeItem("language");
            localStorage.removeItem("score");
            localStorage.removeItem("games");
            localStorage.removeItem("gamesInfos");
            localStorage.removeItem("push");
            localStorage.removeItem("intro");
            localStorage.removeItem("settings");
            localStorage.removeItem("avatarImg");
            localStorage.removeItem("avatarParts");
            localStorage.removeItem("accessoire");
            localStorage.removeItem("surprise");
            localStorage.removeItem("show_notification");
            localStorage.removeItem("show_badge_notification");
            return { ...state, name:'', language:'', score:'', games:'', gamesInfos:'', settings:'', avatarParts:'', accessoire:'', surprise:'', show_notification:'', show_badge_notification:'' }
        case 'UPDATE_GAME':
            localStorage.setItem("games", JSON.stringify(action.payload));
            return { ...state, games: action.payload }
        case 'UPDATE_SCORE':
            localStorage.setItem("score", JSON.stringify(action.payload));
            return {...state, score: action.payload}
        case 'UPDATE_GAMESINFOS':
            localStorage.setItem("gamesInfos", JSON.stringify(action.payload));
            return { ...state, gamesInfos: action.payload}
        case 'SET_INTRO_DONE':
            localStorage.setItem("intro", JSON.stringify(action.payload));
            return { ...state, intro: action.payload}
        case 'UPDATE_SETTINGS':
            localStorage.setItem("settings", JSON.stringify(action.payload));
            return { ...state, settings: action.payload}
        case 'UPDATE_AVATARPARTS':
            localStorage.setItem("avatarParts", JSON.stringify(action.payload));
            return { ...state, avatarParts: action.payload}
        case 'SET_ACCESSOIRE_TRUE':
            localStorage.setItem("accessoire", JSON.stringify(action.payload));
            return { ...state, accessoire: action.payload}
        case 'SET_SURPRISE_TRUE':
            localStorage.setItem("surprise", JSON.stringify(action.payload));
            return { ...state, surprise: action.payload}
        case 'SET_NOTIFICATION':
            localStorage.setItem("show_notification", JSON.stringify(action.payload));
            return { ...state, show_notification: action.payload}
        case 'SET_BADGE_NOTIFICATION':
            localStorage.setItem("show_badge_notification", JSON.stringify(action.payload));
            return { ...state, show_badge_notification: action.payload}
        default:
            return state;
    };
};

//Component exportieren, die den Zugriff auf die LocalStorage Daten sicherstellt
export function UserProvider({ children }) {
    let settings = JSON.parse(localStorage.getItem("settings"));
    try {
        const font = settings.font;
    } catch (error) {
        settings = {
            font: "fontMiddle",
            transition: "transitionOn",
            sound: "soundOn",
            animation: "animationOn"
        }
    };
    
    const [state, dispatch] = useReducer(userReducer, {
        name: JSON.parse(localStorage.getItem("name")),
        language: JSON.parse(localStorage.getItem("language")),
        score: JSON.parse(localStorage.getItem("score")),
        games: JSON.parse(localStorage.getItem("games")),
        gamesInfos: JSON.parse(localStorage.getItem("gamesInfos")),
        intro: JSON.parse(localStorage.getItem("intro")),
        settings: settings,
        avatarParts: JSON.parse(localStorage.getItem("avatarParts")),
        accessoire: JSON.parse(localStorage.getItem("accessoire")),
        surprise: JSON.parse(localStorage.getItem("surprise")),
        show_notification: JSON.parse(localStorage.getItem("show_notification")),
        show_badge_notification: JSON.parse(localStorage.getItem("show_badge_notification"))
    });

    const createUser = (user) => {
        return new Promise(resolve => {
            dispatch({ type: 'CREATE_USER', payload: user});
            resolve('resolved');
        })
    };

    const updateName = (name) => {
        dispatch({ type: 'UPDATE_NAME', payload: name})
    };
    const deleteUser = () => {
        dispatch({ type: 'DELETE_USER', payload: ''})
    };

    const updateGamesInfos = (gamesInfos) => {
        dispatch({ type: 'UPDATE_GAMESINFOS', payload: gamesInfos})
    };

    const updateSettings = (settings) => {
        dispatch({ type: 'UPDATE_SETTINGS', payload: settings})
    };

    const updateLanguage = (language) => {
        dispatch({ type: 'UPDATE_LANGUAGE', payload: language})
    };
    
    function update_game(games) {
        return new Promise((resolve)=>{
            dispatch({ type: 'UPDATE_GAME', payload: games})
            resolve('resolved');
        });
    };

    async function updateGames(games) {
        await update_game(games);
        let done_beteiligungsrechte = 0;
        let done_foerderrechte = 0;
        let done_schutzrechte = 0;
        for (const [name, values] of Object.entries(games)) {
            if (values.world === "beteiligungsrecht" && values.state === "done") {
                done_beteiligungsrechte += 1;
            } else if (values.world === "foerderrecht" && values.state === "done") {
                done_foerderrechte += 1;
            } else if (values.world === "schutzrecht" && values.state === "done") {
                done_schutzrechte += 1;
            };
        };
        let games_infos = JSON.parse(localStorage.getItem("gamesInfos"));
        games_infos['beteiligungsrechte']['done'] = done_beteiligungsrechte;
        games_infos['foerderrechte']['done'] = done_foerderrechte;
        games_infos['schutzrechte']['done'] = done_schutzrechte;
        if (games_infos['beteiligungsrechte']['finished'] !== 1) {
            if (games_infos['beteiligungsrechte']['done']===games_infos['beteiligungsrechte']['total']){setBadgeNotification("beteiligungsrechte")};
            games_infos['beteiligungsrechte']['done']===games_infos['beteiligungsrechte']['total'] ? games_infos['beteiligungsrechte']['finished'] = 1 : games_infos['beteiligungsrechte']['finished'] = 0;
        } else {games_infos['beteiligungsrechte']['finished'] = 1};
        if (games_infos['foerderrechte']['finished'] !== 1) {
            if(games_infos['foerderrechte']['done']===games_infos['foerderrechte']['total']){setBadgeNotification("foerderrechte")};
            games_infos['foerderrechte']['done']===games_infos['foerderrechte']['total'] ? games_infos['foerderrechte']['finished'] = 1 : games_infos['foerderrechte']['finished'] = 0;
        } else {games_infos['foerderrechte']['finished'] = 1};
        if (games_infos['schutzrechte']['finished'] !== 1) {
            if(games_infos['schutzrechte']['done']===games_infos['schutzrechte']['total']){setBadgeNotification("schutzrechte")};
            games_infos['schutzrechte']['done']===games_infos['schutzrechte']['total'] ? games_infos['schutzrechte']['finished'] = 1 : games_infos['schutzrechte']['finished'] = 0;
        } else {games_infos['schutzrechte']['finished'] = 1};
        updateGamesInfos(games_infos);
    };

    const updateScore = (score) => {
        dispatch({ type: 'UPDATE_SCORE', payload: score});
        const accessoire = JSON.parse(localStorage.getItem("accessoire"));
        const surprise = JSON.parse(localStorage.getItem("surprise"));
        const lng = JSON.parse(localStorage.getItem("language"));

        let score_accessoire = games_de['settings'].score_accessoires;
        let score_surprise = games_de['settings'].score_surprise;

        switch (lng) {
            case 'de':
                score_accessoire = games_de['settings'].score_accessoires;
                score_surprise = games_de['settings'].score_surprise;
                break;
            case 'fr':
                score_accessoire = games_fr['settings'].score_accessoires;
                score_surprise = games_fr['settings'].score_surprise;
                break;
            case 'it':
                score_accessoire = games_it['settings'].score_accessoires;
                score_surprise = games_it['settings'].score_surprise;
                break;
        };

        if (score >= score_accessoire && accessoire===false) {
            setNotification(true);
            setAccessoireTrue();
        } else if (score >= score_surprise && surprise===false) {
            setNotification(true);
            setSurpriseTrue();
        }
    };
 
    const calculateScore = (game_name) => {
        let total_score = 0;
        for (const [name, values] of Object.entries(JSON.parse(localStorage.getItem("games")))) {
            if (name !== game_name) {
                total_score += values.points_achieved;
            };
        };
        return total_score;
    };

    const setIntroDone = () => {
        dispatch({ type: 'SET_INTRO_DONE', payload: true})
    };
    const setAccessoireTrue = () => {
        dispatch({ type: 'SET_ACCESSOIRE_TRUE', payload: true})
    };
    const setSurpriseTrue = () => {
        dispatch({ type: 'SET_SURPRISE_TRUE', payload: true})
    };

    const updateAvatarParts = (avatarParts) => {
        dispatch({ type: 'UPDATE_AVATARPARTS', payload: avatarParts})
    };

    const setNotification = (show_notification) => {
        dispatch({ type: 'SET_NOTIFICATION', payload: show_notification})
    }

    const setBadgeNotification = (badge_world) => {
        dispatch({ type: 'SET_BADGE_NOTIFICATION', payload: badge_world})
    }

    return (
        <UserContext.Provider value={{ ...state, createUser, deleteUser, updateGames, calculateScore, updateScore, setIntroDone, updateSettings, updateAvatarParts, updateLanguage, updateName, setNotification, setBadgeNotification }}>
            {children}
        </UserContext.Provider>
    );
};