import { image_importer } from "../../helper/helper_functions";

//import components
import { useEffect, useState } from "react";
import ImageMap from "./ImageMap";
import ContentPlacer from "./ContentPlacer";
import { useUserContext } from "../../hooks/useUserContext";
import AvatarIcon from "../AvatarIcon";

//import styles
import './welten.css';

//import logging for friendly analytics
import { useMatomo } from "@datapunt/matomo-tracker-react";

export default function Schutzrechte({ height, clicked, startSound }) {
    useEffect(() => {
        //const backgroundSong = '../../songs/calm-background-for-video-121519.mp3';
        startSound(backgroundSong, false);
        //logging for friendly analytics
        trackPageView({
            documentTitle: 'Welt Schutzrechte',
            href: '/Welt/schutzrechte',
        })
        const new_position_x = games['diskriminierungsverbot'].position.x * factor + games['diskriminierungsverbot'].height * factor * 0.1;
        const new_position_y = games['diskriminierungsverbot'].position.y * factor - games['diskriminierungsverbot'].height * factor * 0.9;
        document.getElementById("walking_avatar").className = "is_walking_avatar_right";
        document.getElementById("walking_avatar").style.position = "absolute";
        document.getElementById("walking_avatar").style.left = new_position_x + "px";
        document.getElementById("walking_avatar").style.top = new_position_y + "px";
    }, []);

    const [currentGame, setCurrentGame] = useState('diskriminierungsverbot');
    useEffect(() => {
        walk_and_open(currentGame, "", 0, false, false)
    }, [height])

    //import images
    const scenes = image_importer(require.context('../../assets/scenes/schutzrechte', false, /\.(png|jpe?g|svg)$/));
    var backgroundSong;
    if (window.location.hostname !== "localhost") {
        const bgSounds = image_importer(require.context('../../assets/mp3/bg', false, /\.(mp3)$/));
        backgroundSong = bgSounds['calm-background-for-video-121519.mp3']
    } else {
        backgroundSong = '../../songs/calm-background-for-video-121519.mp3';
    }

    //initialize function for logging friendly analytics
    const { trackPageView } = useMatomo();

    //initialize variable for user data
    const { games } = useUserContext();

    //set up image map variables
    const imgSrc = "Unterwasser_Welt.png";
    const imgWidth = (height / 1280) * 3840;
    const viewbox = "0 0 " + imgWidth.toString() + " " + height.toString();
    const factor = height / 1280;
    const imgMap = {
        imgSrc: imgSrc,
    }

    //set up var for contentPlacer
    const contentDiskriminierungsverbot = games['diskriminierungsverbot'];
    const sceneDiskriminierungsverbot = scenes[contentDiskriminierungsverbot.src];
    const contentDirekteGewalt = games['direkte_gewalt'];
    const sceneDirekteGewalt = scenes[contentDirekteGewalt.src];
    const contentSexualisierteGewalt = games['sexualisierte_gewalt'];
    const sceneSexualisierteGewalt = scenes[contentSexualisierteGewalt.src];
    const contentMobbing = games['mobbing'];
    const sceneMobbing = scenes[contentMobbing.src];
    const contentSchutzPrivatleben = games['schutz_privatleben'];
    const sceneSchutzPrivatleben = scenes[contentSchutzPrivatleben.src];
    const contentSharenting = games['sharenting'];
    const sceneSharenting = scenes[contentSharenting.src];
    const contentLoyalitaetskonflikt = games['loyalitaetskonflikt'];
    const sceneLoyalitaetskonflikt = scenes[contentLoyalitaetskonflikt.src];
    const contentSchutzVorAusbeutung = games['schutz_vor_ausbeutung'];
    const sceneSchutzVorAusbeutung = scenes[contentSchutzVorAusbeutung.src];
    const contentSchutzVorSuchtmittel = games['schutz_vor_suchtmittel'];
    const sceneSchutzVorSuchtmittel = scenes[contentSchutzVorSuchtmittel.src];
    const contentIndirekteGewalt = games['indirekte_gewalt'];
    const sceneIndirekteGewalt = scenes[contentIndirekteGewalt.src];

    //function for avatar-walk and open content
    const walk_and_open = (name, sound, pages, video, open = true) => {
        setCurrentGame(name);
        const old_position_x = document.getElementById("walking_avatar").style.left;
        const data = games[name];
        let new_position_x = data.position.x * factor + data.height * factor * 0.2;
        const new_position_y = data.position.y * factor - data.height * factor * 0.9;
        if (Number(old_position_x.substring(0, old_position_x.lastIndexOf("p"))) > new_position_x) {
            document.getElementById("walking_avatar").className = "is_walking_avatar_left";
            new_position_x = data.position.x * factor + data.height * factor * 0.2;
        } else {
            document.getElementById("walking_avatar").className = "is_walking_avatar_right";
            new_position_x = data.position.x * factor + data.height * factor * 0.1;
        }
        document.getElementById("walking_avatar").style.position = "absolute";
        document.getElementById("walking_avatar").style.left = new_position_x + "px";
        document.getElementById("walking_avatar").style.top = new_position_y + "px";
        if (open === true) {
            if (Math.abs(Number(old_position_x.substring(0, old_position_x.lastIndexOf("p"))) - new_position_x) > 1) {
                setTimeout(() => clicked(name, sound, pages, video), 1300);
            } else {
                clicked(name, sound, pages, video);
            }
        }
    }

    return (
        <div className="background-image" style={{ maxWidth: '100%' }}>
            <ImageMap clicked={clicked} factor={factor} imgWidth={imgWidth} viewbox={viewbox} height={height} imgMap={imgMap} folder="schutzrechte" />
            <div id="walking_avatar">
                <AvatarIcon />
            </div>
            <ContentPlacer data={contentDiskriminierungsverbot} factor={factor} clicked={walk_and_open} sound={false} pages={1} id="1" scene={sceneDiskriminierungsverbot} video={false} />
            <ContentPlacer data={contentDirekteGewalt} factor={factor} clicked={walk_and_open} sound={false} pages={1} id="2" scene={sceneDirekteGewalt} video={false} />
            <ContentPlacer data={contentSexualisierteGewalt} factor={factor} clicked={walk_and_open} sound={false} pages={1} id="3" scene={sceneSexualisierteGewalt} video={false} />
            <ContentPlacer data={contentMobbing} factor={factor} clicked={walk_and_open} sound={false} pages={1} id="4" scene={sceneMobbing} video={false} />
            <ContentPlacer data={contentSchutzPrivatleben} factor={factor} clicked={walk_and_open} sound={true} pages={2} id="5" scene={sceneSchutzPrivatleben} video={true} />
            <ContentPlacer data={contentSharenting} factor={factor} clicked={walk_and_open} sound={false} pages={1} id="6" scene={sceneSharenting} video={false} />
            <ContentPlacer data={contentLoyalitaetskonflikt} factor={factor} clicked={walk_and_open} sound={false} pages={1} id="7" scene={sceneLoyalitaetskonflikt} video={false} />
            <ContentPlacer data={contentSchutzVorAusbeutung} factor={factor} clicked={walk_and_open} sound={false} pages={1} id="8" scene={sceneSchutzVorAusbeutung} video={false} />
            <ContentPlacer data={contentSchutzVorSuchtmittel} factor={factor} clicked={walk_and_open} sound={true} pages={1} id="9" scene={sceneSchutzVorSuchtmittel} video={false} />
            <ContentPlacer data={contentIndirekteGewalt} factor={factor} clicked={walk_and_open} sound={false} pages={1} id="10" scene={sceneIndirekteGewalt} video={false} />
            <div className="bubble bubble--1"></div>
            <div className="bubble bubble--2"></div>
            <div className="bubble bubble--3"></div>
            <div className="bubble bubble--4"></div>
            <div className="bubble bubble--5"></div>
            <div className="bubble bubble--6"></div>
            <div className="bubble bubble--7"></div>
            <div className="bubble bubble--8"></div>
            <div className="bubble bubble--9"></div>
            <div className="bubble bubble--10"></div>
            <div className="bubble bubble--11"></div>
            <div className="bubble bubble--12"></div>
        </div>
    )
}
