export const quiz_fr =  {
  "general": {
    "title": "Langage simple",
    "intro": "Quelles phrases sont en langage simple ?",
    "finishButton": "Contrôler les réponses",
    "nrQuestions": 1,
    "maxPoints": 10,
    "resultTitle": "C'est fait!",
    "resultText": "Tu as répondu correctement à <correctQuestions> de <questions> et tu as donc obtenu <points> de <pointsMax> points.",
    "button_back": "retour",
    "button_forward": "suivant"
  },
  "questions": [
    {
      "question": "Clique sur les phrases qui sont en langage simple.",
      "picture": "",
      "answers": [
        "Chaque enfant peut être au courant des nouveautés.",
        "Il convient de noter que chaque enfant a le droit de savoir ce qui se passe dans le monde.",
        "La liberté d'expression est un droit qui appartient à chaque enfant.",
        "Chaque enfant a le droit d'exprimer son opinion.",
        "Tu apprends les nouvelles importantes dans les médias.",
        "Tu peux lire des livres et des journaux.",
        "La littérature spécialisée, que l'on trouve par exemple dans les bibliothèques, fournit des informations passionnantes."
      ],
      "correctAnswer": [0,3,4,5],
      "point": 10,
      "evaluate": true
    },
  ]
} 